import { Table } from 'antd';
import React from 'react';
import { useETFHoldings } from '../../contexts/ETFHoldingsContext';

const HoldingsDataTable = () => {
  const { holdings } = useETFHoldings();

  const columns = [
    { title: 'Symbol', dataIndex: 'symbol', key: 'symbol', sorter: (a, b) => (a.symbol || '').localeCompare(b.symbol || '') },
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a, b) => (a.name || '').localeCompare(b.name || '') },
    { 
      title: 'Shares', 
      dataIndex: 'balance', 
      key: 'shares', 
      sorter: (a, b) => (a.balance || 0) - (b.balance || 0),
      render: (value) => value ? value.toLocaleString() : 'N/A'
    },
    { 
      title: 'Market Value', 
      dataIndex: 'valUsd', 
      key: 'market_value',
      sorter: (a, b) => (a.valUsd || 0) - (b.valUsd || 0),
      render: (value) => value ? `$${value.toLocaleString()}` : 'N/A'
    },
    // { 
    //   title: 'Weight (%)', 
    //   dataIndex: 'pctVal', 
    //   key: 'weight',
    //   sorter: (a, b) => (a.pctVal || 0) - (b.pctVal || 0),
    //   render: (value) => value ? `${(value * 100).toFixed(2)}%` : 'N/A'
    // },
    // { title: 'Sector', dataIndex: 'sector', key: 'sector', sorter: (a, b) => (a.sector || '').localeCompare(b.sector || '') },
    // { title: 'Industry', dataIndex: 'industry', key: 'industry', sorter: (a, b) => (a.industry || '').localeCompare(b.industry || '') },
    // { title: 'Country', dataIndex: 'country', key: 'country', sorter: (a, b) => (a.country || '').localeCompare(b.country || '') },
  ];

  return (
    <Table
      dataSource={holdings}
      columns={columns}
      rowKey='symbol'
      pagination={{ pageSize: 10 }}
      scroll={{ x: true }}
    />
  );
};

export default HoldingsDataTable;

