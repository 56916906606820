import { Alert, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import News from '../News/News';

const { Title } = Typography;

const WatchlistNews = ({ watchlist }) => {
  const [tickers, setTickers] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (watchlist && watchlist.symbols) {
      if (watchlist.symbols.length === 0) {
        setError('No symbols in the watchlist.');
        setLoading(false);
      } else {
        // Limit to 10 symbols to prevent potential API issues
        const limitedSymbols = watchlist.symbols.slice(0, 30);
        setTickers(limitedSymbols.map(symbol => symbol.symbol).join(','));
        setError(null);
        setLoading(false);
      }
    }
  }, [watchlist]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <Alert message={error} type="info" showIcon />;
  }

  return (
    <div>
      {tickers.length > 0 ? (
        <News tickers={tickers} />
      ) : (
        <Alert message="Add symbols to your watchlist to see related news." type="info" showIcon />
      )}
    </div>
  );
};

export default WatchlistNews;
