import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { getMarkets } from '../../services/api';
import GlobeComponent from './GlobeComponent';
import WorldView from './WorldView';

const { TabPane } = Tabs;

const Markets = () => {
  const [marketData, setMarketData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        setLoading(true);
        const response = await getMarkets();
        setMarketData(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching market data:', error);
        setError('Failed to fetch market data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchMarketData();
    // const interval = setInterval(fetchMarketData, 15000); // Fetch data every 15 seconds

    return () => {};
  }, []);

  if (loading) {
    return <div>Loading data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="World" key="1">
          <WorldView marketData={marketData} />
        </TabPane>
        <TabPane tab="Globe" key="2">
          <GlobeComponent marketData={marketData} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Markets;
