import { Radio, Select, Space } from 'antd';
import React from 'react';

const { Option } = Select;

const timeRanges = [
  { value: '2', label: '2Y' },
  { value: '5', label: '5Y' },
  { value: '10', label: '10Y' },
  { value: 'all', label: 'All' },
];

const flattenMetrics = (metrics, level = 0) => {
  return metrics.reduce((acc, item) => {
    acc.push({ ...item, level });
    if (item.children) {
      acc.push(...flattenMetrics(item.children, level + 1));
    }
    return acc;
  }, []);
};

const DataSelector = ({
  timeframe,
  setTimeframe,
  selectedDataTypes,
  setSelectedDataTypes,
  availableMetrics,
  timeRange,
  setTimeRange,
  viewType,
  setViewType,
  activeTab
}) => {
  const flatMetrics = flattenMetrics(availableMetrics);

  const handleDataTypeChange = (values) => {
    if (values.length <= 5) {
      setSelectedDataTypes(values);
    }
  };

  return (
    <Space style={{ width: '100%' }} size="middle">
      <Radio.Group value={viewType} onChange={(e) => setViewType(e.target.value)}>
        <Radio.Button value="table">Table</Radio.Button>
        <Radio.Button value="chart">Bar Chart</Radio.Button>
        <Radio.Button value="sankey" disabled={activeTab === 'cash_flow'}>Flow Chart</Radio.Button>
      </Radio.Group>
      <Radio.Group value={timeframe} onChange={(e) => setTimeframe(e.target.value)}>
        <Radio.Button value="annual">Annual</Radio.Button>
        <Radio.Button value="quarter">Quarterly</Radio.Button>
      </Radio.Group>
      <Radio.Group value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
        {timeRanges.map(range => (
          <Radio.Button key={range.value} value={range.value}>
            {range.label}
          </Radio.Button>
        ))}
      </Radio.Group>
      {viewType === 'chart' && (
        <Select
          mode="multiple"
          value={selectedDataTypes}
          onChange={handleDataTypeChange}
          style={{ width: 'calc(100vw - 1150px)' }}
          placeholder="Select up to 5 metrics"
          maxTagCount={5}
          maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} more...`}
          showSearch
          optionFilterProp="children"
        >
          {flatMetrics.map(metric => (
            <Option key={metric.value} value={metric.value}>
              {'\u00A0'.repeat(metric.level * 2)}{metric.label}
            </Option>
          ))}
        </Select>
      )}
    </Space>
  );
};

export default DataSelector;
