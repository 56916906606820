import { message } from 'antd';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { applyStockScreen, createStockScreen, deleteStockScreen, getAccumulatedResultCount, getFieldOptions, getStockScreens, updateStockScreen } from '../services/api';

const StockScreenContext = createContext();

export const useStockScreen = () => useContext(StockScreenContext);

export const StockScreenProvider = ({ children }) => {
  const [screens, setScreens] = useState([]);
  const [cachedFieldOptions, setCachedFieldOptions] = useState({});

  const fetchScreens = useCallback(async () => {
    try {
      const fetchedScreens = await getStockScreens();
      setScreens(fetchedScreens);
    } catch (error) {
      console.error('Error fetching stock screens:', error);
      message.error('Failed to fetch stock screens');
    }
  }, []);

  const getScreenById = useCallback((screenId) => {
    return screens.find(screen => screen.id === screenId);
  }, [screens]);

  const createScreen = useCallback(async (screenName) => {
    try {
      const newScreen = await createStockScreen(screenName);
      setScreens(prevScreens => [...prevScreens, newScreen]);
      return newScreen;
    } catch (error) {
      console.error('Error creating stock screen:', error);
      message.error('Failed to create stock screen');
    }
  }, []);

  const updateScreen = useCallback(async (screenId, updateData) => {
    try {
      const updatedScreen = await updateStockScreen(screenId, updateData);
      setScreens(prevScreens => prevScreens.map(screen => 
        screen.id === screenId ? updatedScreen : screen
      ));
      return updatedScreen;
    } catch (error) {
      console.error('Error updating stock screen:', error);
      message.error('Failed to update stock screen');
    }
  }, []);

  const deleteScreen = useCallback(async (screenId) => {
    try {
      await deleteStockScreen(screenId);
      setScreens(prevScreens => prevScreens.filter(screen => screen.id !== screenId));
    } catch (error) {
      console.error('Error deleting stock screen:', error);
      message.error('Failed to delete stock screen');
    }
  }, []);

  const fetchAllFieldOptions = useCallback(async () => {
    const types = ['numeric', 'boolean', 'string'];
    try {
      const optionsPromises = types.map(type => getFieldOptions(type));
      const optionsResults = await Promise.all(optionsPromises);
      const newCachedOptions = types.reduce((acc, type, index) => {
        acc[type] = optionsResults[index];
        return acc;
      }, {});
      console.log('Fetched all field options:', newCachedOptions);
      setCachedFieldOptions(newCachedOptions);
    } catch (error) {
      console.error('Error fetching all field options:', error);
      message.error('Failed to fetch field options');
    }
  }, []);

  const getFieldOptionsFromCache = useCallback((criterionType) => {
    return cachedFieldOptions[criterionType] || { fields: [], values: {} };
  }, [cachedFieldOptions]);

  const applyScreen = useCallback(async (screenId) => {
    try {
      return await applyStockScreen(screenId);
    } catch (error) {
      console.error('Error applying stock screen:', error);
      message.error('Failed to apply stock screen');
      return [];
    }
  }, []);

  const fetchAccumulatedResultCount = useCallback(async (screenId, criteriaIndex) => {
    try {
      const response = await getAccumulatedResultCount(screenId, criteriaIndex);
      return response.result_count;
    } catch (error) {
      console.error('Error fetching accumulated result count:', error);
      message.error('Failed to fetch result count');
      return 0;
    }
  }, []);

  const value = {
    screens,
    fetchScreens,
    getScreenById,
    createScreen,
    updateScreen,
    deleteScreen,
    fetchAllFieldOptions,
    getFieldOptions: getFieldOptionsFromCache,
    applyScreen,
    getAccumulatedResultCount: fetchAccumulatedResultCount,
  };

  return (
    <StockScreenContext.Provider value={value}>
      {children}
    </StockScreenContext.Provider>
  );
};
