import { AutoComplete, Button, Input, Layout, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Logout from '../Auth/Logout';

import { UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { getSearchResults } from '../../services/api';
import ThemeToggle from '../ThemeToggle/ThemeToggle';

const { Header } = Layout;
const { Title } = Typography;

const TopBar = ({ onSearch, selectedTicker }) => {
  const [options, setOptions] = useState([]);
  const { currentUser } = useAuth();
  const [exchangeShortName, setExchangeShortName] = useState('');

  const userMenu = (
    <Menu>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <Logout />
      </Menu.Item>
    </Menu>
  );

  const handleSearch = async (value) => {
    if (value) {
      try {
        const response = await getSearchResults(value);
        const searchResults = response.data.results;
        setOptions(
          searchResults.map((result, index) => ({
            value: result.symbol,
            label: `${result.symbol} - ${result.name} (${result.exchangeShortName})`,
            'data-testid': `search-result-${index}`,
            exchangeShortName: result.exchangeShortName,
          }))
        );
      } catch (error) {
        console.error('Error fetching search results:', error);
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const handleSelect = (value, option) => {
    setExchangeShortName(option.exchangeShortName);
    onSearch(value, option.exchangeShortName);
  };

  return (
    <Header style={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between', 
      padding: '0 24px',
      position: 'relative',
      background: '#3a4a4a',
    }}>
      <Title level={4} style={{ color: 'white', margin: '0 20px 0 0' }}>TradeWise</Title>
      <div style={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
      }}>
        <AutoComplete
          style={{ width: 450 }}
          options={options}
          onSearch={handleSearch}
          onSelect={handleSelect}
          defaultValue={selectedTicker}
          defaultActiveFirstOption={true}
          data-testid="stock-search"
        >
          <Input placeholder="Search by ticker or name (e.g., AAPL, Panasonic)" />
        </AutoComplete>
      </div>
      
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <div className="user-actions">
          {currentUser ? (
            <Dropdown overlay={userMenu} placement="bottomRight" >
              <Button style= {{margin: "0px 10px"}} icon={<UserOutlined />}>{currentUser.email}</Button>
            </Dropdown>
          ) : (
            <>
            </>
          )}
          <ThemeToggle />
        </div>
      </div>
    </Header>
  );
};

export default TopBar;
