import { theme } from 'antd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserProfile, updateUserProfile } from '../services/api';
import { useAuth } from './AuthContext';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [themePreference, setThemePreference] = useState('SYSTEM');
  const [systemTheme, setSystemTheme] = useState('LIGHT');
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchUserTheme = async () => {
      if (currentUser) {
        try {
          const response = await getUserProfile();
          setThemePreference(response.data.theme_preference);
        } catch (error) {
          console.error('Failed to fetch user theme preference:', error);
        }
      }
    };

    fetchUserTheme();
  }, [currentUser]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setSystemTheme(mediaQuery.matches ? 'DARK' : 'LIGHT');

    const handleChange = (e) => {
      setSystemTheme(e.matches ? 'DARK' : 'LIGHT');
    };

    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  const changeTheme = async (newTheme) => {
    setThemePreference(newTheme);
    if (currentUser) {
      try {
        await updateUserProfile({ theme_preference: newTheme });
      } catch (error) {
        console.error('Failed to update user theme preference:', error);
      }
    }
  };

  const isDarkMode = themePreference === 'DARK' || (themePreference === 'SYSTEM' && systemTheme === 'DARK');

  const themeConfig = {
    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      colorPrimary: '#4ea3a3', // New primary color (light green)
      colorBgContainer: isDarkMode ? '#141414' : '#ffffff',
      colorBgElevated: isDarkMode ? '#1f1f1f' : '#ffffff',
      colorText: isDarkMode ? '#ffffff' : '#000000',
    },
  };

  return (
    <ThemeContext.Provider value={{ themePreference, changeTheme, theme: themeConfig, isDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
