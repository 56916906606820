import React from 'react';
import { Table, Tabs } from 'antd';

const { TabPane } = Tabs;

const TableSupplyChainView = ({ supplyChainData, symbolData }) => {
  const renderPrice = (symbol) => {
    const data = symbolData[symbol];
    if (!data) return '-';
    return data.price ? data.price.toFixed(2) : '-';
  };

  const renderChange = (symbol) => {
    const data = symbolData[symbol];
    if (!data || !data.price || !data.previousClose) return '-';
    const change = data.price - data.previousClose;
    return (
      <span style={{ color: change >= 0 ? 'green' : 'red' }}>
        {change.toFixed(2)}
      </span>
    );
  };

  const renderChangePercent = (symbol) => {
    const data = symbolData[symbol];
    if (!data || !data.price || !data.previousClose) return '-';
    const changePercent = ((data.price - data.previousClose) / data.previousClose) * 100;
    return (
      <span style={{ color: changePercent >= 0 ? 'green' : 'red' }}>
        {changePercent.toFixed(2)}%
      </span>
    );
  };

  const columns = {
    suppliersCustomers: [
      { title: 'Symbol', dataIndex: 'symbol', key: 'symbol', sorter: (a, b) => a.symbol.localeCompare(b.symbol) },
      { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
      { title: 'Relation', dataIndex: 'relation', key: 'relation' },
      { title: 'Exposure', dataIndex: 'exposure', key: 'exposure' },
      { title: 'Price', key: 'price', render: (_, record) => renderPrice(record.symbol), sorter: (a, b) => (symbolData[a.symbol]?.price || 0) - (symbolData[b.symbol]?.price || 0) },
      { title: 'Change', key: 'change', render: (_, record) => renderChange(record.symbol), sorter: (a, b) => (symbolData[a.symbol]?.price - symbolData[a.symbol]?.previousClose || 0) - (symbolData[b.symbol]?.price - symbolData[b.symbol]?.previousClose || 0) },
      { title: 'Change(%)', key: 'changePercent', render: (_, record) => renderChangePercent(record.symbol), sorter: (a, b) => ((symbolData[a.symbol]?.price - symbolData[a.symbol]?.previousClose) / symbolData[a.symbol]?.previousClose || 0) - ((symbolData[b.symbol]?.price - symbolData[b.symbol]?.previousClose) / symbolData[b.symbol]?.previousClose || 0) },
    ],
    peers: [
      { title: 'Symbol', dataIndex: 'symbol', key: 'symbol', sorter: (a, b) => a.symbol.localeCompare(b.symbol) },
      { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
      { title: 'Threat', dataIndex: 'threat', key: 'threat' },
      { title: 'Friend', dataIndex: 'friend', key: 'friend' },
      { title: 'Price', key: 'price', render: (_, record) => renderPrice(record.symbol), sorter: (a, b) => (symbolData[a.symbol]?.price || 0) - (symbolData[b.symbol]?.price || 0) },
      { title: 'Change', key: 'change', render: (_, record) => renderChange(record.symbol), sorter: (a, b) => (symbolData[a.symbol]?.price - symbolData[a.symbol]?.previousClose || 0) - (symbolData[b.symbol]?.price - symbolData[b.symbol]?.previousClose || 0) },
      { title: 'Change(%)', key: 'changePercent', render: (_, record) => renderChangePercent(record.symbol), sorter: (a, b) => ((symbolData[a.symbol]?.price - symbolData[a.symbol]?.previousClose) / symbolData[a.symbol]?.previousClose || 0) - ((symbolData[b.symbol]?.price - symbolData[b.symbol]?.previousClose) / symbolData[b.symbol]?.previousClose || 0) },
    ],
    commodities: [
      { title: 'Symbol', dataIndex: 'symbol', key: 'symbol', sorter: (a, b) => a.symbol.localeCompare(b.symbol) },
      { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
      { title: 'Exposure', dataIndex: 'exposure', key: 'exposure' },
      { title: 'Price', key: 'price', render: (_, record) => renderPrice(record.symbol), sorter: (a, b) => (symbolData[a.symbol]?.price || 0) - (symbolData[b.symbol]?.price || 0) },
      { title: 'Change', key: 'change', render: (_, record) => renderChange(record.symbol), sorter: (a, b) => (symbolData[a.symbol]?.price - symbolData[a.symbol]?.previousClose || 0) - (symbolData[b.symbol]?.price - symbolData[b.symbol]?.previousClose || 0) },
      { title: 'Change(%)', key: 'changePercent', render: (_, record) => renderChangePercent(record.symbol), sorter: (a, b) => ((symbolData[a.symbol]?.price - symbolData[a.symbol]?.previousClose) / symbolData[a.symbol]?.previousClose || 0) - ((symbolData[b.symbol]?.price - symbolData[b.symbol]?.previousClose) / symbolData[b.symbol]?.previousClose || 0) },
    ],
  };

  return (
    <Tabs defaultActiveKey="suppliers">
      <TabPane tab="Suppliers" key="suppliers">
        <Table columns={columns.suppliersCustomers} dataSource={supplyChainData.suppliers} rowKey="symbol" />
      </TabPane>
      <TabPane tab="Customers" key="customers">
        <Table columns={columns.suppliersCustomers} dataSource={supplyChainData.customers} rowKey="symbol" />
      </TabPane>
      <TabPane tab="Peers" key="peers">
        <Table columns={columns.peers} dataSource={supplyChainData.peers} rowKey="symbol" />
      </TabPane>
      <TabPane tab="Commodities" key="commodities">
        <Table columns={columns.commodities} dataSource={supplyChainData.commodities} rowKey="symbol" />
      </TabPane>
    </Tabs>
  );
};

export default TableSupplyChainView;
