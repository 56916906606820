export const exchangeToCurrencyMap = {
  "AMEX": "$",
  "AMS": "€",
  "AQS": "$",
  "ASX": "A$",
  "ATH": "€",
  "BER": "€",
  "BME": "€",
  "BRU": "€",
  "BSE": "₹",
  "BUD": "Ft",
  "BUE": "AR$",
  "CAI": "E£",
  "CBOE": "$",
  "CNQ": "C$",
  "CPH": "kr",
  "DFM": "د.إ",
  "DOH": "ر.ق",
  "DUS": "€",
  "DXE": "€",
  "ETF": "$",
  "EURONEXT": "€",
  "HAM": "€",
  "HEL": "€",
  "HKSE": "HK$",
  "ICE": "$",
  "IOB": "£",
  "IST": "₺",
  "JKT": "Rp",
  "JNB": "R",
  "JPX": "¥",
  "KLS": "RM",
  "KOE": "₩",
  "KSC": "₩",
  "KUW": "د.ك",
  "LSE": "£",
  "MCX": "₹",
  "MEX": "$",
  "MIL": "€",
  "MUN": "€",
  "NASDAQ": "$",
  "NEO": "C$",
  "NIM": "₦",
  "NSE": "₹",
  "NYSE": "$",
  "NZE": "NZ$",
  "OEM": "€",
  "OQX": "$",
  "OSL": "kr",
  "OTC": "$",
  "PNK": "$",
  "PRA": "Kč",
  "RIS": "₽",
  "SAO": "R$",
  "SAU": "﷼",
  "SES": "S$",
  "SET": "฿",
  "SGO": "€",
  "SHH": "¥",
  "SHZ": "¥",
  "SIX": "CHF",
  "STO": "kr",
  "STU": "€",
  "TAI": "NT$",
  "TLV": "₪",
  "TSX": "C$",
  "TSXV": "C$",
  "TWO": "NT$",
  "VIE": "€",
  "VSE": "€",
  "WSE": "zł",
  "XETRA": "€"
};

export const getCurrencySymbol = (exchange) => {
  return exchangeToCurrencyMap[exchange] || '$';
};
