import React from 'react';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { useTheme } from '../../contexts/ThemeContext';


const Charts = ({ ticker }) => {
  const { isDarkMode } = useTheme();

  return (
    <AdvancedRealTimeChart 
      theme={isDarkMode ? "dark" : "light"} 
      symbol={ticker} 
      autosize
    ></AdvancedRealTimeChart>
  );
};

export default Charts;
