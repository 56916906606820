import React, { useEffect, useState } from "react";
import { Alert, Modal } from "antd";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { getEconomicCalendar } from "../../../services/api";
import EconomicEventSelector from "./EconomicEventSelector";
import EconomicEventTable from "./EconomicEventTable";
import "./styles.css";

const EconomicCalendar = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs().add(7, "day")]);
  const [countries, setCountries] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [chartTitle, setChartTitle] = useState("");
  const [expandedDates, setExpandedDates] = useState({});

  const fetchEvents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getEconomicCalendar({
        from_date: dateRange[0].format("YYYY-MM-DD"),
        to_date: dateRange[1].format("YYYY-MM-DD"),
        countries: countries.join(","),
        impacts: impacts.join(","),
      });
      setEvents(response);
      // Set all dates as expanded by default
      const allExpanded = {};
      response.forEach(event => {
        const date = dayjs(event.date).format("YYYY-MM-DD");
        allExpanded[date] = true;
      });
      setExpandedDates(allExpanded);
    } catch (error) {
      console.error("Error fetching economic calendar events:", error);
      setEvents([]);
      if (error.response && error.response.status === 400) {
        setError(error.response.data.detail);
      } else {
        setError("An error occurred while fetching data. Please try again.");
      }
    }
    setLoading(false);
  };

  const handleChartClick = async (record) => {
    setLoading(true);
    try {
      const response = await getEconomicCalendar({
        from_date: dayjs().subtract(10, "year").format("YYYY-MM-DD"),
        to_date: dayjs().format("YYYY-MM-DD"),
        countries: record.country,
        event_types: record.eventType,
      });
      
      const chartData = {
        labels: response.map(item => dayjs(item.date).toDate()),
        datasets: [
          {
            label: "Actual",
            data: response.map(item => ({
              x: dayjs(item.date).toDate(),
              y: item.actual
            })),
            backgroundColor: (context) => {
              const value = context.raw.y;
              if (value === null || value === undefined) return "rgba(200, 200, 200, 0.8)";
              return value < 0 ? "rgba(255, 99, 132, 0.6)" : "rgba(75, 192, 192, 0.6)";
            },
            borderColor: "transparent",
            borderWidth: 0,
          },
        ],
      };
      
      setChartData(chartData);
      setChartTitle(record.eventType);
      setModalVisible(true);
    } catch (error) {
      console.error("Error fetching chart data:", error);
      setError("An error occurred while fetching chart data. Please try again.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEvents();
  }, [dateRange, countries, impacts]);

  const handleDateChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange(dates);
    } else {
      setDateRange([]);
    }
  };

  const handleCountryChange = (selectedCountries) => {
    setCountries(selectedCountries);
  };

  const handleImpactChange = (selectedImpacts) => {
    setImpacts(selectedImpacts);
  };

  const toggleDateExpansion = (date) => {
    setExpandedDates(prev => ({ ...prev, [date]: !prev[date] }));
  };

  return (
    <div className="economic-calendar">
      <h1>Economic Calendar</h1>
      <EconomicEventSelector
        dateRange={dateRange}
        countries={countries}
        impacts={impacts}
        onDateChange={handleDateChange}
        onCountryChange={handleCountryChange}
        onImpactChange={handleImpactChange}
      />
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}
      <EconomicEventTable
        events={events}
        loading={loading}
        expandedDates={expandedDates}
        onToggleDateExpansion={toggleDateExpansion}
        onChartClick={handleChartClick}
      />
      <Modal
        title={chartTitle}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width="90vw"
        style={{ top: 20 }}
        footer={null}
      >
        {chartData && (
          <Bar
            data={chartData}
            options={{
              animation: false,
              transitions: {
                active: {
                  animation: {
                    duration: 0
                  }
                }
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    title: (context) => dayjs(context[0].parsed.x).format("YYYY-MM-DD"),
                    label: (context) => `Actual: ${context.parsed.y}`,
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: false,
                },
                x: {
                  type: "time",
                  time: {
                    unit: "month",
                    displayFormats: {
                      month: "MMM yyyy",
                    },
                  },
                  title: {
                    display: true,
                    text: "Date",
                  },
                },
              },
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default EconomicCalendar;
