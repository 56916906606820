import { Alert, Card, Col, Row, Spin, Tabs, Radio } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getAnalysts, getQuarterlyEstimatesAndActuals, getYearlyEstimatesAndActuals } from '../../services/api';
import { getCurrencySymbol } from '../../utils/currencyMapping';
import AnalystDistribution from './AnalystDistribution';
import AnalystEstimates from './AnalystEstimates';
import './Analysts.css';
import AnalystSummary from './AnalystSummary';
import PriceTargets from './PriceTargets';
import RecommendationHistoryTable from './RecommendationHistoryTable';
import RecommendationsHistory from './RecommendationsHistory';

const { TabPane } = Tabs;

const Analysts = ({ ticker, exchangeShortName }) => {
  const [analystData, setAnalystData] = useState(null);
  const [estimatesData, setEstimatesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('ratings');
  const [estimateType, setEstimateType] = useState('eps');
  const [timeRange, setTimeRange] = useState('2y');
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [ratingViewMode, setRatingViewMode] = useState('chart');
  const [frequencyMode, setFrequencyMode] = useState('quarterly');

  const dataCache = useRef({ quarterly: null, yearly: null });

  const fetchEstimatesData = useCallback(async (frequency) => {
    if (dataCache.current[frequency]) {
      setEstimatesData(dataCache.current[frequency]);
      return;
    }

    try {
      setLoading(true);
      const response = frequency === 'quarterly' 
        ? await getQuarterlyEstimatesAndActuals(ticker)
        : await getYearlyEstimatesAndActuals(ticker);
      dataCache.current[frequency] = response.data;
      setEstimatesData(response.data);
    } catch (err) {
      setError(`Failed to fetch ${frequency} estimates. Please try again later.`);
      console.error(`Error fetching ${frequency} estimates:`, err);
    } finally {
      setLoading(false);
    }
  }, [ticker]);

  useEffect(() => {
    const fetchAnalystData = async () => {
      try {
        setLoading(true);
        const response = await getAnalysts(ticker);
        setAnalystData(response.data);
      } catch (err) {
        setError('Failed to fetch analyst data. Please try again later.');
        console.error('Error fetching analyst data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalystData();
  }, [ticker]);

  useEffect(() => {
    if (activeTab === 'estimates') {
      fetchEstimatesData(frequencyMode);
    }
  }, [activeTab, frequencyMode, fetchEstimatesData]);

  useEffect(() => {
    const savedActiveTab = localStorage.getItem('analystsActiveTab');
    const savedEstimateType = localStorage.getItem('selectedEstimateType');
    const savedTimeRange = localStorage.getItem('estimatesTimeRange');
    const savedRatingViewMode = localStorage.getItem('ratingViewMode');
    const savedFrequencyMode = localStorage.getItem('frequencyMode');
    
    if (savedActiveTab) setActiveTab(savedActiveTab);
    if (savedEstimateType) setEstimateType(savedEstimateType);
    if (savedTimeRange) setTimeRange(savedTimeRange);
    if (savedRatingViewMode) setRatingViewMode(savedRatingViewMode);
    if (savedFrequencyMode) setFrequencyMode(savedFrequencyMode);
  }, []);

  useEffect(() => {
    localStorage.setItem('analystsActiveTab', activeTab);
    localStorage.setItem('selectedEstimateType', estimateType);
    localStorage.setItem('estimatesTimeRange', timeRange);
    localStorage.setItem('ratingViewMode', ratingViewMode);
    localStorage.setItem('frequencyMode', frequencyMode);
  }, [activeTab, estimateType, timeRange, ratingViewMode, frequencyMode]);

  useEffect(() => {
    setCurrencySymbol(getCurrencySymbol(exchangeShortName));
  }, [exchangeShortName]);

  const handleFrequencyChange = (newFrequency) => {
    setFrequencyMode(newFrequency);
    fetchEstimatesData(newFrequency);
  };

  if (loading) return <Spin size="large" />;
  if (error) return <Alert message="Error" description={error} type="error" />;

  return (
    <div className="analysts-container">
      <Card>
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="Ratings" key="ratings">
            <div className="analysts-content">
              <Row gutter={16} className="charts-row">
                <Col style={{ margin: "0px 0px 10px"}} span={24}>
                  <Radio.Group
                    value={ratingViewMode}
                    onChange={(e) => setRatingViewMode(e.target.value)}
                  >
                    <Radio.Button value="chart">Chart</Radio.Button>
                    <Radio.Button value="table">Table</Radio.Button>
                  </Radio.Group>
                </Col>
                <Col span={24}>
                  {ratingViewMode === 'chart' ? (
                    <Row gutter={16}>
                      {analystData?.currentConsensus && (
                        <Col span={24}>
                          <AnalystSummary currentConsensus={analystData.currentConsensus} />
                        </Col>
                      )}
                      <Col span={12}>
                        {analystData?.currentDistribution && (
                          <AnalystDistribution distribution={analystData.currentDistribution} />
                        )}
                      </Col>
                      <Col span={12}>
                        {analystData?.recommendationsHistory && (
                          <RecommendationsHistory history={analystData.recommendationsHistory} />
                        )}
                      </Col>
                    </Row>
                  ) : (
                    analystData?.recommendationsHistory && (
                      <RecommendationHistoryTable history={analystData.recommendationsHistory} />
                    )
                  )}
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Estimates" key="estimates">
            {estimatesData ? (
              <AnalystEstimates 
                data={estimatesData}
                ticker={ticker}
                estimateType={estimateType}
                setEstimateType={setEstimateType}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                currencySymbol={currencySymbol}
                frequencyMode={frequencyMode}
                setFrequencyMode={handleFrequencyChange}
              />
            ) : (
              <Alert message="No estimates data available" type="warning" />
            )}
          </TabPane>
          <TabPane tab="Price Targets" key="price-target">
            <PriceTargets ticker={ticker} currencySymbol={currencySymbol} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default Analysts;
