import { Typography } from 'antd';
import React from 'react';
import { Chart } from 'react-chartjs-2';


const { Title: AntTitle } = Typography;

const RecommendationsHistory = ({ history }) => {
  const reversedHistory = history.slice().reverse();

  const data = {
    labels: reversedHistory.map(item => item.date),
    datasets: [
      {
        type: 'line',
        label: 'Avg Rec',
        data: reversedHistory.map(item => item.meanRec),
        borderColor: '#1890ff',
        fill: false,
        yAxisID: 'y',
        pointRadius: 0, // This line removes the dots
      },
      {
        type: 'bar',
        label: 'Strong B',
        data: reversedHistory.map(item => item.strongBuy),
        backgroundColor: '#52c41a',
        stack: 'Stack 0',
        yAxisID: 'y1',
      },
      {
        type: 'bar',
        label: 'Moderate B',
        data: reversedHistory.map(item => item.moderateBuy),
        backgroundColor: '#95de64',
        stack: 'Stack 0',
        yAxisID: 'y1',
      },
      {
        type: 'bar',
        label: 'Hold',
        data: reversedHistory.map(item => item.hold),
        backgroundColor: '#ffd666',
        stack: 'Stack 0',
        yAxisID: 'y1',
      },
      {
        type: 'bar',
        label: 'Moderate S',
        data: reversedHistory.map(item => item.moderateSell),
        backgroundColor: '#ffa39e',
        stack: 'Stack 0',
        yAxisID: 'y1',
      },
      {
        type: 'bar',
        label: 'Strong S',
        data: reversedHistory.map(item => item.strongSell),
        backgroundColor: '#ff4d4f',
        stack: 'Stack 0',
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    animation: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Recommendations History',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        min: 1,
        max: 5,
        ticks: {
          stepSize: 1,
          callback: (value) => {
            return ['Strong Sell', 'Moderate Sell', 'Hold', 'Moderate Buy', 'Strong Buy'][value - 1];
          },
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
        },
        title: {
          display: true,
          text: 'Number of Analysts',
        },
      },
    },
  };

  return (
    <div className="recommendations-history">
      <AntTitle level={4}>Recommendations History</AntTitle>
      <Chart type='bar' data={data} options={options} />
    </div>
  );
};

export default RecommendationsHistory;
