import { Col, DatePicker, Row, Select } from 'antd';
import * as CountryFlags from 'country-flag-icons/react/3x2';
import ISO31661 from 'iso-3166-1-alpha-2';
import React, { useMemo } from 'react';

const { RangePicker } = DatePicker;
const { Option } = Select;

const getFlag = (code) => {
  const Flag = CountryFlags[code];
  return Flag ? <Flag style={{ width: '1em', marginRight: '0.5em' }} /> : null;
};

const majorEconomies = ['US', 'CN', 'JP', 'DE', 'UK', 'IN'];

const EconomicEventSelector = ({ dateRange, countries, impacts, onDateChange, onCountryChange, onImpactChange }) => {
  const handleDateChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      onDateChange(dates);
    } else {
      onDateChange([]);
    }
  };

  const countryOptions = useMemo(() => {
    const allCountries = Object.entries(ISO31661.getData()).map(([code, name]) => ({
      value: code,
      label: (
        <span>
          {getFlag(code)}
          {name} ({code})
        </span>
      ),
      searchLabel: `${name} (${code})`,
    }));

    // Special case for UK
    const ukIndex = allCountries.findIndex(country => country.value === 'GB');
    if (ukIndex !== -1) {
      allCountries[ukIndex] = {
        value: 'UK',
        label: (
          <span>
            {getFlag('GB')}
            United Kingdom (UK)
          </span>
        ),
        searchLabel: 'United Kingdom (UK)',
      };
    }

    // Separate major economies and other countries
    const majorEconomyOptions = majorEconomies.map(code => 
      allCountries.find(country => country.value === code)
    ).filter(Boolean);

    const otherCountries = allCountries.filter(country => 
      !majorEconomies.includes(country.value)
    ).sort((a, b) => a.searchLabel.localeCompare(b.searchLabel));

    return [
      {
        label: 'Major Economies',
        options: majorEconomyOptions,
      },
      {
        label: 'Other Countries',
        options: otherCountries,
      },
    ];
  }, []);

  const filterOption = (inputValue, option) => {
    if (!inputValue || typeof inputValue !== 'string') {
      return true;
    }

    const input = inputValue.toLowerCase();
    const { searchLabel, value } = option || {};

    if (!searchLabel || !value) {
      return false;
    }

    // Prioritize exact matches for two-character country codes
    if (input.length === 2 && value.toLowerCase() === input) {
      return true;
    }

    return searchLabel.toLowerCase().includes(input) || value.toLowerCase().includes(input);
  };

  return (
    <Row gutter={16} style={{ marginBottom: 16 }}>
      <Col span={5}>
        <RangePicker
          value={dateRange}
          onChange={handleDateChange}
          style={{ width: "100%" }}
          allowClear={false}
          format="YYYY-MM-DD"
        />
      </Col>
      <Col span={12}>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select countries"
          onChange={onCountryChange}
          value={countries}
          options={countryOptions}
          filterOption={filterOption}
          showSearch
          optionFilterProp="searchLabel"
          optionLabelProp="label"
        />
      </Col>
      <Col span={7}>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select impact levels"
          onChange={onImpactChange}
          value={impacts}
        >
          <Option value="Low">Low</Option>
          <Option value="Medium">Medium</Option>
          <Option value="High">High</Option>
        </Select>
      </Col>
    </Row>
  );
};

export default EconomicEventSelector;
