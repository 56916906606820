import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Layout, message, Modal, Popconfirm, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useStockScreen } from '../../contexts/StockScreenContext';
import { useTheme } from '../../contexts/ThemeContext';
import ScreenCriteriaPanel from './ScreenCriteriaPanel';
import ScreenPerformanceChart from './ScreenPerformanceChart';
import ScreenResultsTable from './ScreenResultsTable';

const { Content } = Layout;
const { TabPane } = Tabs;

const StockScreenerPage = () => {
  const { theme } = useTheme();
  const { screens, fetchScreens, createScreen, updateScreen, deleteScreen, fetchAllFieldOptions } = useStockScreen();
  const [activeScreenKey, setActiveScreenKey] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [screenName, setScreenName] = useState('');
  const [editingScreenId, setEditingScreenId] = useState(null);
  const [currentCriteria, setCurrentCriteria] = useState([]);

  useEffect(() => {
    fetchScreens();
    fetchAllFieldOptions();
  }, [fetchScreens, fetchAllFieldOptions]);

  useEffect(() => {
    if (screens.length > 0 && !activeScreenKey) {
      setActiveScreenKey(screens[0].id);
      setCurrentCriteria(screens[0].criteria || []);
    }
  }, [screens]);

  const handleScreenTabChange = (key) => {
    if (key === 'add') {
      setModalTitle('Create New Screen');
      setScreenName('');
      setEditingScreenId(null);
      setIsModalVisible(true);
    } else {
      setActiveScreenKey(key);
      const selectedScreen = screens.find(screen => screen.id === key);
      setCurrentCriteria(selectedScreen?.criteria || []);
    }
  };

  const handleEditScreen = (screenId, screenName, event) => {
    event.stopPropagation();
    setModalTitle('Edit Screen');
    setScreenName(screenName);
    setEditingScreenId(screenId);
    setIsModalVisible(true);
  };

  const handleDeleteScreen = async (screenId) => {
    try {
      await deleteScreen(screenId);
      message.success('Screen deleted successfully');
      if (activeScreenKey === screenId) {
        const remainingScreens = screens.filter(s => s.id !== screenId);
        setActiveScreenKey(remainingScreens[0]?.id || '');
        setCurrentCriteria(remainingScreens[0]?.criteria || []);
      }
    } catch (error) {
      console.error('Error deleting screen:', error);
      message.error('Failed to delete screen');
    }
  };

  const handleModalOk = async () => {
    if (!screenName.trim()) {
      message.error('Please enter a screen name');
      return;
    }

    try {
      if (editingScreenId) {
        await updateScreen(editingScreenId, { name: screenName });
        message.success('Screen updated successfully');
      } else {
        const newScreen = await createScreen(screenName);
        setActiveScreenKey(newScreen.id);
        setCurrentCriteria(newScreen.criteria || []);
        message.success('Screen created successfully');
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error creating/updating screen:', error);
      message.error('Failed to create/update screen');
    }
  };

  const handleCriteriaChange = (newCriteria) => {
    setCurrentCriteria(newCriteria);
  };

  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ padding: '20px' }}>
        <Tabs
          activeKey={activeScreenKey}
          onChange={handleScreenTabChange}
          type="card"
          tabBarExtraContent={{
            right: (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => handleScreenTabChange('add')}
              >
                Add Screen
              </Button>
            ),
          }}
        >
          {screens.map(screen => (
            <TabPane
              tab={
                <span>
                  {screen.name}
                  <Divider type="vertical" />
                  <EditOutlined
                    onClick={(e) => handleEditScreen(screen.id, screen.name, e)}
                  />
                  {screens.length > 1 && (
                    <Popconfirm
                      title="Are you sure you want to delete this screen?"
                      onConfirm={() => handleDeleteScreen(screen.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Popconfirm>
                  )}
                </span>
              }
              key={screen.id}
            >
              <ScreenCriteriaPanel screenId={activeScreenKey} onCriteriaChange={handleCriteriaChange} />
              <Tabs defaultActiveKey="results">
                <TabPane tab="Results" key="results">
                  <ScreenResultsTable screenId={activeScreenKey} criteria={currentCriteria} />
                </TabPane>
                <TabPane tab="Performance" key="performance">
                  <ScreenPerformanceChart screenId={activeScreenKey} />
                </TabPane>
              </Tabs>
            </TabPane>
          ))}
        </Tabs>
        <Modal
          title={modalTitle}
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={() => setIsModalVisible(false)}
        >
          <Input
            placeholder="Enter screen name"
            value={screenName}
            onChange={(e) => setScreenName(e.target.value)}
          />
        </Modal>
      </Content>
    </Layout>
  );
};

export default StockScreenerPage;
