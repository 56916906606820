import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Space, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { getNews, markNewsAsRead } from '../../services/api';
import './News.css';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const News = ({ tickers }) => {
  const { isDarkMode } = useTheme();
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([]);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm'),
      width: '150px',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Source',
      dataIndex: 'site',
      key: 'site',
    },
    {
      title: '',
      key: 'action',
      render: () => <RightOutlined />,
      width: '50px',
    },
  ];

  const fetchNews = async () => {
    try {
      setLoading(true);
      const params = {
        tickers: Array.isArray(tickers) ? tickers.join(",") : tickers,
        page,
      };
      if (dateRange.length === 2) {
        params.start_date = dateRange[0].format('YYYY-MM-DD');
        params.end_date = dateRange[1].format('YYYY-MM-DD');
      }
      const response = await getNews(params);
      console.log('API Response:', response.data);
      setNewsData(response.data.current_page);
      setHasNextPage(response.data.has_next_page);
    } catch (error) {
      console.error('Error fetching news:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, [tickers, dateRange, page]);

  const handleRowClick = useCallback((record) => {
    return {
      onClick: async () => {
        try {
          await markNewsAsRead({
            publishedDate: record.publishedDate,
            title: record.title,
            site: record.site
          });
          setNewsData(prevData => 
            prevData.map(item => 
              item.title === record.title ? { ...item, is_read: true } : item
            )
          );
        } catch (error) {
          console.error('Error marking article as read:', error);
        }
        window.open(record.url, '_blank', 'noopener,noreferrer');
      },
    };
  }, []);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    setPage(0);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const renderPagination = () => (
    <Space>
      <Button onClick={handlePreviousPage} disabled={page === 0}>
        <LeftOutlined /> Back
      </Button>
      <span>Page {page + 1}</span>
      <Button onClick={handleNextPage} disabled={!hasNextPage}>
        Next <RightOutlined />
      </Button>
    </Space>
  );

  const tickerDisplay = Array.isArray(tickers) ? tickers.join(', ') : tickers;

  return (
    <div className={isDarkMode ? 'dark-theme' : ''}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        {renderPagination()}
        <RangePicker onChange={handleDateRangeChange} />
      </div>
      <Table 
        loading={loading}
        pagination={false}
        size="small" 
        columns={columns} 
        dataSource={newsData}
        onRow={(record) => ({
          ...handleRowClick(record),
          className: `news-row ${record.is_read ? 'read-article' : ''}`,
        })}
        components={{
          body: {
            row: (props) => (
              <Tooltip title="Click to read full article">
                <tr {...props} />
              </Tooltip>
            ),
          },
        }}
      />
      <div style={{ marginTop: '16px' }}>
        {renderPagination()}
      </div>
    </div>
  );
};

export default News;
