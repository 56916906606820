import React, { useState, useEffect } from 'react';
import { Input, Button, message, Typography, List, Tag, Space } from 'antd';
import { RobotOutlined } from '@ant-design/icons';
import { generateAIWatchlist, getWatchlists } from '../../services/api';

const { TextArea } = Input;
const { Title, Text } = Typography;

const MAX_PROMPT_LENGTH = 500;

const EXAMPLE_PROMPTS = [
  "Add 5 US stocks under 10 billion dollars market cap which are dealing with AI and have huge potential in 10 years",
  "Add AAPL suppliers",
  "Add top 3 performing tech stocks in the last quarter",
  "Add 5 dividend-paying stocks in the healthcare sector",
  "Add emerging market ETFs with low expense ratios"
];

const WatchlistAI = ({ watchlistId }) => {
  const [aiPrompt, setAiPrompt] = useState('');
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);
  const [aiPrompts, setAiPrompts] = useState([]);

  useEffect(() => {
    fetchAIPrompts();
  }, [watchlistId]);

  const fetchAIPrompts = async () => {
    try {
      const response = await getWatchlists();
      const watchlist = response.data.find(w => w.id === watchlistId);
      if (watchlist && watchlist.llm_prompts) {
        setAiPrompts(watchlist.llm_prompts);
      }
    } catch (error) {
      console.error('Error fetching AI prompts:', error);
    }
  };

  const handleGenerateAI = async () => {
    if (!aiPrompt.trim()) {
      message.error('Please enter an AI prompt');
      return;
    }

    setIsGeneratingAI(true);
    try {
      await generateAIWatchlist(watchlistId, aiPrompt);
      setAiPrompts(prevPrompts => [{ prompt: aiPrompt, status: 'PENDING' }, ...prevPrompts]);
      setAiPrompt('');
      message.success('AI-generated watchlist request submitted successfully');
      fetchAIPrompts(); // Refresh the prompts list
    } catch (error) {
      console.error('Error generating AI watchlist:', error);
      message.error('Failed to generate AI watchlist');
    } finally {
      setIsGeneratingAI(false);
    }
  };

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Title level={4}>Manage Watchlist via AI</Title>
      <TextArea
        placeholder={`Enter AI prompt to generate or modify watchlist. Examples:\n${EXAMPLE_PROMPTS.join('\n')}`}
        value={aiPrompt}
        onChange={(e) => setAiPrompt(e.target.value.slice(0, MAX_PROMPT_LENGTH))}
        rows={6}
        maxLength={MAX_PROMPT_LENGTH}
        showCount
      />
      <Button
        type="primary"
        icon={<RobotOutlined />}
        onClick={handleGenerateAI}
        loading={isGeneratingAI}
      >
        Add stocks using AI Prompt
      </Button>
      {aiPrompts.length > 0 && (
        <div>
          <Title level={5}>AI Prompts History</Title>
          <List
            itemLayout="horizontal"
            dataSource={aiPrompts}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  title={`Prompt ${aiPrompts.length - index}`}
                  description={item.prompt}
                />
                <Tag color={item.status === 'DONE' ? 'green' : 'blue'}>{item.status}</Tag>
              </List.Item>
            )}
          />
        </div>
      )}
    </Space>
  );
};

export default WatchlistAI;
