import { Divider, Tag } from 'antd';
import React from 'react';
import { useETFHoldings } from '../../contexts/ETFHoldingsContext';

const largeTagStyle = {
  fontSize: '12px',
  padding: '6px 20px',
  height: 'auto',
  lineHeight: '1.5',
  cursor: 'pointer',
};

const ClassificationTags = () => {
  const { selectedClassifications, setSelectedClassifications } = useETFHoldings();
  const classifications = ['sector', 'industry', 'country', 'state', 'exchange', 'type', 'currency'];

  const toggleClassification = (classification) => {
    if (selectedClassifications.includes(classification)) {
      setSelectedClassifications(selectedClassifications.filter((c) => c !== classification));
    } else {
      setSelectedClassifications([...selectedClassifications, classification]);
    }
  };

  return (
    <div className='classification-tags'>
      <b>Group by:</b>
      <Divider type="vertical" />
      {classifications.map((classification) => (
        <Tag
          key={classification}
          color={selectedClassifications.includes(classification) ? 'blue' : 'default'}
          onClick={() => toggleClassification(classification)}
          style={{
            ...largeTagStyle,
            // Add any additional custom styles here
          }}
        >
          {classification}
        </Tag>
      ))}
    </div>
  );
};

export default ClassificationTags;

