import React, { useState } from 'react';
import { AutoComplete, Input, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getSearchResults, addSymbolToWatchlist } from '../../services/api';

const SymbolSearch = ({ watchlistId, onSymbolAdded }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (value) => {
    if (value) {
      setIsLoading(true);
      try {
        const response = await getSearchResults(value);
        const searchResults = response.data.results;
        setOptions(
          searchResults.map((result) => ({
            value: result.symbol,
            label: `${result.symbol} - ${result.name} (${result.exchangeShortName})`,
            exchangeShortName: result.exchangeShortName,
          }))
        );
      } catch (error) {
        console.error('Error fetching search results:', error);
        message.error('Failed to search symbols');
      } finally {
        setIsLoading(false);
      }
    } else {
      setOptions([]);
    }
  };

  const handleAddSymbol = async (symbol) => {
    setIsLoading(true);
    try {
      await addSymbolToWatchlist(watchlistId, symbol);
      message.success(`Added ${symbol} to the watchlist`);
      setOptions([]);
      if (onSymbolAdded) {
        onSymbolAdded();
      }
    } catch (error) {
      console.error('Error adding symbol to watchlist:', error);
      message.error('Failed to add symbol to watchlist');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (value) => {
    handleAddSymbol(value);
  };

  const handleSearchSubmit = (value) => {
    if (options.length > 0) {
      handleAddSymbol(options[0].value);
    }
  };

  return (
    <AutoComplete
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      style={{ width: '100%', marginBottom: '20px' }}
      defaultActiveFirstOption
    >
      <Input.Search
        placeholder="Search and add symbol (e.g., AAPL, Microsoft)"
        enterButton={<PlusOutlined />}
        size="large"
        loading={isLoading}
        onSearch={handleSearchSubmit}
      />
    </AutoComplete>
  );
};

export default SymbolSearch;
