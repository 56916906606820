import { GoogleOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Space } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { login, loginWithGoogle } = useAuth();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await login(values.email, values.password);
      message.success('Logged in successfully');
      navigate('/');
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      await loginWithGoogle();
      message.success('Logged in successfully with Google');
      navigate('/');
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  return (
    <div style={{
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
    }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: 'white' }}>Login to TradeWise</h2>
      <Form name="login" onFinish={onFinish} layout="vertical">
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button 
              type="primary" 
              htmlType="submit" 
              loading={loading} 
              block
              style={{ backgroundColor: theme.token.colorPrimary }}
            >
              Log In
            </Button>
            <Button 
              onClick={handleGoogleLogin} 
              loading={loading} 
              icon={<GoogleOutlined />} 
              style={{ backgroundColor: 'rgba(255, 99, 132, 0.7)', borderColor: '#DB4437', color: 'white' }}
              block
            >
              Log In with Google
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Link to="/signup">
          <Button type="link" style={{ color: 'white' }}>Don't have an account? Sign Up</Button>
        </Link>
      </div>
    </div>
  );
};

export default Login;
