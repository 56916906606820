import { Typography } from 'antd';
import React from 'react';
import { Bar } from 'react-chartjs-2';


const { Title: AntTitle } = Typography;

const AnalystDistribution = ({ distribution }) => {
  const data = {
    labels: ['Strong Buy', 'Moderate Buy', 'Hold', 'Moderate Sell', 'Strong Sell'],
    datasets: [
      {
        data: [
          distribution.strongBuy,
          distribution.moderateBuy,
          distribution.hold,
          distribution.moderateSell,
          distribution.strongSell,
        ],
        backgroundColor: ['#4CAF50', '#8BC34A', '#FFC107', '#FF9800', '#F44336'],
      },
    ],
  };

  const options = {
    animation: false,
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="analyst-distribution">
      <AntTitle level={4}>Analyst Ratings Distribution</AntTitle>
      <Bar data={data} options={options} />
    </div>
  );
};

export default AnalystDistribution;
