import { Button, Popover } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import { formatDate } from './utilities';


const generateColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const hue = (i * 360) / numColors;
    colors.push(`hsla(${hue}, 70%, 50%, 1)`);
  }
  return colors;
};

const colorPalette = generateColors(50);

const PriceTargetsChartView = ({ priceTargets, currencySymbol, showLatest, onAnalystClick }) => {
  const [hoveredAnalyst, setHoveredAnalyst] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const chartRef = useRef(null);

  const chartData = useMemo(() => {
    const data = showLatest
      ? Object.values(
          priceTargets.reduce((acc, target) => {
            if (!acc[target.analystName] || new Date(target.publishedDate) > new Date(acc[target.analystName].publishedDate)) {
              acc[target.analystName] = target;
            }
            return acc;
          }, {})
        )
      : priceTargets;

    const analysts = [...new Set(data.map(target => target.analystName))];

    return {
      datasets: [{
        label: 'Price Targets',
        data: data.map(target => ({
          x: new Date(target.publishedDate).getTime(),
          y: target.priceTarget,
          analyst: target.analystName,
          company: target.analystCompany,
          date: target.publishedDate,
          priceWhenPosted: target.priceWhenPosted,
          newsTitle: target.newsTitle,
          newsURL: target.newsURL,
        })),
        backgroundColor: data.map(target => {
          const baseColor = colorPalette[analysts.indexOf(target.analystName) % colorPalette.length];
          return hoveredAnalyst === null || hoveredAnalyst === target.analystName 
            ? baseColor 
            : baseColor.replace('1)', '0.2)');
        }),
        pointRadius: 6,
        pointHoverRadius: 8,
        pointBorderColor: 'transparent',
        pointBorderWidth: 2,
        pointHoverBorderWidth: 3,
        pointHoverBorderColor: 'white',
      }],
    };
  }, [priceTargets, showLatest, hoveredAnalyst]);

  const handleHover = useCallback((event, chartElement) => {
    if (chartElement && chartElement.length > 0) {
      const dataPoint = chartData.datasets[0].data[chartElement[0].index];
      setHoveredAnalyst(dataPoint.analyst);
      event.native.target.style.cursor = 'pointer';
    } else {
      setHoveredAnalyst(null);
      event.native.target.style.cursor = 'default';
    }
  }, [chartData]);

  const handleClick = useCallback((event, chartElement) => {
    if (chartElement && chartElement.length > 0) {
      const dataPoint = chartData.datasets[0].data[chartElement[0].index];
      setSelectedDataPoint(dataPoint);
      const chart = chartRef.current;
      const canvasPosition = chart.canvas.getBoundingClientRect();
      setPopoverPosition({
        x: canvasPosition.left + chartElement[0].element.x,
        y: canvasPosition.top + chartElement[0].element.y,
      });
      setPopoverVisible(true);
    } else {
      setPopoverVisible(false);
    }
  }, [chartData]);

  const options = {
    animation: false,
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Date',
        },
        ticks: {
          callback: function(value) {
            return formatDate(new Date(value));
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `Price Target (${currencySymbol})`,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    onHover: handleHover,
    onClick: handleClick,
  };

  const popoverContent = (
    <div>
      <Button onClick={() => {
        window.open(selectedDataPoint.newsURL, '_blank');
        setPopoverVisible(false);
      }}>
        Open News Article
      </Button>
      <Button onClick={() => {
        onAnalystClick(selectedDataPoint.analyst);
        setPopoverVisible(false);
      }}>
        View Analyst Profile
      </Button>
    </div>
  );

  return (
    <div style={{ position: 'relative' }}>
      <Scatter ref={chartRef} data={chartData} options={options} />
      <Popover
        content={popoverContent}
        visible={popoverVisible}
        onVisibleChange={setPopoverVisible}
        trigger="click"
        destroyTooltipOnHide
        overlayStyle={{ position: 'fixed', left: `${popoverPosition.x}px`, top: `${popoverPosition.y - 10}px` }}
      >
        <div style={{ position: 'fixed', left: `${popoverPosition.x}px`, top: `${popoverPosition.y}px`, width: '1px', height: '1px' }} />
      </Popover>
    </div>
  );
};

export default PriceTargetsChartView;
