import { Alert, Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getEmployeeCount } from '../../services/api';
import EmployeeCountTable from './EmployeeCountTable';

const { Text } = Typography;

const EmployeeCount = ({ ticker }) => {
  const [employeeCount, setEmployeeCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const employeeCountResponse = await getEmployeeCount(ticker);
        setEmployeeCount(employeeCountResponse.data || []);
      } catch (error) {
        console.error('Error fetching employee count data:', error);
        setError('Failed to fetch employee count data. Please try again later.');
      }
      setLoading(false);
    };

    if (ticker) {
      fetchData();
    } else {
      console.log('No ticker provided');
      setError('No ticker provided. Please select a company.');
      setLoading(false);
    }
  }, [ticker]);

  if (loading) {
    return <Alert message="Loading employee count data..." type="info" />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  if (employeeCount.length === 0) {
    return <Text>No employee count data available at the moment. Please check back later.</Text>;
  }

  const sortedData = employeeCount.sort((a, b) => new Date(a.periodOfReport) - new Date(b.periodOfReport));

  const chartData = {
    labels: sortedData.map(item => new Date(item.periodOfReport).getFullYear()),
    datasets: [
      {
        label: 'Employee Count',
        data: sortedData.map(item => item.employeeCount),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Employees',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Year',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `Employee Count: ${context.parsed.y.toLocaleString()}`,
        },
      },
    },
  };

  return (
    <Row gutter={16} style={{ height: 'calc(100vh - 200px)' }}>
      <Col span={8} style={{ height: '100%', overflowY: 'auto' }}>
        <EmployeeCountTable data={sortedData} />
      </Col>
      <Col span={16} style={{ height: '100%' }}>
        <Bar data={chartData} options={options} style={{ height: '100%' }} />
      </Col>
    </Row>
  );
};

export default EmployeeCount;
