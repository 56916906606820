import React from 'react';
import FinancialsTable from '../FinancialsPage/FinancialsTable';
import { getAnalystEstimatesMetrics } from './AnalystEstimatesMetrics';

const TableView = ({ data, currencySymbol }) => {
  const transformEstimatesData = (apiData) => {
    return apiData.map(item => ({
      date: item.date,
      calendarYear: item.calendarYear,
      period: item.period,
      epsEstimate: item.eps?.estimate,
      epsActual: item.eps?.actual,
      epsEstimateHigh: item.eps?.estimateHigh,
      epsEstimateLow: item.eps?.estimateLow,
      epsNumberAnalysts: item.eps?.numberAnalysts,
      revenueEstimate: item.revenue?.estimate,
      revenueActual: item.revenue?.actual,
      revenueEstimateHigh: item.revenue?.estimateHigh,
      revenueEstimateLow: item.revenue?.estimateLow,
      revenueNumberAnalysts: item.revenue?.numberAnalysts,
      ebitdaEstimate: item.ebitda?.estimate,
      ebitdaActual: item.ebitda?.actual,
      ebitdaEstimateHigh: item.ebitda?.estimateHigh,
      ebitdaEstimateLow: item.ebitda?.estimateLow,
      ebitdaNumberAnalysts: item.ebitda?.numberAnalysts,
      ebitEstimate: item.ebit?.estimate,
      ebitActual: item.ebit?.actual,
      ebitEstimateHigh: item.ebit?.estimateHigh,
      ebitEstimateLow: item.ebit?.estimateLow,
      ebitNumberAnalysts: item.ebit?.numberAnalysts,
      netIncomeEstimate: item.netIncome?.estimate,
      netIncomeActual: item.netIncome?.actual,
      netIncomeEstimateHigh: item.netIncome?.estimateHigh,
      netIncomeEstimateLow: item.netIncome?.estimateLow,
      netIncomeNumberAnalysts: item.netIncome?.numberAnalysts,
      sgaExpenseEstimate: item.sgaExpense?.estimate,
      sgaExpenseActual: item.sgaExpense?.actual,
      sgaExpenseEstimateHigh: item.sgaExpense?.estimateHigh,
      sgaExpenseEstimateLow: item.sgaExpense?.estimateLow,
      sgaExpenseNumberAnalysts: item.sgaExpense?.numberAnalysts,
    }));
  };

  return (
    <FinancialsTable
      data={transformEstimatesData(data)}
      metrics={getAnalystEstimatesMetrics()}
      currencySymbol={currencySymbol}
      timeRange={data.length}
    />
  );
};

export default TableView;
