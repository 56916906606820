import React, { useState, useEffect } from 'react';
import EarningsEventSelector from './EarningsEventSelector';
import EarningsEventTable from './EarningsEventTable';
import EarningsChartModal from './EarningsChartModal';
import { getEarningsCalendar, getQuarterlyEstimatesAndActuals } from '../../../services/api';
import dayjs from 'dayjs';

const EarningsCalendar = () => {
  const [dateRange, setDateRange] = useState([dayjs(), dayjs().add(7, 'day')]);
  const [countries, setCountries] = useState([]);
  const [influential, setInfluential] = useState(false);
  const [earningsData, setEarningsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedExchangeShortName, setSelectedExchangeShortName] = useState(null);
  const [chartModalVisible, setChartModalVisible] = useState(false);

  useEffect(() => {
    const fetchEarningsData = async () => {
      setLoading(true);
      try {
        const response = await getEarningsCalendar({
          from_date: dateRange[0].format('YYYY-MM-DD'),
          to_date: dateRange[1].format('YYYY-MM-DD'),
          countries: countries.join(','),
          influential: influential,
        });
        console.log('Earnings data fetched:', response);
        setEarningsData(response);
      } catch (error) {
        console.error('Error fetching earnings data:', error);
        // TODO: Handle error (e.g., show error message to user)
      }
      setLoading(false);
    };

    fetchEarningsData();
  }, [dateRange, countries, influential]);

  const handleDateChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange(dates);
    } else {
      setDateRange([]);
    }
  };

  const handleCountryChange = (selectedCountries) => {
    setCountries(selectedCountries);
  };

  const handleInfluentialChange = (value) => {
    setInfluential(value);
  };

  const handleChartClick = async (record) => {
    console.log('Chart clicked for record:', record);
    setSelectedCompany(record.companyName);
    setSelectedExchangeShortName(record.exchangeShortName);
    setChartModalVisible(true);
    try {
      const response = await getQuarterlyEstimatesAndActuals(record.symbol);
      console.log('Quarterly estimates and actuals fetched:', response);
      if (response && response.data && Array.isArray(response.data)) {
        setChartData(response.data);
      } else {
        console.error('Unexpected data structure:', response);
        setChartData(null);
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setChartData(null);
      // TODO: Handle error (e.g., show error message to user)
    }
  };

  const filteredEarningsData = influential
    ? earningsData.filter(event => event.is_influential)
    : earningsData;

  return (
    <div className="earnings-calendar">
      <h1>Earnings Calendar</h1>
      <EarningsEventSelector
        dateRange={dateRange}
        countries={countries}
        influential={influential}
        onDateChange={handleDateChange}
        onCountryChange={handleCountryChange}
        onInfluentialChange={handleInfluentialChange}
      />
      <EarningsEventTable
        events={filteredEarningsData}
        loading={loading}
        onChartClick={handleChartClick}
      />
      <EarningsChartModal
        visible={chartModalVisible}
        onClose={() => setChartModalVisible(false)}
        data={chartData}
        company={selectedCompany}
        exchangeShortName={selectedExchangeShortName}
      />
    </div>
  );
};

export default EarningsCalendar;
