import { Table, Tooltip, Typography } from 'antd';
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const { Title } = Typography;

const BarCell = ({ value, maxValue, color, allZero }) => {
  const { isDarkMode } = useTheme();
  const percentage = allZero ? 0 : (value / maxValue) * 100;
  const backgroundColor = isDarkMode ? '#1f1f1f' : '#f0f0f0';

  return (
    <Tooltip title={`${value} ${allZero ? '' : `(${percentage.toFixed(1)}%)`}`}>
      <div style={{ width: '100%', background: backgroundColor, borderRadius: '4px', overflow: 'hidden' }}>
        <div
          style={{
            width: `${percentage}%`,
            backgroundColor: color,
            color: 'white',
            padding: '4px 8px',
            textAlign: 'right',
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </div>
      </div>
    </Tooltip>
  );
};

const RecommendationHistoryTable = ({ history }) => {
  const preprocessedData = history.map(row => {
    const values = [row.strongBuy, row.moderateBuy, row.hold, row.moderateSell, row.strongSell];
    const maxValue = Math.max(...values);
    const allZero = values.every(value => value === 0);
    return { ...row, maxValue, allZero };
  });

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Strong Buy',
      dataIndex: 'strongBuy',
      key: 'strongBuy',
      render: (value, record) => <BarCell value={value} maxValue={record.maxValue} color="#52c41a" allZero={record.allZero} />,
    },
    {
      title: 'Moderate Buy',
      dataIndex: 'moderateBuy',
      key: 'moderateBuy',
      render: (value, record) => <BarCell value={value} maxValue={record.maxValue} color="#95de64" allZero={record.allZero} />,
    },
    {
      title: 'Hold',
      dataIndex: 'hold',
      key: 'hold',
      render: (value, record) => <BarCell value={value} maxValue={record.maxValue} color="#ffd666" allZero={record.allZero} />,
    },
    {
      title: 'Moderate Sell',
      dataIndex: 'moderateSell',
      key: 'moderateSell',
      render: (value, record) => <BarCell value={value} maxValue={record.maxValue} color="#ffa39e" allZero={record.allZero} />,
    },
    {
      title: 'Strong Sell',
      dataIndex: 'strongSell',
      key: 'strongSell',
      render: (value, record) => <BarCell value={value} maxValue={record.maxValue} color="#ff4d4f" allZero={record.allZero} />,
    },
    {
      title: 'Avg Rec',
      dataIndex: 'meanRec',
      key: 'meanRec',
      render: (text) => Number(text).toFixed(2),
    },
  ];

  return (
    <div className="recommendation-history-table">
      <Table 
        dataSource={preprocessedData.map((item, index) => ({ ...item, key: index }))} 
        columns={columns} 
        pagination={false}
        scroll={{ y: "calc(100vh - 400px)" }}
      />
    </div>
  );
};

export default RecommendationHistoryTable;
