import CanvasJSReact from '@canvasjs/react-stockcharts';
import React, { useEffect, useRef } from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const PriceChart = ({ data, symbol, timeRange, showCandlesticks, currencySymbol }) => {
  const { isDarkMode } = useTheme();
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.render();
    }
  }, [data, showCandlesticks, isDarkMode]);

  if (!data || data.length === 0) {
    console.log('No data available for PriceChart');
    return <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>No data available</div>;
  }

  const priceData = data.map(item => ({
    x: new Date(item.Date),
    y: showCandlesticks 
      ? [Number(item.Open), Number(item.High), Number(item.Low), Number(item.Close)]
      : Number(item.Close)
  }));

  const volumeData = data.map(item => ({
    x: new Date(item.Date),
    y: Number(item.Volume)
  }));

  const startPrice = showCandlesticks ? priceData[0].y[3] : priceData[0].y;
  const endPrice = showCandlesticks ? priceData[priceData.length - 1].y[3] : priceData[priceData.length - 1].y;
  const priceColor = endPrice >= startPrice ? "rgba(75, 192, 192, 1)" : "rgba(255, 99, 132, 1)";

  const options = {
    theme: isDarkMode ? "dark1" : "light1",
    charts: [
      {
        axisX: {
          crosshair: {
            enabled: true,
            snapToDataPoint: true
          }
        },
        axisY: {
          title: 'Price (' + currencySymbol + ')',
          prefix: currencySymbol,
          crosshair: {
            enabled: true,
            snapToDataPoint: true
          }
        },
        toolTip: {
          shared: true
        },
        data: [
          {
            type: showCandlesticks ? "candlestick" : "line",
            color: showCandlesticks ? undefined : priceColor,
            risingColor: "rgba(75, 192, 192, 1)",
            fallingColor: "rgba(255, 99, 132, 1)",
            lineThickness: showCandlesticks ? undefined : 2,
            markerSize: 0,
            yValueFormatString: currencySymbol + "#,##0.00",
            xValueFormatString: "MMM DD YYYY",
            dataPoints: priceData
          }
        ]
      },
      {
        height: 100,
        axisX: {
          crosshair: {
            enabled: true,
            snapToDataPoint: true
          }
        },
        axisY: {
          title: "Volume",
          crosshair: {
            enabled: true,
            snapToDataPoint: true
          },
          labelFormatter: function(e) {
            return CanvasJSReact.CanvasJS.formatNumber(e.value, "#,###,,.##M");
          }
        },
        toolTip: {
          shared: true
        },
        data: [
          {
            type: "column",
            color: isDarkMode ? "#4B5563" : "#D1D5DB",
            yValueFormatString: "#,###,###",
            xValueFormatString: "MMM DD YYYY",
            dataPoints: volumeData
          }
        ]
      }
    ],
    navigator: {
      slider: {
        minimum: new Date(data[0].Date),
        maximum: new Date(data[data.length - 1].Date)
      }
    },
    rangeSelector: {
      enabled: false
    },
    animationEnabled: false,
    zoomEnabled: true
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <CanvasJSStockChart options={options} ref={chartRef} />
    </div>
  );
};

export default PriceChart;
