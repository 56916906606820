import { Card, Col, Drawer, Radio, Row, Space, Spin, Switch, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { getPriceTargets } from '../../services/api';
import AnalystProfile from './AnalystProfile';
import PriceTargetsChartView from './PriceTargetsChartView';

const PriceTargets = ({ ticker, currencySymbol }) => {
  const [priceTargets, setPriceTargets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupBy, setGroupBy] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedAnalyst, setSelectedAnalyst] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [viewMode, setViewMode] = useState('chart');
  const [showLatest, setShowLatest] = useState(false);

  useEffect(() => {
    const fetchPriceTargets = async () => {
      setLoading(true);
      try {
        const response = await getPriceTargets(ticker);
        setPriceTargets(response.data);
      } catch (error) {
        console.error('Error fetching price targets:', error);
      }
      setLoading(false);
    };

    fetchPriceTargets();
  }, [ticker]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const groupedData = useMemo(() => {
    if (!groupBy) return priceTargets.map((item, index) => ({ ...item, key: `ungrouped-${index}` }));

    const grouped = priceTargets.reduce((acc, item) => {
      const key = item.analystName;
      if (!acc[key]) {
        acc[key] = {
          key: `grouped-${key}`,
          analystName: item.analystName,
          analystCompany: item.analystCompany,
          priceTargets: []
        };
      }
      acc[key].priceTargets.push(item);
      return acc;
    }, {});

    return Object.values(grouped).map(group => ({
      ...group,
      latestPriceTargets: group.priceTargets
        .sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate))
        .slice(0, 3)
        .map(item => `${currencySymbol}${item.priceTarget.toFixed(2)} (${formatDate(item.publishedDate)})`)
        .join(' -> ')
    }));
  }, [priceTargets, groupBy, currencySymbol]);

  const columns = groupBy
    ? [
        {
          title: 'Analyst Name',
          dataIndex: 'analystName',
          key: 'analystName',
          render: (text, record) => (
            <a onClick={() => handleAnalystClick(text)}>{text} from {record.analystCompany}</a>
          ),
        },
        {
          title: 'Latest Price Targets',
          dataIndex: 'latestPriceTargets',
          key: 'latestPriceTargets',
        },
      ]
    : [
        {
          title: 'Date',
          dataIndex: 'publishedDate',
          key: 'publishedDate',
          render: (date) => formatDate(date),
        },
        {
          title: 'Analyst Name',
          dataIndex: 'analystName',
          key: 'analystName',
          render: (text) => <a onClick={() => handleAnalystClick(text)}>{text}</a>,
        },
        {
          title: 'Company',
          dataIndex: 'analystCompany',
          key: 'analystCompany',
        },
        {
          title: 'Price Target',
          dataIndex: 'priceTarget',
          key: 'priceTarget',
          render: (price) => `${currencySymbol}${price.toFixed(2)}`,
        },
        {
          title: 'Price When Posted',
          dataIndex: 'priceWhenPosted',
          key: 'priceWhenPosted',
          render: (price) => `${currencySymbol}${price.toFixed(2)}`,
        },
        {
          title: 'News',
          dataIndex: 'newsTitle',
          key: 'newsTitle',
          render: (title, record) => (
            record.newsURL ? (
              <a href={record.newsURL} target="_blank" rel="noopener noreferrer">
                {title}
              </a>
            ) : title
          ),
        },
      ];

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: 'Date',
        dataIndex: 'publishedDate',
        key: 'publishedDate',
        render: (date) => formatDate(date),
      },
      {
        title: 'Price Target',
        dataIndex: 'priceTarget',
        key: 'priceTarget',
        render: (price) => `${currencySymbol}${price.toFixed(2)}`,
      },
      {
        title: 'Price When Posted',
        dataIndex: 'priceWhenPosted',
        key: 'priceWhenPosted',
        render: (price) => `${currencySymbol}${price.toFixed(2)}`,
      },
      {
        title: 'News',
        dataIndex: 'newsTitle',
        key: 'newsTitle',
        render: (title, item) => (
          item.newsURL ? (
            <a href={item.newsURL} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          ) : title
        ),
      },
    ];

    return (
      <Table
        columns={expandedColumns}
        dataSource={record.priceTargets}
        pagination={false}
        rowKey={(item) => `${item.publishedDate}-${item.analystName}`}
      />
    );
  };

  const onExpand = (expanded, record) => {
    setExpandedRowKeys(prevKeys => {
      if (expanded) {
        return [...prevKeys, record.key];
      } else {
        return prevKeys.filter(key => key !== record.key);
      }
    });
  };

  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
  };

  const handleAnalystClick = (analyst) => {
    setSelectedAnalyst(analyst);
    setDrawerVisible(true);
  };

  return (
    <Card>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row>
          <Col>
            <Space align="center">
              <Radio.Group value={viewMode} onChange={handleViewModeChange}>
                <Radio.Button value="chart">Chart</Radio.Button>
                <Radio.Button value="table">Table</Radio.Button>
              </Radio.Group>
              {viewMode === 'chart' && (
                <Switch
                  checked={showLatest}
                  onChange={setShowLatest}
                  checkedChildren="Show Latest"
                  unCheckedChildren="Show All"
                />
              )}
              {viewMode === 'table' && (
                <Switch
                  checked={groupBy}
                  onChange={setGroupBy}
                  checkedChildren="Grouped"
                  unCheckedChildren="Ungrouped"
                />
              )}
            </Space>
          </Col>
        </Row>
        {loading ? (
          <Spin size="large" />
        ) : viewMode === 'chart' ? (
          <PriceTargetsChartView
            priceTargets={priceTargets}
            currencySymbol={currencySymbol}
            showLatest={showLatest}
            onAnalystClick={handleAnalystClick}
          />
        ) : (
          <Table
            dataSource={groupedData}
            columns={columns}
            rowKey={(record) => record.key}
            pagination={{ pageSize: 8 }}
            expandable={groupBy ? {
              expandedRowRender,
              expandedRowKeys,
              onExpand,
            } : false}
            onRow={groupBy ? (record) => ({
              onClick: () => {
                onExpand(!expandedRowKeys.includes(record.key), record);
              },
              style: { cursor: 'pointer' }
            }) : undefined}
          />
        )}
      </Space>
      <Drawer
        title="Analyst Profile"
        placement="right"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={800}
      >
        <AnalystProfile analystName={selectedAnalyst} />
      </Drawer>
    </Card>
  );
};

export default PriceTargets;
