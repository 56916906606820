import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { formatValue } from '../../Analysts/utilities';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartView = ({ data, estimateType, timeRange, frequencyMode, currencySymbol }) => {
  console.log('ChartView props:', { data, estimateType, timeRange, frequencyMode, currencySymbol });

  const chartData = useMemo(() => {
    if (!data || data.length === 0) {
      console.log('No data available for chart');
      return null;
    }

    console.log('Preparing chart data for', estimateType);
    const labels = data.map(item => `${item.calendarYear} ${item.period}`);
    const actualData = data.map(item => item[estimateType]?.actual);
    const estimateData = data.map(item => item[estimateType]?.estimate);

    console.log('Chart data prepared:', { labels, actualData, estimateData });

    return {
      labels,
      datasets: [
        {
          label: 'Actual',
          data: actualData,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          type: 'bar'
        },
        {
          label: 'Estimate',
          data: estimateData,
          backgroundColor: 'rgb(54, 162, 235)',
          type: 'line',
          borderColor: 'rgb(54, 162, 235)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)'
        }
      ]
    };
  }, [data, estimateType]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    transitions: {
      active: {
        animation: {
          duration: 0
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return formatValue(value, estimateType !== 'eps', currencySymbol);
          }
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatValue(context.parsed.y, estimateType !== 'eps', currencySymbol);
            }
            return label;
          }
        }
      }
    }
  };

  if (!chartData) {
    console.log('No chart data available, rendering empty div');
    return <div>No data available for the selected time range and estimate type.</div>;
  }

  console.log('Rendering chart with data:', chartData);
  return (
    <div style={{ height: '400px' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default ChartView;
