import React from 'react';
import { Card, Col, Row, Typography, Statistic } from 'antd';

const { Text } = Typography;

const formatLargeNumber = (num) => {
  if (!num) return 'N/A';
  if (num >= 1e12) return (num / 1e12).toFixed(2) + 'T';
  if (num >= 1e9) return (num / 1e9).toFixed(2) + 'B';
  if (num >= 1e6) return (num / 1e6).toFixed(2) + 'M';
  if (num >= 1e3) return (num / 1e3).toFixed(2) + 'K';
  return num.toFixed(2);
};

const CompanyDetails = ({ companyData, currencySymbol, isETF }) => {
  const { profile, ratios } = companyData;
  const latestRatios = ratios && ratios.length > 0 ? ratios[0] : null;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title="Company Profile">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Statistic title="Sector" value={profile.sector || 'N/A'} />
            </Col>
            <Col span={8}>
              <Statistic title="Industry" value={profile.industry || 'N/A'} />
            </Col>
            <Col span={8}>
              <Statistic title="Market Cap" value={formatLargeNumber(profile.mktCap)} prefix={currencySymbol} />
            </Col>
            <Col span={8}>
              <Statistic title="Beta" value={profile.beta ? profile.beta.toFixed(2) : 'N/A'} />
            </Col>
            <Col span={8}>
              <Statistic title="Last Dividend" value={profile.lastDiv ? profile.lastDiv.toFixed(2) : 'N/A'} prefix={currencySymbol} />
            </Col>
            <Col span={8}>
              <Statistic title="52 Week Range" value={profile.range || 'N/A'} />
            </Col>
          </Row>
        </Card>
      </Col>
      {!isETF && latestRatios && (
        <Col span={24}>
          <Card title="Key Ratios">
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Statistic title="P/E Ratio" value={latestRatios.priceEarningsRatioTTM ? latestRatios.priceEarningsRatioTTM.toFixed(2) : 'N/A'} />
              </Col>
              <Col span={8}>
                <Statistic title="P/B Ratio" value={latestRatios.priceToBookRatioTTM ? latestRatios.priceToBookRatioTTM.toFixed(2) : 'N/A'} />
              </Col>
              <Col span={8}>
                <Statistic title="Debt to Equity" value={latestRatios.debtEquityRatioTTM ? latestRatios.debtEquityRatioTTM.toFixed(2) : 'N/A'} />
              </Col>
              <Col span={8}>
                <Statistic title="ROE" value={latestRatios.returnOnEquityTTM ? (latestRatios.returnOnEquityTTM * 100).toFixed(2) : 'N/A'} suffix="%" />
              </Col>
              <Col span={8}>
                <Statistic title="ROA" value={latestRatios.returnOnAssetsTTM ? (latestRatios.returnOnAssetsTTM * 100).toFixed(2) : 'N/A'} suffix="%" />
              </Col>
              <Col span={8}>
                <Statistic title="Profit Margin" value={latestRatios.netProfitMarginTTM ? (latestRatios.netProfitMarginTTM * 100).toFixed(2) : 'N/A'} suffix="%" />
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default CompanyDetails;
