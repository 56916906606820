import { UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, message, Row, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getUserProfile } from '../../services/api';
import ThemeToggle from '../ThemeToggle/ThemeToggle';

const { Title, Text } = Typography;

const UserProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getUserProfile();
        setProfile(response.data);
      } catch (error) {
        message.error('Failed to fetch user profile');
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchProfile();
    }
  }, [currentUser]);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <Card>
      <Row gutter={[16, 16]} align="middle">
        <Col xs={24} sm={6} md={4} lg={3}>
          <Avatar size={80} icon={<UserOutlined />} />
        </Col>
        <Col xs={24} sm={18} md={20} lg={21}>
          <Title level={3} style={{ marginBottom: '8px' }}>User Profile</Title>
          <Text strong>Email: </Text>
          <Text>{profile?.email}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
        <Col span={24}>
          <Text strong style={{ marginRight: '16px' }}>Theme Preference: </Text>
          <ThemeToggle />
        </Col>
      </Row>
    </Card>
  );
};

export default UserProfile;
