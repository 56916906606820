import { Radio } from 'antd';
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const ThemeToggle = () => {
  const { themePreference, changeTheme } = useTheme();

  const handleThemeChange = (e) => {
    const newTheme = e.target.value;
    changeTheme(newTheme);
  };

  return (
    <Radio.Group
      value={themePreference}
      onChange={handleThemeChange}
      buttonStyle="solid"
    >
      <Radio.Button value="LIGHT">
        <span role="img" aria-label="sun" style={{ fontSize: '12px' }}>☀️</span>
      </Radio.Button>
      <Radio.Button value="DARK">
        <span role="img" aria-label="moon" style={{ fontSize: '12px' }}>🌙</span>
      </Radio.Button>
      <Radio.Button value="SYSTEM">
        <span role="img" aria-label="laptop" style={{ fontSize: '12px' }}>💻</span>
      </Radio.Button>
    </Radio.Group>
  );
};

export default ThemeToggle;
