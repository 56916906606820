import React, { useEffect, useCallback, useMemo } from 'react';
import { Card, Typography, List, Row, Col, Skeleton, Button, Space } from 'antd';
import { LoadingOutlined, InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Title, Paragraph, Text } = Typography;

const StyledCard = styled(Card)`
  transition: opacity 0.3s ease-in-out;
`;

const StatusBar = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const StatusIcon = styled.span`
  font-size: 24px;
  margin-right: 12px;
`;

const StatusText = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const AISummaryTab = ({ aiSummary, loading, fetchAISummary }) => {
  const pollAISummary = useCallback(() => {
    const pollInterval = setInterval(() => {
      fetchAISummary();
    }, 2000); // Changed to 2000ms (2 seconds)

    return () => clearInterval(pollInterval);
  }, [fetchAISummary]);

  useEffect(() => {
    if (aiSummary && (aiSummary.status === 'NEW' || aiSummary.status === 'GENERATING')) {
      const clearPollInterval = pollAISummary();
      return () => clearPollInterval();
    }
  }, [aiSummary, pollAISummary]);

  const SkeletonLayout = useMemo(() => (
    <StyledCard>
      <Skeleton active avatar paragraph={{ rows: 4 }} />
      <Skeleton active title={false} paragraph={{ rows: 3 }} />
      <Row gutter={[16, 16]}>
        {[...Array(4)].map((_, index) => (
          <Col span={12} key={index}>
            <Card>
              <Skeleton active title={false} paragraph={{ rows: 3 }} />
            </Card>
          </Col>
        ))}
      </Row>
    </StyledCard>
  ), []);

  const renderStatusBar = () => {
    if (!aiSummary || aiSummary.status === 'COMPLETED') return null;

    let icon, text, bgColor, textColor;

    switch (aiSummary.status) {
      case 'NEW':
      case 'GENERATING':
        icon = <LoadingOutlined />;
        text = 'AI Summary Generation in Progress';
        bgColor = '#e6f7ff';
        textColor = '#1890ff';
        break;
      case 'FAILED':
        icon = <InfoCircleOutlined />;
        text = 'AI Summary Generation Failed';
        bgColor = '#fff2f0';
        textColor = '#ff4d4f';
        break;
      default:
        return null;
    }

    return (
      <StatusBar bgColor={bgColor} textColor={textColor}>
        <StatusIcon>{icon}</StatusIcon>
        <StatusText>{text}</StatusText>
      </StatusBar>
    );
  };

  if (loading && !aiSummary) {
    return SkeletonLayout;
  }

  if (!aiSummary) {
    return (
      <StyledCard>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Text>No AI summary available. Click to generate one.</Text>
          <Button onClick={fetchAISummary} type="primary" icon={<CheckCircleOutlined />}>
            Generate AI Summary
          </Button>
        </Space>
      </StyledCard>
    );
  }

  if (aiSummary.status === 'NEW' || aiSummary.status === 'GENERATING') {
    return (
      <StyledCard>
        {renderStatusBar()}
        {SkeletonLayout}
      </StyledCard>
    );
  }

  if (aiSummary.status === 'FAILED') {
    return (
      <StyledCard>
        {renderStatusBar()}
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Text>We encountered an error while generating the AI summary. Please try again.</Text>
          <Button onClick={fetchAISummary} type="primary" icon={<CheckCircleOutlined />}>
            Retry
          </Button>
        </Space>
      </StyledCard>
    );
  }

  const { summary, key_points, investment_considerations } = aiSummary;

  return (
    <StyledCard>
      <Title level={3} style={{ marginBottom: '24px' }}>AI Summary</Title>
      <Paragraph>{summary}</Paragraph>

      <Card title="Key Points" style={{ marginTop: '24px' }}>
        <List
          dataSource={key_points}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </Card>

      <Title level={4} style={{ marginTop: '24px', marginBottom: '16px' }}>Investment Considerations</Title>
      <Row gutter={[16, 16]}>
        {Object.entries(investment_considerations).map(([key, value]) => (
          <Col span={12} key={key}>
            <Card title={key.charAt(0).toUpperCase() + key.slice(1)} size="small">
              <List
                dataSource={value}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </StyledCard>
  );
};

export default React.memo(AISummaryTab);
