import React, { useEffect, useMemo, useRef, useState } from 'react';
import Globe from 'react-globe.gl';
import * as THREE from 'three';

const baseMarketCoordinates = {
  '^SPX': { lat: 40.7128, lng: -74.0060 },    // New York
  '^DJI': { lat: 40.7128, lng: -74.0060 },    // New York
  '^IXIC': { lat: 40.7128, lng: -74.0060 },   // New York
  '^RUT': { lat: 40.7128, lng: -74.0060 },    // New York
  '^NYA': { lat: 40.7128, lng: -74.0060 },    // New York
  '^FTSE': { lat: 51.5074, lng: -0.1278 },    // London
  '^N225': { lat: 35.6762, lng: 139.6503 },   // Tokyo
  '^GDAXI': { lat: 50.1109, lng: 8.6821 },    // Frankfurt
  '^FCHI': { lat: 48.8566, lng: 2.3522 },     // Paris
  '000001.SS': { lat: 31.2304, lng: 121.4737 }, // Shanghai
  '^HSI': { lat: 22.3193, lng: 114.1694 },    // Hong Kong
  '^GSPTSE': { lat: 43.6532, lng: -79.3832 }, // Toronto
  '^BSESN': { lat: 18.9256, lng: 72.8242 },   // Mumbai
  '^NSEI': { lat: 19.0760, lng: 72.8777 },    // Mumbai
  '^BVSP': { lat: -23.5505, lng: -46.6333 },  // São Paulo
  '^AXJO': { lat: -33.8688, lng: 151.2093 },  // Sydney
  '^KS11': { lat: 37.5665, lng: 126.9780 },   // Seoul
  '^SSMI': { lat: 47.3769, lng: 8.5417 },     // Zurich
  '^IBEX': { lat: 40.4168, lng: -3.7038 },    // Madrid
  'FTSEMIB.MI': { lat: 45.4642, lng: 9.1900 }, // Milan
  '^AEX': { lat: 52.3676, lng: 4.9041 },      // Amsterdam
  'IMOEX.ME': { lat: 55.7558, lng: 37.6173 }, // Moscow
  '^OMXS30': { lat: 59.3293, lng: 18.0686 },  // Stockholm
  '^STI': { lat: 1.3521, lng: 103.8198 },     // Singapore
  '^MXX': { lat: 19.4326, lng: -99.1332 },    // Mexico City
  '^TWII': { lat: 25.0330, lng: 121.5654 },   // Taipei
  'XU100.IS': { lat: 41.0082, lng: 28.9784 }, // Istanbul
  '^TASI.SR': { lat: 24.7136, lng: 46.6753 }  // Riyadh
};

const offsetCoordinates = (markets) => {
  const offsetted = [...markets];
  const locationMap = {};

  offsetted.forEach(market => {
    const key = `${market.lat},${market.lng}`;
    if (!locationMap[key]) {
      locationMap[key] = [];
    }
    locationMap[key].push(market);
  });

  Object.values(locationMap).forEach(group => {
    if (group.length > 1) {
      const radius = 3; // Increased radius for better spread
      group.forEach((market, index) => {
        const angle = (index / group.length) * 2 * Math.PI;
        market.lat += radius * Math.cos(angle);
        market.lng += radius * Math.sin(angle);
      });
    }
  });

  return offsetted;
};

const GlobeComponent = ({ marketData }) => {
  const [countriesData, setCountriesData] = useState([]);
  const globeEl = useRef();

  useEffect(() => {
    fetch('https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_110m_admin_0_countries.geojson')
      .then(res => res.json())
      .then(data => setCountriesData(data.features));
  }, []);

  useEffect(() => {
    if (globeEl.current) {
      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = 0.2;
      const now = new Date();
      globeEl.current.pointOfView({ lat: 20, lng: 0, altitude: 2 });
    }
  }, []);

  const getMarkerColor = (secondsToOpen, changesPercentage) => {
    return changesPercentage >= 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)';
  };

  const getAltitude = (changesPercentage) => {
    return Math.abs(changesPercentage) * 0.1 + 0.01; 
  };

  const marketsWithCoordinates = marketData.map(market => ({
    ...market,
    ...baseMarketCoordinates[market.symbol]
  }));

  const offsettedMarkets = offsetCoordinates(marketsWithCoordinates);

  const fragmentShader = `
    uniform sampler2D globeTexture;
    uniform float sunLongitude;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vPosition;

    void main() {
      vec3 diffuse = texture2D(globeTexture, vUv).xyz;
      vec3 nightColor = vec3(0.1, 0.1, 0.3) * diffuse;
      vec3 dayColor = diffuse;
      
      vec3 normalizedPosition = normalize(vPosition);
      float longitude = atan(normalizedPosition.x, normalizedPosition.z);
      float latitude = asin(normalizedPosition.y);
      
      float distanceFromSun = mod(abs(longitude - sunLongitude), 2.0 * 3.14159);
      if (distanceFromSun > 3.14159) {
        distanceFromSun = 2.0 * 3.14159 - distanceFromSun;
      }
      
      float dayNightMix = smoothstep(1.4, 1.7, distanceFromSun);
      
      vec3 color = mix(dayColor, nightColor, dayNightMix);
      
      // Add atmosphere effect
      float atmosphere = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), 2.0) * 0.5;
      color += vec3(0.3, 0.6, 1.0) * atmosphere;
      
      gl_FragColor = vec4(color, 1.0);
    }
  `;

  const vertexShader = `
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vPosition;

    void main() {
      vUv = uv;
      vNormal = normalize(normalMatrix * normal);
      vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;

  const shaderMaterial = useMemo(() => {
    const material = new THREE.ShaderMaterial({
      uniforms: {
        globeTexture: { value: new THREE.TextureLoader().load('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg') },
        sunLongitude: { value: 0 }
      },
      vertexShader,
      fragmentShader
    });

    const updateSunLongitude = () => {
      const now = new Date();
      const hours = now.getUTCHours();
      const minutes = now.getUTCMinutes();
      const sunLongitude = -((hours + minutes / 60) / 24 * 2 * Math.PI - Math.PI);
      material.uniforms.sunLongitude.value = sunLongitude;
    };

    updateSunLongitude();
    setInterval(updateSunLongitude, 60000); // Update every minute

    return material;
  }, []);

  return (
    <Globe
      ref={globeEl}
      globeMaterial={shaderMaterial}
      backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
      lineHoverPrecision={0}
      polygonsData={countriesData}
      polygonAltitude={0.01}
      polygonCapColor={() => 'rgba(200, 200, 200, 0.3)'}
      polygonSideColor={() => 'rgba(150, 150, 150, 0.3)'}
      polygonStrokeColor={() => '#111'}
      pointsData={offsettedMarkets}
      pointLat="lat"
      pointLng="lng"
      pointColor={d => getMarkerColor(d.secondsToOpen, d.changesPercentage)}
      pointAltitude={d => getAltitude(d.changesPercentage)}
      pointRadius={0.5}
      pointLabel={d => `
        <div style="color: white; background-color: rgba(0, 0, 0, 0.75); padding: 10px; border-radius: 5px;">
          <h3>${d.symbol}: ${d.changesPercentage.toFixed(2)}%</h3>
          <p>Price: ${d.price}</p>
          <p>Change: ${d.change}</p>
          <p>Status: ${d.secondsToOpen === 0 ? 'Open' : 'Closed'}</p>
        </div>
      `}
    />
  );
};

export default GlobeComponent;
