import React, { useState } from 'react';
import { Card, Col, Collapse, List, Row, Typography } from 'antd';
import StockPrice from '../../StockPrice/StockPrice';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const DetailedSupplyChainView = ({ supplyChainData }) => {
  const [activeKeys, setActiveKeys] = useState([]);

  const renderListItem = (item) => (
    <List.Item>
      <Card style={{ width: '100%' }}>
        <Title level={4}>{item.name} ({item.symbol})</Title>
        <StockPrice symbol={item.symbol} size='small' />
        <StockPrice symbol={item.symbol} changePercent size='small' />

        {item.relation && <p><strong>Relation:</strong> {item.relation}</p>}
        {item.exposure && <p><strong>Exposure:</strong> {item.exposure}</p>}
        {item.threat && <p><strong>Threat:</strong> {item.threat}</p>}
        {item.friend && <p><strong>Friend:</strong> {item.friend}</p>}
      </Card>
    </List.Item>
  );

  const getHeader = (category, data, isActive) => {
    if (!data || data.length === 0) {
      return category.charAt(0).toUpperCase() + category.slice(1);
    }
    if (isActive) {
      return category.charAt(0).toUpperCase() + category.slice(1);
    }
    const companyNames = data.map(item => item.name).join(', ');
    return `${category.charAt(0).toUpperCase() + category.slice(1)} (${companyNames})`;
  };

  const onCollapseChange = (keys) => {
    setActiveKeys(keys);
  };

  return (
    <Collapse activeKey={activeKeys} onChange={onCollapseChange}>
      {['suppliers', 'customers', 'peers', 'commodities'].map((category) => (
        <Panel 
          header={getHeader(category, supplyChainData[category], activeKeys.includes(category))} 
          key={category}
        >
          {supplyChainData[category] && supplyChainData[category].length > 0 ? (
            <Row gutter={[16, 16]}>
              {supplyChainData[category].map((item, index) => (
                <Col span={8} key={index}>
                  {renderListItem(item)}
                </Col>
              ))}
            </Row>
          ) : (
            <Text>No data available for {category} at the moment. Please check back later.</Text>
          )}
        </Panel>
      ))}
    </Collapse>
  );
};

export default DetailedSupplyChainView;
