import { Avatar, Card, Col, Row, Spin, Statistic, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAnalystProfile } from '../../services/api';

const { Title, Text } = Typography;

const AnalystProfile = ({ analystName }) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setProfileData(null);
    const fetchAnalystProfile = async () => {
      try {
        const response = await getAnalystProfile(analystName);
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching analyst profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalystProfile();
  }, [analystName]);

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const columns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: 'Price Target',
      dataIndex: 'priceTarget',
      key: 'priceTarget',
      render: (price) => `$${price.toFixed(2)}`,
    },
    {
      title: 'Price When Posted',
      dataIndex: 'priceWhenPosted',
      key: 'priceWhenPosted',
      render: (price) => `$${price.toFixed(2)}`,
    },
    {
      title: 'Current Price',
      dataIndex: 'priceNow',
      key: 'priceNow',
      render: (price) => (price ? `$${price.toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Price After One Year',
      dataIndex: 'priceAfterOneYear',
      key: 'priceAfterOneYear',
      render: (price) => (price ? `$${price.toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Performance',
      dataIndex: 'performance',
      key: 'performance',
      render: (performance) => (performance ? `${performance.toFixed(2)}%` : 'N/A'),
    },
    {
      title: 'Date',
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      render: (date) => new Date(date).toLocaleDateString(),
    },
  ];

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!profileData) {
    return <Text>No profile data available for {analystName}</Text>;
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Avatar size={64} style={{ marginRight: '16px' }}>
          {getInitials(analystName)}
        </Avatar>
        <Title level={3}>{analystName}</Title>
      </div>
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <Statistic
                title="Overall Performance"
                value={profileData.overallPerformance}
                precision={2}
                suffix="%"
                valueStyle={{ color: profileData.overallPerformance >= 0 ? '#3f8600' : '#cf1322' }}
              />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
              <Statistic
                title={`Best Performance: ${profileData.bestPerformance.symbol}`}
                value={profileData.bestPerformance.performance}
                precision={2}
                suffix="%"
                valueStyle={{ color: profileData.bestPerformance.performance >= 0 ? '#3f8600' : '#cf1322' }}
              />
            </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title={`Worst Performance: ${profileData.worstPerformance.symbol}`}
              value={profileData.worstPerformance.performance}
              precision={2}
              suffix="%"
              valueStyle={{ color: profileData.worstPerformance.performance >= 0 ? '#3f8600' : '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>
      <Title level={4} style={{ marginTop: '20px' }}>Recent Price Targets</Title>
      <Table
        dataSource={profileData.priceTargets}
        columns={columns}
        rowKey={(record) => `${record.symbol}-${record.publishedDate}`}
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default AnalystProfile;
