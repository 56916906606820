const createMetric = (value, label, children = []) => ({
  value,
  label,
  children
});

export const getMetrics = (tabKey) => {
  switch (tabKey) {
    case 'income_statement':
      return [
        createMetric('revenue', 'Revenue', [
          createMetric('costOfRevenue', 'Cost of Revenue'),
          createMetric('grossProfit', 'Gross Profit', [
            createMetric('grossProfitRatio', 'Gross Profit Ratio')
          ])
        ]),
        createMetric('operatingExpenses', 'Operating Expenses', [
          createMetric('researchAndDevelopmentExpenses', 'Research & Development Expenses'),
          createMetric('generalAndAdministrativeExpenses', 'General & Administrative Expenses'),
          createMetric('sellingAndMarketingExpenses', 'Selling and Marketing Expenses'),
          createMetric('sellingGeneralAndAdministrativeExpenses', 'SG&A Expenses'),
          createMetric('otherExpenses', 'Other Expenses')
        ]),
        createMetric('operatingIncome', 'Operating Income', [
          createMetric('operatingIncomeRatio', 'Operating Income Ratio')
        ]),
        createMetric('totalOtherIncomeExpensesNet', 'Total Other Income/Expenses Net', [
          createMetric('interestExpense', 'Interest Expense'),
          createMetric('interestIncome', 'Interest Income'),
          createMetric('depreciationAndAmortization', 'Depreciation and Amortization')
        ]),
        createMetric('incomeBeforeTax', 'Income Before Tax', [
          createMetric('incomeBeforeTaxRatio', 'Income Before Tax Ratio'),
          createMetric('incomeTaxExpense', 'Income Tax Expense')
        ]),
        createMetric('netIncome', 'Net Income', [
          createMetric('netIncomeRatio', 'Net Income Ratio')
        ]),
        createMetric('ebitda', 'EBITDA', [
          createMetric('ebitdaratio', 'EBITDA Ratio')
        ]),
        createMetric('eps', 'EPS', [
          createMetric('epsdiluted', 'EPS Diluted')
        ]),
        createMetric('weightedAverageShsOut', 'Weighted Average Shares Outstanding', [
          createMetric('weightedAverageShsOutDil', 'Weighted Average Shares Outstanding (Diluted)')
        ])
      ];
    case 'balance_sheet':
      return [
        createMetric('assets', 'Assets', [
          createMetric('currentAssets', 'Current Assets', [
            createMetric('cashAndCashEquivalents', 'Cash and Cash Equivalents'),
            createMetric('shortTermInvestments', 'Short Term Investments'),
            createMetric('netReceivables', 'Net Receivables'),
            createMetric('inventory', 'Inventory'),
            createMetric('otherCurrentAssets', 'Other Current Assets')
          ]),
          createMetric('nonCurrentAssets', 'Non-Current Assets', [
            createMetric('propertyPlantEquipmentNet', 'Property, Plant & Equipment (Net)'),
            createMetric('goodwill', 'Goodwill'),
            createMetric('intangibleAssets', 'Intangible Assets'),
            createMetric('longTermInvestments', 'Long Term Investments'),
            createMetric('otherNonCurrentAssets', 'Other Non-Current Assets')
          ])
        ]),
        createMetric('liabilities', 'Liabilities', [
          createMetric('currentLiabilities', 'Current Liabilities', [
            createMetric('accountPayables', 'Account Payables'),
            createMetric('shortTermDebt', 'Short Term Debt'),
            createMetric('taxPayables', 'Tax Payables'),
            createMetric('deferredRevenue', 'Deferred Revenue'),
            createMetric('otherCurrentLiabilities', 'Other Current Liabilities')
          ]),
          createMetric('nonCurrentLiabilities', 'Non-Current Liabilities', [
            createMetric('longTermDebt', 'Long Term Debt'),
            createMetric('deferredRevenueNonCurrent', 'Deferred Revenue Non-Current'),
            createMetric('deferredTaxLiabilitiesNonCurrent', 'Deferred Tax Liabilities Non-Current'),
            createMetric('otherNonCurrentLiabilities', 'Other Non-Current Liabilities')
          ])
        ]),
        createMetric('totalStockholdersEquity', 'Total Stockholders Equity', [
          createMetric('commonStock', 'Common Stock'),
          createMetric('retainedEarnings', 'Retained Earnings'),
          createMetric('accumulatedOtherComprehensiveIncomeLoss', 'Accumulated Other Comprehensive Income/Loss'),
          createMetric('othertotalStockholdersEquity', 'Other Total Stockholders Equity')
        ]),
        createMetric('totalLiabilitiesAndStockholdersEquity', 'Total Liabilities and Stockholders Equity'),
        createMetric('totalInvestments', 'Total Investments'),
        createMetric('totalDebt', 'Total Debt'),
        createMetric('netDebt', 'Net Debt')
      ];
    case 'cash_flow':
      return [
        createMetric('operatingActivities', 'Operating Activities', [
          createMetric('netIncome', 'Net Income'),
          createMetric('depreciationAndAmortization', 'Depreciation and Amortization'),
          createMetric('deferredIncomeTax', 'Deferred Income Tax'),
          createMetric('stockBasedCompensation', 'Stock Based Compensation'),
          createMetric('changeInWorkingCapital', 'Change in Working Capital', [
            createMetric('accountsReceivables', 'Accounts Receivables'),
            createMetric('inventory', 'Inventory'),
            createMetric('accountsPayables', 'Accounts Payables'),
            createMetric('otherWorkingCapital', 'Other Working Capital')
          ]),
          createMetric('otherNonCashItems', 'Other Non-Cash Items')
        ]),
        createMetric('investingActivities', 'Investing Activities', [
          createMetric('investmentsInPropertyPlantAndEquipment', 'Investments in Property, Plant and Equipment'),
          createMetric('acquisitionsNet', 'Acquisitions Net'),
          createMetric('purchasesOfInvestments', 'Purchases of Investments'),
          createMetric('salesMaturitiesOfInvestments', 'Sales/Maturities of Investments'),
          createMetric('otherInvestingActivites', 'Other Investing Activities')
        ]),
        createMetric('financingActivities', 'Financing Activities', [
          createMetric('debtRepayment', 'Debt Repayment'),
          createMetric('commonStockIssued', 'Common Stock Issued'),
          createMetric('commonStockRepurchased', 'Common Stock Repurchased'),
          createMetric('dividendsPaid', 'Dividends Paid'),
          createMetric('otherFinancingActivites', 'Other Financing Activities')
        ]),
        createMetric('netChangeInCash', 'Net Change in Cash'),
        createMetric('cashAtEndOfPeriod', 'Cash at End of Period'),
        createMetric('cashAtBeginningOfPeriod', 'Cash at Beginning of Period'),
        createMetric('operatingCashFlow', 'Operating Cash Flow'),
        createMetric('capitalExpenditure', 'Capital Expenditure'),
        createMetric('freeCashFlow', 'Free Cash Flow')
      ];
    default:
      return [];
  }
};
