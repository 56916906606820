import { Image } from 'antd';
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const AdaptiveLogo = ({ src, alt, height }) => {
  const { isDarkMode } = useTheme();

  const logoStyle = {
    padding: '4px 40px 4px 4px',
    borderRadius: '4px',
    display: 'inline-block',
    ...(isDarkMode
      ? {
        padding: '4px 40px 4px 0px',
      }
      : {
          background: 'linear-gradient(to right, rgba(74, 120, 120, 0.25), rgba(74, 120, 120, 0.02))',
        }),
  };

  return (
    <div style={logoStyle}>
      <Image
        src={src}
        alt={alt}
        height={height}
        preview={false}
        style={{ objectFit: 'contain' }}
      />
    </div>
  );
};

export default AdaptiveLogo;
