import React, { useState } from 'react';
import { Table, Button } from 'antd';
import { CaretDownOutlined, CaretRightOutlined, StarFilled, BarChartOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import * as CountryFlags from 'country-flag-icons/react/3x2';
import { getCurrencySymbol } from '../../../utils/currencyMapping';

const getFlag = (code) => {
  const Flag = CountryFlags[code];
  return Flag ? <Flag style={{ width: '1em', marginRight: '0.5em' }} /> : null;
};

const formatCurrency = (value, exchangeShortName) => {
  if (value === null || value === undefined) return "N/A";
  const symbol = getCurrencySymbol(exchangeShortName) || '$';
  return `${symbol}${formatLargeNumber(value)}`;
};

const formatLargeNumber = (num) => {
  if (num === null || num === undefined) return "N/A";
  const absNum = Math.abs(num);
  if (absNum >= 1e12) return (num / 1e12).toFixed(2) + 'T';
  if (absNum >= 1e9) return (num / 1e9).toFixed(2) + 'B';
  if (absNum >= 1e6) return (num / 1e6).toFixed(2) + 'M';
  if (absNum >= 1e3) return (num / 1e3).toFixed(2) + 'K';
  return num.toFixed(2);
};

const getReadableTime = (time) => {
  switch(time?.toLowerCase()) {
    case 'bmo':
      return 'Before Market Open';
    case 'amc':
      return 'After Market Close';
    case 'dm':
      return 'During Market';
    default:
      return 'During Market';
  }
};

const sortEvents = (events) => {
  const timeOrder = { 'bmo': 0, 'dm': 1, 'amc': 2 };
  return events.sort((a, b) => {
    const timeA = a.time?.toLowerCase() || 'dm';
    const timeB = b.time?.toLowerCase() || 'dm';
    if (timeOrder[timeA] !== timeOrder[timeB]) {
      return timeOrder[timeA] - timeOrder[timeB];
    }
    return (b.marketCap || 0) - (a.marketCap || 0);
  });
};

const EarningsEventTable = ({ events, loading, onChartClick }) => {
  const [expandedDates, setExpandedDates] = useState({});

  const groupEventsByDate = (events) => {
    const grouped = {};
    events.forEach(event => {
      const date = dayjs(event.date).format("YYYY-MM-DD");
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(event);
    });
    
    // Sort events within each date group
    Object.keys(grouped).forEach(date => {
      grouped[date] = sortEvents(grouped[date]);
    });
    
    return grouped;
  };

  const getTableData = () => {
    const groupedEvents = groupEventsByDate(events);
    const tableData = [];

    Object.entries(groupedEvents).forEach(([date, dateEvents]) => {
      tableData.push({
        key: `date-${date}`,
        date,
        isDateGroup: true,
        events: dateEvents,
      });

      if (expandedDates[date]) {
        dateEvents.forEach((event, index) => {
          tableData.push({
            ...event,
            key: `${date}-${index}`,
          });
        });
      }
    });

    return tableData;
  };

  const onToggleDateExpansion = (date) => {
    setExpandedDates(prev => ({ ...prev, [date]: !prev[date] }));
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: 'left',
      width: 200,
      render: (text, record) => {
        if (record.isDateGroup) {
          return (
            <span
              className="date-group-cell"
              onClick={() => onToggleDateExpansion(record.date)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              {expandedDates[record.date] ? <CaretDownOutlined /> : <CaretRightOutlined />}
              <span style={{ marginLeft: '8px' }}>
                {dayjs(record.date).format("YYYY-MM-DD")}
              </span>
            </span>
          );
        }
        return <span style={{ paddingLeft: '28px' }}>{getReadableTime(record.time)}</span>;
      },
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      render: (text, record) => {
        if (record.isDateGroup) return `${record.events.length} earnings`;
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {getFlag(record.country)}
            {text}
            {record.is_influential && <StarFilled style={{ color: '#faad14', marginLeft: '5px' }} />}
          </span>
        );
      },
    },
    {
      title: "Ticker",
      dataIndex: "symbol",
      key: "symbol",
      render: (text, record) => {
        if (record.isDateGroup) return '';
        return <span>{text}</span>;
      },
    },
    {
      title: "EPS Estimate",
      dataIndex: "epsEstimated",
      key: "epsEstimated",
      render: (value, record) => {
        if (record.isDateGroup) return '';
        return value !== null && value !== undefined ? value.toFixed(2) : "N/A";
      },
    },
    {
      title: "EPS Actual",
      dataIndex: "eps",
      key: "eps",
      render: (value, record) => {
        if (record.isDateGroup) return '';
        return value !== null && value !== undefined ? value.toFixed(2) : "N/A";
      },
    },
    {
      title: "Revenue Estimate",
      dataIndex: "revenueEstimated",
      key: "revenueEstimated",
      render: (value, record) => {
        if (record.isDateGroup) return '';
        return formatCurrency(value, record.exchangeShortName);
      },
    },
    {
      title: "Revenue Actual",
      dataIndex: "revenue",
      key: "revenue",
      render: (value, record) => {
        if (record.isDateGroup) return '';
        return formatCurrency(value, record.exchangeShortName);
      },
    },
    {
      title: "Chart",
      key: "chart",
      render: (_, record) => (
        !record.isDateGroup && (
          <Button
            icon={<BarChartOutlined />}
            onClick={() => onChartClick(record)}
          />
        )
      ),
    },
  ];

  return (
    <Table 
      columns={columns} 
      dataSource={getTableData()} 
      loading={loading} 
      pagination={false}
      scroll={{ x: 'max-content', y: 'calc(100vh - 350px)' }}
      size="small"
      rowClassName={(record) => record.isDateGroup ? 'row-level-0' : 'row-level-1'}
    />
  );
};

export default EarningsEventTable;
