import { Table } from 'antd';
import { formatDistanceToNow } from 'date-fns';
import React, { useMemo } from 'react';
import { getColorForPercentChange, getStatusColor } from '../../utils/colorUtils';

const getStatusString = (secondsToOpen, secondsToClose) => {
  if (secondsToClose > 0) {
    const closeTime = formatDistanceToNow(new Date(Date.now() + secondsToClose * 1000), { addSuffix: true });
    return `Closes ${closeTime}`;
  } else {
    const openTime = formatDistanceToNow(new Date(Date.now() + secondsToOpen * 1000), { addSuffix: true });
    return `Opens ${openTime}`;
  }
};

const WorldView = ({ marketData }) => {
  const sortedData = useMemo(() => {
    return [...marketData].sort((a, b) => {
      if (a.secondsToClose > 0 && b.secondsToClose > 0) {
        return a.secondsToClose - b.secondsToClose;
      } else if (a.secondsToClose > 0) {
        return -1;
      } else if (b.secondsToClose > 0) {
        return 1;
      } else {
        return a.secondsToOpen - b.secondsToOpen;
      }
    });
  }, [marketData]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price, record) => (
        <span style={{ color: getColorForPercentChange(record.changesPercentage) }}>
          {price.toFixed(2)}
        </span>
      ),
    },
    {
      title: 'Change',
      dataIndex: 'change',
      key: 'change',
      render: (change, record) => (
        <span style={{ color: getColorForPercentChange(record.changesPercentage) }}>
          {change.toFixed(2)}
        </span>
      ),
    },
    {
      title: 'Change %',
      dataIndex: 'changesPercentage',
      key: 'changesPercentage',
      render: (changesPercentage) => (
        <span style={{ color: getColorForPercentChange(changesPercentage) }}>
          {changesPercentage.toFixed(2)}%
        </span>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        const statusString = getStatusString(record.secondsToOpen, record.secondsToClose);
        const statusColor = getStatusColor(record.secondsToOpen, record.secondsToClose);
        return <span style={{ color: statusColor }}>{statusString}</span>;
      },
    },
  ];

  return (
    <Table
      className='world-view-table'
      dataSource={sortedData}
      columns={columns}
      rowKey="symbol"
      pagination={false}
      scroll={{ y: 'calc(100vh - 250px)', x: true }}
    />
  );
};

export default WorldView;
