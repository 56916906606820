import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getMarkets } from '../../services/api';
import GlobeComponent from '../Markets/GlobeComponent';
import Login from './Login';
import SignUp from './SignUp';

const AuthPage = () => {
  const [marketData, setMarketData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const response = await getMarkets();
        setMarketData(response.data);
      } catch (error) {
        console.error('Error fetching market data:', error);
      }
    };

    fetchMarketData();
  }, []);

  return (
    <div style={{
      position: 'relative',
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}>
        <GlobeComponent marketData={marketData} alignment='center'/>
      </div>
      <div style={{
        position: 'absolute',
        top: '50%',
        right: "0%",
        width: '100%',
        height: '20%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {location.pathname === '/login' ? <Login /> : <SignUp />}
      </div>
    </div>
  );
};

export default AuthPage;