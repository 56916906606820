import { Alert, Col, Row, Spin, Typography } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ChartView from './ChartView';
import { estimateTypes } from './constants';
import ControlPanel from './ControlPanel';
import EstimateTypeTabs from './EstimateTypeTabs';
import TableView from './TableView';
import { filterData, formatValue } from './utilities';

const { Title: AntTitle } = Typography;

const AnalystEstimates = ({ data, ticker, estimateType, setEstimateType, timeRange, setTimeRange, currencySymbol, frequencyMode, setFrequencyMode }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('chart');
  const chartRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    console.log('AnalystEstimates data:', data);
    if (data) {
      setLoading(false);
    } else {
      setError('No data available');
    }
  }, [data]);

  useEffect(() => {
    const resizeChart = () => {
      if (chartRef.current && containerRef.current) {
        chartRef.current.resize();
      }
    };

    window.addEventListener('resize', resizeChart);
    return () => window.removeEventListener('resize', resizeChart);
  }, []);

  const selectedEstimateTypeInfo = estimateTypes.find(et => et.key === estimateType);

  const filteredData = useMemo(() => {
    console.log('Filtering data:', data, timeRange);
    return filterData(data, timeRange);
  }, [data, timeRange]);

  const chartData = useMemo(() => {
    console.log('Creating chart data:', filteredData, estimateType);
    if (!filteredData || filteredData.length === 0) return null;
    return {
      labels: filteredData.map(item => `${item.calendarYear} ${item.period}`),
      datasets: [
        {
          label: 'Actual',
          data: filteredData.map(item => ({
            x: `${item.calendarYear} ${item.period}`,
            y: item[estimateType]?.actual
          })),
          backgroundColor: (context) => {
            const value = context.raw.y;
            if (value === null || value === undefined) return 'rgba(200, 200, 200, 0.8)';
            return value < 0 ? 'rgba(255, 99, 132, 0.6)' : 'rgba(75, 192, 192, 0.6)';
          },
          borderColor: 'transparent',
          borderWidth: 0,
          type: 'bar'
        },
        {
          label: 'Estimate',
          data: filteredData.map(item => ({
            x: `${item.calendarYear} ${item.period}`,
            y: item[estimateType]?.estimate
          })),
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          type: 'line',
          pointStyle: 'circle',
          pointRadius: 4,
          pointHoverRadius: 6,
          fill: false
        }
      ]
    };
  }, [filteredData, estimateType]);

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => `${context[0].label}`,
          label: (context) => {
            const dataPoint = filteredData.find(item => `${item.calendarYear} ${item.period}` === context.label);
            const estimateData = dataPoint?.[estimateType];
            let label = context.dataset.label + ': ' + formatValue(context.parsed.y, selectedEstimateTypeInfo.useCurrency, currencySymbol);
            return label;
          },
          afterBody: (context) => {
            const dataPoint = filteredData.find(item => `${item.calendarYear} ${item.period}` === context[0].label);
            const estimateData = dataPoint?.[estimateType];
            if (estimateData?.estimate !== null && estimateData?.estimate !== undefined) {
              return [
                `High: ${formatValue(estimateData.estimateHigh, selectedEstimateTypeInfo.useCurrency, currencySymbol)}`,
                `Low: ${formatValue(estimateData.estimateLow, selectedEstimateTypeInfo.useCurrency, currencySymbol)}`,
                `Analysts: ${estimateData.numberAnalysts || 'N/A'}`
              ];
            }
            return [];
          }
        }
      }
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: frequencyMode === 'quarterly' ? 'Quarter' : 'Year'
        },
        offset: true,
        grid: {
          offset: true
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value, index, values) {
            return formatValue(value, selectedEstimateTypeInfo.useCurrency, currencySymbol);
          }
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  if (loading) return <Spin size="large" />;
  if (error) return <Alert message="Error" description={error} type="error" />;

  return (
    <div className="analyst-estimates" ref={containerRef} style={{ height: 'calc(100vh - 200px)' }}>
      <Row align="middle" style={{ marginBottom: 16, marginTop: 16 }}>
        <Col span={24}>
          <ControlPanel
            viewMode={viewMode}
            setViewMode={setViewMode}
            frequencyMode={frequencyMode}
            setFrequencyMode={setFrequencyMode}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
          />
        </Col>
      </Row>
      {viewMode === 'chart' && (
        <EstimateTypeTabs
          estimateType={estimateType}
          setEstimateType={setEstimateType}
          estimateTypes={estimateTypes}
        />
      )}
      <div style={{ height: 'calc(100% - 200px)' }}>
        {viewMode === 'chart' ? (
          chartData ? (
            <ChartView chartData={chartData} options={options} chartRef={chartRef} />
          ) : (
            <div>No data available for the selected time range.</div>
          )
        ) : (
          <TableView data={filteredData} currencySymbol={currencySymbol} />
        )}
      </div>
    </div>
  );
};

export default AnalystEstimates;
