import { Tabs } from "antd";
import React from "react";
import EconomicCalendar from "./EconomicCalendar";
import EarningsCalendar from "./EarningsCalendar/EarningsCalendar";

const { TabPane } = Tabs;

const Calendar = () => {
  return (
    <div className="calendar-container">
      <Tabs defaultActiveKey="economic">
        <TabPane tab="Economic" key="economic">
          <EconomicCalendar />
        </TabPane>
        <TabPane tab="Earnings" key="earnings">
          <EarningsCalendar />
        </TabPane>
        <TabPane tab="IPO" key="ipo">
          Coming Soon!
        </TabPane>
        <TabPane tab="Dividends" key="dividends">
          Coming Soon!
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Calendar;
