import React from 'react';

const ScreenPerformanceChart = ({ screenId }) => {
  return (
    <div>
      <h3>Performance Chart</h3>
      <p>This is a placeholder for the screen performance chart. Screen ID: {screenId}</p>
    </div>
  );
};

export default ScreenPerformanceChart;
