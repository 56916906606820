import React from 'react';
import { Bar } from 'react-chartjs-2';


const formatLargeNumber = (num) => {
  if (num === null || num === undefined) return 'N/A';
  const absNum = Math.abs(num);
  const sign = num < 0 ? '-' : '';
  if (absNum >= 1e9) return sign + (absNum / 1e9).toFixed(1) + 'B';
  if (absNum >= 1e6) return sign + (absNum / 1e6).toFixed(1) + 'M';
  if (absNum >= 1e3) return sign + (absNum / 1e3).toFixed(1) + 'K';
  return sign + absNum.toFixed(2);
};

const DataChart = ({ data, selectedDataTypes, availableMetrics, currencySymbol }) => {
  if (!data || data.length === 0 || selectedDataTypes.length === 0) {
    return <div>No data available</div>;
  }

  const getMetricLabel = (metricValue) => {
    const findMetric = (metrics) => {
      for (const metric of metrics) {
        if (metric.value === metricValue) return metric.label;
        if (metric.children) {
          const childLabel = findMetric(metric.children);
          if (childLabel) return childLabel;
        }
      }
      return null;
    };
    return findMetric(availableMetrics) || metricValue;
  };

  const colors = [
    'rgba(75, 192, 192, 0.6)',
    'rgba(255, 99, 132, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(153, 102, 255, 0.6)'
  ];

  const borderColors = [
    'rgba(75, 192, 192, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(153, 102, 255, 1)'
  ];

  const chartData = {
    labels: data.map(item => item.date),
    datasets: selectedDataTypes.map((dataType, index) => ({
      label: getMetricLabel(dataType),
      data: data.map(item => item[dataType]),
      backgroundColor: colors[index % colors.length],
      borderColor: borderColors[index % borderColors.length],
      borderWidth: 1,
    })),
  };

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += currencySymbol + formatLargeNumber(context.parsed.y);
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Value',
        },
        ticks: {
          callback: (value) => currencySymbol + formatLargeNumber(value)
        }
      },
    },
  };

  return (
    <div style={{ height: 'calc(100vh - 300px)' }}>
      <Bar options={options} data={chartData} />
    </div>
  );
};

export default DataChart;
