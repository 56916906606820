import React from 'react';
import { Chart } from 'react-chartjs-2';

const ChartView = ({ chartData, options, chartRef }) => {
  return (
    <Chart ref={chartRef} type='bar' options={options} data={chartData} />
  );
};

export default ChartView;
