import React from 'react';
import { Table, Tag, Button } from 'antd';
import { BarChartOutlined, CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import * as CountryFlags from 'country-flag-icons/react/3x2';

const getFlag = (code) => {
  const Flag = CountryFlags[code];
  return Flag ? <Flag style={{ width: '1em', marginRight: '0.5em' }} /> : null;
};

const EconomicEventTable = ({ events, loading, expandedDates, onToggleDateExpansion, onChartClick }) => {
  const groupEventsByDate = (events) => {
    const grouped = {};
    events.forEach(event => {
      const date = dayjs(event.date).format("YYYY-MM-DD");
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(event);
    });
    return grouped;
  };

  const getTableData = () => {
    const groupedEvents = groupEventsByDate(events);
    const tableData = [];

    Object.entries(groupedEvents).forEach(([date, dateEvents]) => {
      tableData.push({
        key: `date-${date}`,
        date,
        isDateGroup: true,
        events: dateEvents,
      });

      if (expandedDates[date]) {
        dateEvents.forEach((event, index) => {
          tableData.push({
            ...event,
            key: `${date}-${index}`,
          });
        });
      }
    });

    return tableData;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: 'left',
      width: 200,
      render: (text, record) => {
        if (record.isDateGroup) {
          return (
            <span
              className="date-group-cell"
              onClick={() => onToggleDateExpansion(record.date)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              {expandedDates[record.date] ? <CaretDownOutlined /> : <CaretRightOutlined />}
              <span style={{ marginLeft: '8px' }}>
                {dayjs(record.date).format("YYYY-MM-DD")}
              </span>
            </span>
          );
        }
        return <span style={{ paddingLeft: '28px' }}>{dayjs(record.date).format("HH:mm")}</span>;
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (text, record) => {
        if (record.isDateGroup) return '';
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {getFlag(text)}
            {text}
          </span>
        );
      },
    },
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      render: (text, record) => {
        if (record.isDateGroup) {
          return `${record.events.length} events`;
        }
        return <span style={{ paddingLeft: '28px' }}>{text}</span>;
      },
    },
    {
      title: "Impact",
      dataIndex: "impact",
      key: "impact",
      render: (impact, record) => {
        if (record.isDateGroup) return '';
        return (
          <Tag color={impact === "High" ? "red" : impact === "Medium" ? "orange" : "green"}>
            {impact || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Actual",
      dataIndex: "actual",
      key: "actual",
      render: (actual, record) => {
        if (record.isDateGroup) return '';
        return actual !== null && actual !== undefined ? `${actual} ${record.unit || ''}` : "N/A";
      },
    },
    {
      title: "Previous",
      dataIndex: "previous",
      key: "previous",
      render: (previous, record) => {
        if (record.isDateGroup) return '';
        return previous !== null && previous !== undefined ? `${previous} ${record.unit || ''}` : "N/A";
      },
    },
    {
      title: "Forecast",
      dataIndex: "estimate",
      key: "estimate",
      render: (estimate, record) => {
        if (record.isDateGroup) return '';
        return estimate !== null && estimate !== undefined ? `${estimate} ${record.unit || ''}` : "N/A";
      },
    },
    {
      title: "Chart",
      key: "chart",
      render: (_, record) => (
        !record.isDateGroup && (
          <Button
            icon={<BarChartOutlined />}
            onClick={() => onChartClick(record)}
          />
        )
      ),
    },
  ];

  return (
    <Table 
      columns={columns} 
      dataSource={getTableData()} 
      loading={loading} 
      pagination={false}
      scroll={{ x: 'max-content', y: 'calc(100vh - 350px)' }}
      size="small"
      rowClassName={(record) => record.isDateGroup ? 'row-level-0' : 'row-level-1'}
    />
  );
};

export default EconomicEventTable;
