
import {
  BarChartOutlined,
  DashboardOutlined,
  FileTextOutlined,
  FilterOutlined,
  GlobalOutlined,
  LineChartOutlined,
  LinkOutlined,
  StarOutlined,
  TeamOutlined,
  UserOutlined,
  AudioOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const { Sider } = Layout;

const LeftNavBar = ({ isETF }) => {
  const location = useLocation();

  const menuItems = [
    { key: "/", icon: <DashboardOutlined />, label: "Overview" },
    { key: "/financials", icon: <BarChartOutlined />, label: "Financials", disabled: isETF },
    { key: "/news", icon: <FileTextOutlined />, label: "News" },
    { key: "/charts", icon: <LineChartOutlined />, label: "Charts" },
    { key: "/analysts", icon: <UserOutlined />, label: "Analysts", disabled: isETF },
    { key: "/people", icon: <TeamOutlined />, label: "People", disabled: isETF },
    { key: "/supply-chain", icon: <LinkOutlined />, label: "Supply Chain", disabled: isETF },
    { key: "/markets", icon: <GlobalOutlined />, label: "Markets" },
    { key: "/watchlists", icon: <StarOutlined />, label: "Watchlists" },
    { key: "/stock-screener", icon: <FilterOutlined />, label: "Stock Screener" },
    { key: "/earnings-transcript", icon: <AudioOutlined />, label: "Earnings Transcript", disabled: isETF },
    { key: "/calendar", icon: <CalendarOutlined />, label: "Calendar" },
  ];

  return (
    <Sider width={200} className="site-layout-background">
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{ height: "100%", borderRight: 0 }}
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
            {item.disabled ? (
              <span>{item.label}</span>
            ) : (
              <Link to={item.key}>{item.label}</Link>
            )}
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default LeftNavBar;

