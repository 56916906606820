import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Form, Select, InputNumber, Switch, message, Spin } from 'antd';
import { useStockScreen } from '../../contexts/StockScreenContext';

const { Option } = Select;

const CriterionModal = ({ visible, onClose, onSave, criterion, usedFields, allFields }) => {
  const [form] = Form.useForm();
  const { getFieldOptions } = useStockScreen();
  const [criterionType, setCriterionType] = useState('');
  const [fieldOptions, setFieldOptions] = useState({ fields: [], values: {} });
  const [selectedField, setSelectedField] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsLoading(true);
      form.resetFields();
      if (criterion) {
        form.setFieldsValue(criterion);
        setCriterionType(criterion.type);
        setSelectedField(criterion.field);
      } else {
        setCriterionType('');
        setSelectedField('');
        form.setFieldsValue({ type: undefined, field: undefined, min: undefined, max: undefined, value: undefined, values: undefined });
      }
      setIsLoading(false);
    }
  }, [visible, criterion, form]);

  useEffect(() => {
    if (criterionType) {
      const options = getFieldOptions(criterionType);
      console.log('Field options for type:', criterionType, options);
      setFieldOptions(options);
    }
  }, [criterionType, getFieldOptions]);

  const filteredFields = useMemo(() => {
    console.log('Filtering fields. All fields:', allFields, 'Criterion type:', criterionType);
    return allFields.filter(field => {
      if (criterionType === 'numeric') {
        return ['marketCap', 'price', 'beta', 'volume', 'dividend'].includes(field.value);
      } else if (criterionType === 'boolean') {
        return ['isEtf', 'isFund', 'isActivelyTrading'].includes(field.value);
      } else if (criterionType === 'string') {
        return ['sector', 'industry', 'exchange', 'country'].includes(field.value);
      }
      return false;
    });
  }, [allFields, criterionType]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (values.type === 'numeric' && values.min === undefined && values.max === undefined) {
        throw new Error('At least one of Minimum or Maximum must be specified for numeric criteria.');
      }
      if (values.type === 'string' && (!values.values || values.values.length === 0)) {
        throw new Error('At least one value must be selected for string criteria.');
      }
      onSave(values);
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      } else {
        console.error('Validation failed:', error);
        message.error('Please fill in all required fields correctly.');
      }
    }
  };

  const handleTypeChange = (value) => {
    console.log('Type changed to:', value);
    setCriterionType(value);
    setSelectedField('');
    form.setFieldsValue({ field: undefined, min: undefined, max: undefined, value: undefined, values: undefined });
  };

  const handleFieldChange = (value) => {
    console.log('Field changed to:', value);
    setSelectedField(value);
  };

  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  const renderValueInput = () => {
    console.log('Rendering value input. Criterion type:', criterionType, 'Selected field:', selectedField, 'Field options:', fieldOptions);
    
    if (criterionType === 'numeric') {
      const inputNumberStyle = { width: '100%' };
      return (
        <>
          <Form.Item name="min" label="Minimum">
            <InputNumber
              style={inputNumberStyle}
              formatter={formatNumber}
              parser={(value) => value.replace(/$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item name="max" label="Maximum">
            <InputNumber
              style={inputNumberStyle}
              formatter={formatNumber}
              parser={(value) => value.replace(/$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </>
      );
    } else if (criterionType === 'boolean') {
      return (
        <Form.Item name="value" label="Value" valuePropName="checked">
          <Switch />
        </Form.Item>
      );
    } else if (criterionType === 'string' && selectedField) {
      const options = fieldOptions.values[selectedField] || [];
      console.log('String options for field', selectedField, ':', options);
      return (
        <Form.Item name="values" label="Values" rules={[{ required: true, message: 'Please select at least one value' }]}>
          <Select mode="multiple">
            {options.map(option => (
              <Option key={option.value} value={option.value}>{option.label}</Option>
            ))}
          </Select>
        </Form.Item>
      );
    }
    return null;
  };

  console.log('Rendering CriterionModal. Filtered fields:', filteredFields);

  return (
    <Modal
      title={criterion ? 'Edit Criterion' : 'Add Criterion'}
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      confirmLoading={isLoading}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" initialValues={criterion}>
          <Form.Item name="id" hidden>
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="type" label="Criterion Type" rules={[{ required: true, message: 'Please select a criterion type' }]}>
            <Select onChange={handleTypeChange}>
              <Option value="numeric">Numeric (e.g., Market Cap, Dividend)</Option>
              <Option value="boolean">True/False (e.g., Is Actively Trading, Is ETF)</Option>
              <Option value="string">Text (e.g., Sector, Industry)</Option>
            </Select>
          </Form.Item>

          {criterionType && (
            <Form.Item name="field" label="Field" rules={[{ required: true, message: 'Please select a field' }]}>
              <Select
                onChange={handleFieldChange}
                disabled={isLoading}
              >
                {filteredFields.map(option => (
                  <Option 
                    key={option.value} 
                    value={option.value} 
                    disabled={usedFields.has(option.value) && option.value !== criterion?.field}
                  >
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {renderValueInput()}
        </Form>
      </Spin>
    </Modal>
  );
};

export default CriterionModal;
