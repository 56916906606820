import React, { createContext, useCallback, useState } from 'react';
import { getFinancials } from '../services/api';

export const FinancialContext = createContext();

export const FinancialProvider = ({ children }) => {
  const [financialData, setFinancialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [exchangeShortName, setExchangeShortName] = useState('');

  const fetchFinancialData = useCallback(async (ticker, statementType, period) => {
    if (financialData[ticker]?.[statementType]?.[period]) {
      return; // Data already loaded, no need to fetch again
    }

    setLoading(true);
    setError(null);
    try {
      const endpoint = statementType.replace('_', '-');
      const response = await getFinancials(ticker, endpoint, period);
      setFinancialData(prevData => ({
        ...prevData,
        [ticker]: {
          ...prevData[ticker],
          [statementType]: {
            ...(prevData[ticker]?.[statementType] || {}),
            [period]: response.data,
          },
        },
      }));
    } catch (err) {
      setError(`Failed to fetch ${statementType} data for ${ticker} (${period})`);
      console.error(`Error fetching ${statementType} data for ${ticker} (${period}):`, err);
    } finally {
      setLoading(false);
    }
  }, [financialData]);

  const updateExchangeShortName = (newExchangeShortName) => {
    setExchangeShortName(newExchangeShortName);
  };

  return (
    <FinancialContext.Provider value={{ financialData, loading, error, fetchFinancialData, exchangeShortName, updateExchangeShortName }}>
      {children}
    </FinancialContext.Provider>
  );
};

