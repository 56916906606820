export const formatLargeNumber = (number, currency = null) => {
  if (number === null || number === undefined) return 'N/A';
  
  const absNumber = Math.abs(number);
  let formatted;
  
  if (absNumber >= 1e9) {
    formatted = (absNumber / 1e9).toFixed(1) + 'B';
  } else if (absNumber >= 1e6) {
    formatted = (absNumber / 1e6).toFixed(1) + 'M';
  } else if (absNumber >= 1e3) {
    formatted = (absNumber / 1e3).toFixed(1) + 'K';
  } else {
    formatted = absNumber.toFixed(0);
  }
  
  if (currency) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, maximumFractionDigits: 0 })
      .format(absNumber)
      .replace(/\d{1,3}(,\d{3})*/, formatted);
  } else {
    return formatted;
  }
};
