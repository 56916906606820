import { Alert, Modal, Radio, Space, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { getCurrencySymbol } from '../../../utils/currencyMapping';
import { filterData } from '../../Analysts/utilities';
import ChartView from './ChartView';

const { TabPane } = Tabs;

const EarningsChartModal = ({ visible, onClose, data, company, exchangeShortName }) => {
  const [activeTab, setActiveTab] = useState('eps');
  const [timeRange, setTimeRange] = useState('5y');
  const [frequencyMode, setFrequencyMode] = useState('quarterly');
  const [filteredData, setFilteredData] = useState(null);
  const [error, setError] = useState(null);

  const currencySymbol = getCurrencySymbol(exchangeShortName);

  useEffect(() => {
    console.log('EarningsChartModal - Raw data received:', data);
    if (data) {
      try {
        if (!Array.isArray(data)) {
          console.error('EarningsChartModal - Data is not an array:', data);
          throw new Error('Data is not an array');
        }
        const filtered = filterData(data, timeRange);
        console.log('EarningsChartModal - Filtered data:', filtered);
        setFilteredData(filtered);
        setError(null);
      } catch (err) {
        console.error('EarningsChartModal - Error processing data:', err);
        setError(err.message);
        setFilteredData(null);
      }
    } else {
      console.log('EarningsChartModal - No data received');
      setFilteredData(null);
      setError('No data available');
    }
  }, [data, timeRange]);

  const tabs = [
    { key: 'eps', title: 'EPS' },
    { key: 'revenue', title: 'Revenue' },
    { key: 'ebitda', title: 'EBITDA' },
    { key: 'ebit', title: 'EBIT' },
    { key: 'netIncome', title: 'Net Income' },
    { key: 'sgaExpense', title: 'SGA Expense' },
  ];

  return (
    <Modal
      title={`${company} - Earnings Data`}
      open={visible}
      onCancel={onClose}
      width="90vw"
      style={{ top: 20 }}
      footer={null}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space>
          <Radio.Group value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
            <Radio.Button value="1y">1Y</Radio.Button>
            <Radio.Button value="2y">2Y</Radio.Button>
            <Radio.Button value="5y">5Y</Radio.Button>
            <Radio.Button value="10y">10Y</Radio.Button>
            <Radio.Button value="all">All</Radio.Button>
          </Radio.Group>
        </Space>
        {error ? (
          <Alert message="Error" description={error} type="error" showIcon />
        ) : filteredData ? (
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            {tabs.map(tab => (
              <TabPane tab={tab.title} key={tab.key}>
                <ChartView 
                  data={filteredData} 
                  estimateType={tab.key}
                  timeRange={timeRange}
                  frequencyMode={frequencyMode}
                  currencySymbol={currencySymbol}
                />
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <Spin size="large" />
        )}
      </Space>
    </Modal>
  );
};

export default EarningsChartModal;
