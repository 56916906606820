import { Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useStockScreen } from '../../contexts/StockScreenContext';

const ScreenResultsTable = ({ screenId, criteria }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const { applyScreen } = useStockScreen();

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      try {
        const screenResults = await applyScreen(screenId);
        setResults(screenResults);
      } catch (error) {
        console.error('Error fetching screen results:', error);
        message.error('Failed to fetch screen results');
      } finally {
        setLoading(false);
      }
    };

    if (screenId && criteria.length > 0) {
      fetchResults();
    } else {
      setResults([]);
    }
  }, [screenId, criteria, applyScreen]);

  const columns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (price) => price.toFixed(2),
    },
    {
      title: 'Market Cap',
      dataIndex: 'marketCap',
      key: 'marketCap',
      sorter: (a, b) => a.marketCap - b.marketCap,
      render: (marketCap) => {
        if (marketCap >= 1e12) return (marketCap / 1e12).toFixed(2) + 'T';
        if (marketCap >= 1e9) return (marketCap / 1e9).toFixed(2) + 'B';
        if (marketCap >= 1e6) return (marketCap / 1e6).toFixed(2) + 'M';
        return marketCap.toFixed(2);
      },
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      filters: [...new Set(results.map(item => item.sector))].map(sector => ({ text: sector, value: sector })),
      onFilter: (value, record) => record.sector === value,
    },
  ];

  return (
    <Table
      dataSource={results}
      columns={columns}
      rowKey="symbol"
      loading={loading}
      scroll={{ y: 'calc(100vh - 480px)', x: true }}
    />
  );
};

export default ScreenResultsTable;
