import React from 'react';
import { Skeleton, Card } from 'antd';

const SkeletonLoader = ({ activeTab }) => {
  const renderDetailedSkeleton = () => (
    <Card>
      <Skeleton active paragraph={{ rows: 4 }} />
      <Skeleton active paragraph={{ rows: 4 }} />
      <Skeleton active paragraph={{ rows: 4 }} />
      <Skeleton active paragraph={{ rows: 4 }} />
    </Card>
  );

  const renderTableSkeleton = () => (
    <Card>
      <Skeleton active paragraph={{ rows: 10 }} />
    </Card>
  );

  const renderGraphSkeleton = () => (
    <Card>
      <Skeleton.Image style={{ width: '100%', height: '400px' }} />
    </Card>
  );

  switch (activeTab) {
    case 'detailed':
      return renderDetailedSkeleton();
    case 'table':
      return renderTableSkeleton();
    case 'graph':
      return renderGraphSkeleton();
    default:
      return renderDetailedSkeleton();
  }
};

export default SkeletonLoader;
