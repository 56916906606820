import { Avatar, Card, Select, Spin, Typography, message } from 'antd';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useCompany } from '../../contexts/CompanyContext';
import { useTheme } from '../../contexts/ThemeContext';
import { getAvailableTranscripts, getTranscriptContent } from '../../services/api';

const { Option } = Select;
const { Title } = Typography;

const AVATAR_SIZE = 32;
const AVATAR_MARGIN = 16;

const useAvatarAnimation = (messageRef, avatarRef, isCompanyPerson) => {
  const [avatarPosition, setAvatarPosition] = useState(0);

  const animateAvatar = useCallback(() => {
    if (!messageRef.current || !avatarRef.current) return;

    const messageRect = messageRef.current.getBoundingClientRect();
    const avatarRect = avatarRef.current.getBoundingClientRect();
    const scrollContainer = messageRef.current.closest('.transcript-content');
    const scrollRect = scrollContainer.getBoundingClientRect();

    const topBound = scrollRect.top + AVATAR_MARGIN;
    const bottomBound = scrollRect.bottom - AVATAR_MARGIN - AVATAR_SIZE;

    let newPosition;

    if (messageRect.top >= bottomBound) {
      // Message is below the viewport, keep avatar at the top
      newPosition = 0;
    } else if (messageRect.bottom <= topBound) {
      // Message is above the viewport, keep avatar at the bottom
      newPosition = messageRect.height - AVATAR_SIZE;
    } else if (messageRect.top < topBound) {
      // Message is scrolled up, push avatar down
      newPosition = Math.min(topBound - messageRect.top, messageRect.height - AVATAR_SIZE);
    } else {
      // Message is partially in view, keep avatar at the top
      newPosition = 0;
    }

    setAvatarPosition(newPosition);
  }, []);

  useEffect(() => {
    const scrollContainer = messageRef.current?.closest('.transcript-content');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', animateAvatar);
      window.addEventListener('resize', animateAvatar);
      animateAvatar();

      return () => {
        scrollContainer.removeEventListener('scroll', animateAvatar);
        window.removeEventListener('resize', animateAvatar);
      };
    }
  }, [animateAvatar]);

  return avatarPosition;
};

const AnimatedMessage = React.memo(({ message, person, isDarkMode, theme, getInitials, getMessageStyle }) => {
  const { name, content } = message;
  const { type, title } = person;
  const { backgroundColor, color } = getMessageStyle(type);
  const isCompanyPerson = type === 'COMPANY';

  const messageRef = useRef(null);
  const avatarRef = useRef(null);
  const avatarPosition = useAvatarAnimation(messageRef, avatarRef, isCompanyPerson);

  return (
    <div ref={messageRef} style={{ 
      display: 'flex', 
      marginBottom: 16,
      flexDirection: isCompanyPerson ? 'row-reverse' : 'row',
      width: '100%',
      position: 'relative',
      paddingLeft: isCompanyPerson ? 0 : AVATAR_SIZE + AVATAR_MARGIN,
      paddingRight: isCompanyPerson ? AVATAR_SIZE + AVATAR_MARGIN : 0,
    }}>
      <Avatar
        ref={avatarRef}
        style={{ 
          position: 'absolute',
          [isCompanyPerson ? 'right' : 'left']: 0,
          top: avatarPosition,
          transition: 'top 0.1s ease-out',
          backgroundColor: isDarkMode ? '#3a4a4a' : theme.token.colorPrimary,
          width: AVATAR_SIZE,
          height: AVATAR_SIZE,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {getInitials(name)}
      </Avatar>
      <div style={{ maxWidth: '80%', width: '100%' }}>
        <div style={{ 
          fontWeight: 'bold', 
          marginBottom: 4, 
          fontSize: '0.9em', 
          color: theme.token.colorText,
          textAlign: isCompanyPerson ? 'right' : 'left'
        }}>
          {name}{isCompanyPerson && ` (${title})`}
        </div>
        <div style={{
          backgroundColor,
          color,
          padding: 12,
          borderRadius: 18,
          wordWrap: 'break-word',
          boxShadow: `0 1px 2px ${isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
          marginLeft: isCompanyPerson ? 'auto' : '0',
          marginRight: isCompanyPerson ? '0' : 'auto',
          maxWidth: 'fit-content'
        }}>
          {content}
        </div>
      </div>
    </div>
  );
});

const EarningsTranscriptPage = () => {
  const { selectedTicker } = useCompany();
  const { isDarkMode, theme } = useTheme();
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [availableTranscripts, setAvailableTranscripts] = useState([]);
  const [transcriptContent, setTranscriptContent] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAvailableTranscripts = async () => {
      if (selectedTicker) {
        setLoading(true);
        try {
          const transcripts = await getAvailableTranscripts(selectedTicker);
          setAvailableTranscripts(transcripts);
          if (transcripts.length > 0) {
            const latestTranscript = transcripts[0];
            const latestTranscriptValue = `${latestTranscript.year}-${latestTranscript.quarter}`;
            setSelectedTranscript(latestTranscriptValue);
            handleTranscriptSelect(latestTranscriptValue);
          }
        } catch (error) {
          message.error('Failed to fetch available transcripts');
        }
        setLoading(false);
      }
    };

    fetchAvailableTranscripts();
  }, [selectedTicker]);

  const handleTranscriptSelect = async (value) => {
    setSelectedTranscript(value);
    setLoading(true);
    try {
      const [year, quarter] = value.split('-');
      const content = await getTranscriptContent(selectedTicker, parseInt(year), parseInt(quarter));
      setTranscriptContent(content);
    } catch (error) {
      message.error('Failed to fetch transcript content');
    }
    setLoading(false);
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const getMessageStyle = (type) => {
    let backgroundColor, textColor;
    switch (type) {
      case 'COMPANY':
        backgroundColor = isDarkMode ? '#3a4a4a' : theme.token.colorPrimary;
        textColor = '#ffffff';
        break;
      case 'OPERATOR':
        backgroundColor = isDarkMode ? '#636366' : '#8E8E93';
        textColor = '#ffffff';
        break;
      default:
        backgroundColor = isDarkMode ? '#3A3A3C' : '#E5E5EA';
        textColor = theme.token.colorText;
    }
    return { backgroundColor, color: textColor };
  };

  return (
    <div className="earnings-transcript-page" >
      <Title level={2} style={{ color: theme.token.colorText }}>Earnings Transcript</Title>
      <Card style={{ backgroundColor: theme.token.colorBgContainer, borderColor: isDarkMode ? '#333' : '#f0f0f0' }}>
        <Select
          style={{ width: '100%', marginBottom: 20 }}
          placeholder="Select a transcript"
          onChange={handleTranscriptSelect}
          loading={loading}
          showSearch
          value={selectedTranscript}
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {availableTranscripts.map((transcript) => (
            <Option 
              key={`${transcript.year}-${transcript.quarter}`} 
              value={`${transcript.year}-${transcript.quarter}`}
              label={`Q${transcript.quarter} ${transcript.year} - ${transcript.date}`}
            >
              Q{transcript.quarter} {transcript.year} - {transcript.date}
            </Option>
          ))}
        </Select>
        {loading ? (
          <Spin size="large" />
        ) : (
          transcriptContent && (
            <div className="transcript-content" style={{ maxHeight: '70vh', overflowY: 'auto', padding: `16px ${AVATAR_SIZE + AVATAR_MARGIN}px` }}>
              {transcriptContent.messages.map((message, index) => (
                <AnimatedMessage
                  key={index}
                  message={message}
                  person={transcriptContent.persons[message.name]}
                  isDarkMode={isDarkMode}
                  theme={theme}
                  getInitials={getInitials}
                  getMessageStyle={getMessageStyle}
                />
              ))}
            </div>
          )
        )}
      </Card>
    </div>
  );
};

export default EarningsTranscriptPage;
