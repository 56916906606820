import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Card, Tabs, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getSupplyChain } from '../../services/api';
import WebSocketManager from '../../utils/WebSocketManager';
import DetailedSupplyChainView from './components/DetailedSupplyChainView';
import SkeletonLoader from './components/SkeletonLoader';
import TableSupplyChainView from './components/TableSupplyChainView';
import GraphView from './GraphView';

const { TabPane } = Tabs;

const StatusBar = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const StatusIcon = styled.span`
  font-size: 24px;
  margin-right: 12px;
`;

const StatusText = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <StatusBar bgColor="#fff2f0" textColor="#ff4d4f">
          <StatusIcon><InfoCircleOutlined /></StatusIcon>
          <StatusText>Something went wrong. Please try again later.</StatusText>
        </StatusBar>
      );
    }

    return this.props.children;
  }
}

const MemoizedDetailedSupplyChainView = React.memo(DetailedSupplyChainView);
const MemoizedTableSupplyChainView = React.memo(TableSupplyChainView);
const MemoizedGraphView = React.memo(GraphView);

// SupplyChain component
const SupplyChain = ({ ticker }) => {
  const [supplyChainData, setSupplyChainData] = useState({
    suppliers: [],
    customers: [],
    peers: [],
    commodities: []
  });
  const [symbolData, setSymbolData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responseInfo, setResponseInfo] = useState('');
  const [activeTab, setActiveTab] = useState('detailed');
  const [isLLMGenerating, setIsLLMGenerating] = useState(false);

  const fetchSupplyChainData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      setResponseInfo('');
      const response = await getSupplyChain(ticker);
      console.log('API Response received');
      setSupplyChainData(response.data.response || {
        suppliers: [],
        customers: [],
        peers: [],
        commodities: []
      });
      setResponseInfo(response.data.info || '');
      setIsLLMGenerating(response.data.isLLMGenerating);
    } catch (error) {
      console.error('Error fetching supply chain data:', error);
      setError('Failed to fetch supply chain data. Please try again later.');
      message.error('Failed to fetch supply chain data');
    } finally {
      setLoading(false);
    }
  }, [ticker]);

  useEffect(() => {
    fetchSupplyChainData();
  }, [fetchSupplyChainData]);

  useEffect(() => {
    let pollInterval;
    if (isLLMGenerating) {
      pollInterval = setInterval(fetchSupplyChainData, 2000); // Poll every 2 seconds
    }
    return () => clearInterval(pollInterval);
  }, [isLLMGenerating, fetchSupplyChainData]);

  const updateSymbolData = useCallback((symbol, data) => {
    setSymbolData(prevData => ({
      ...prevData,
      [symbol]: { ...prevData[symbol], ...data }
    }));
  }, []);

  useEffect(() => {
    const allSymbols = [
      ...(supplyChainData.suppliers || []),
      ...(supplyChainData.customers || []),
      ...(supplyChainData.peers || []),
      ...(supplyChainData.commodities || [])
    ].map(item => item.symbol);

    WebSocketManager.subscribe(allSymbols, updateSymbolData);

    return () => {
      WebSocketManager.unsubscribe(allSymbols, updateSymbolData);
    };
  }, [supplyChainData, updateSymbolData]);

  const memoizedSupplyChainData = useMemo(() => supplyChainData, [supplyChainData]);
  const memoizedSymbolData = useMemo(() => symbolData, [symbolData]);

  const renderStatusBar = () => {
    if (isLLMGenerating) {
      return (
        <StatusBar bgColor="#e6f7ff" textColor="#1890ff">
          <StatusIcon><LoadingOutlined /></StatusIcon>
          <StatusText>{responseInfo || 'Analyzing supply chain data...'}</StatusText>
        </StatusBar>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (loading || isLLMGenerating) {
      return <SkeletonLoader activeTab={activeTab} />;
    }
    if (error) {
      return (
        <StatusBar bgColor="#fff2f0" textColor="#ff4d4f">
          <StatusIcon><InfoCircleOutlined /></StatusIcon>
          <StatusText>{error}</StatusText>
        </StatusBar>
      );
    }

    return (
      <Card>
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="Detailed View" key="detailed">
            <MemoizedDetailedSupplyChainView supplyChainData={memoizedSupplyChainData} />
          </TabPane>
          <TabPane tab="Table View" key="table">
            <MemoizedTableSupplyChainView supplyChainData={memoizedSupplyChainData} symbolData={memoizedSymbolData} />
          </TabPane>
          {/* <TabPane tab="Graph View" key="graph">
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoader activeTab="graph" />}>
                <MemoizedGraphView supplyChainData={memoizedSupplyChainData} ticker={ticker} />
              </Suspense>
            </ErrorBoundary>
          </TabPane> */}
        </Tabs>
      </Card>
    );
  };

  const memoizedContent = useMemo(() => renderContent(), [loading, isLLMGenerating, error, activeTab, memoizedSupplyChainData, memoizedSymbolData]);

  return (
    <div style={{ height: '90vh', width: '100%', overflowY: 'auto' }}>
      {renderStatusBar()}
      {memoizedContent}
    </div>
  );
};

export default SupplyChain;
