
import { ConfigProvider, Layout } from "antd";
import React from "react";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import Analysts from "./components/Analysts/Analysts";
import AuthPage from "./components/Auth/AuthPage";
import Calendar from "./components/Calendar/Calendar";
import Charts from "./components/Charts/Charts";
import EarningsTranscriptPage from "./components/EarningsTranscript/EarningsTranscriptPage";
import FinancialsPage from "./components/FinancialsPage/FinancialsPage";
import LeftNavBar from "./components/LeftNavBar/LeftNavBar";
import Markets from "./components/Markets/Markets";
import News from "./components/News/News";
import Overview from "./components/Overview/Overview";
import People from "./components/People/People";
import ProtectedRoute from "./components/ProtectedRoute";
import StockScreenerPage from "./components/StockScreener/StockScreenerPage";
import SupplyChain from "./components/SupplyChain/SupplyChain";
import TopBar from "./components/TopBar/TopBar";
import UserProfile from "./components/UserProfile/UserProfile";
import WatchlistPage from "./components/Watchlist/WatchlistPage";
import { AuthProvider } from "./contexts/AuthContext";
import { CompanyProvider, useCompany } from "./contexts/CompanyContext";
import { CurrentWatchlistProvider } from "./contexts/CurrentWatchlistContext";
import { FinancialProvider } from "./contexts/FinancialContext";
import { StockScreenProvider } from "./contexts/StockScreenContext";
import { ThemeProvider, useTheme } from "./contexts/ThemeContext";
import { WatchlistProvider } from "./contexts/WatchlistContext";

const { Content } = Layout;

const AppContent = () => {
  const { theme } = useTheme();
  const { selectedTicker, exchangeShortName, isETF, updateTicker } = useCompany();
  const location = useLocation();

  const isAuthPage = location.pathname === "/login" || location.pathname === "/signup";

  if (isAuthPage) {
    return <AuthPage />;
  }

  return (
    <ConfigProvider theme={theme}>
      <Layout style={{ minHeight: "100vh" }}>
        <TopBar selectedTicker={selectedTicker} onSearch={updateTicker} />
        <Layout>
          <LeftNavBar isETF={isETF} />
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
              <Routes>
                <Route path="/" element={<ProtectedRoute><Overview ticker={selectedTicker} exchangeShortName={exchangeShortName} /></ProtectedRoute>} />
                <Route path="/news" element={<ProtectedRoute><News tickers={selectedTicker} exchangeShortName={exchangeShortName} /></ProtectedRoute>} />
                <Route path="/financials" element={
                  <ProtectedRoute>
                    <FinancialProvider>
                      <FinancialsPage ticker={selectedTicker} exchangeShortName={exchangeShortName} />
                    </FinancialProvider>
                  </ProtectedRoute>
                } />
                <Route path="/charts" element={<ProtectedRoute><Charts ticker={selectedTicker} exchangeShortName={exchangeShortName} /></ProtectedRoute>} />
                <Route path="/analysts" element={<ProtectedRoute><Analysts ticker={selectedTicker} exchangeShortName={exchangeShortName} /></ProtectedRoute>} />
                <Route path="/people" element={<ProtectedRoute><People ticker={selectedTicker} exchangeShortName={exchangeShortName} /></ProtectedRoute>} />
                <Route path="/supply-chain" element={<ProtectedRoute><SupplyChain ticker={selectedTicker} exchangeShortName={exchangeShortName} /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
                <Route path="/markets" element={<ProtectedRoute><Markets /></ProtectedRoute>} />
                <Route path="/watchlists" element={
                  <ProtectedRoute>
                    <WatchlistProvider>
                      <CurrentWatchlistProvider>
                        <WatchlistPage />
                      </CurrentWatchlistProvider>
                    </WatchlistProvider>
                  </ProtectedRoute>
                } />
                <Route path="/stock-screener" element={
                  <ProtectedRoute>
                    <StockScreenProvider>
                      <StockScreenerPage />
                    </StockScreenProvider>
                  </ProtectedRoute>
                } />
                <Route path="/earnings-transcript" element={<ProtectedRoute><EarningsTranscriptPage /></ProtectedRoute>} />
                <Route path="/calendar" element={<ProtectedRoute><Calendar /></ProtectedRoute>} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

const App = () => (
  <AuthProvider>
    <ThemeProvider>
      <CompanyProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<AuthPage />} />
            <Route path="/signup" element={<AuthPage />} />
            <Route path="/*" element={<AppContent />} />
          </Routes>
        </Router>
      </CompanyProvider>
    </ThemeProvider>
  </AuthProvider>
);

export default App;

