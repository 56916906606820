import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCompanyInfo } from '../services/api';
import { useAuth } from './AuthContext';

const CompanyContext = createContext();

export const useCompany = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [companyInfo, setCompanyInfo] = useState({});
  const [selectedTicker, setSelectedTicker] = useState('AAPL');
  const [exchangeShortName, setExchangeShortName] = useState('NASDAQ');
  const [isETF, setIsETF] = useState(false);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await getCompanyInfo(selectedTicker);
        setCompanyInfo(response.data);
        setIsETF(response.data.profile.isEtf);
      } catch (error) {
        console.error('Error fetching company info:', error);
      }
    };

    fetchCompanyInfo();
  }, [selectedTicker, currentUser]);

  const updateTicker = (ticker, exchange) => {
    setSelectedTicker(ticker);
    setExchangeShortName(exchange);
  };

  return (
    <CompanyContext.Provider value={{ companyInfo, isETF, selectedTicker, exchangeShortName, updateTicker }}>
      {children}
    </CompanyContext.Provider>
  );
};
