export const formatLargeNumber = (num) => {
  if (num === null || num === undefined) return 'N/A';
  const absNum = Math.abs(num);
  const sign = num < 0 ? '-' : '';
  if (absNum >= 1e9) return sign + (absNum / 1e9).toFixed(1) + 'B';
  if (absNum >= 1e6) return sign + (absNum / 1e6).toFixed(1) + 'M';
  if (absNum >= 1e3) return sign + (absNum / 1e3).toFixed(1) + 'K';
  return sign + absNum.toFixed(2);
};

export const filterData = (data, range) => {
  if (!data) return [];
  const cutoffDate = new Date();
  switch (range) {
    case '1y':
      cutoffDate.setFullYear(cutoffDate.getFullYear() - 1);
      break;
    case '2y':
      cutoffDate.setFullYear(cutoffDate.getFullYear() - 2);
      break;
    case '5y':
      cutoffDate.setFullYear(cutoffDate.getFullYear() - 5);
      break;
    case '10y':
      cutoffDate.setFullYear(cutoffDate.getFullYear() - 10);
      break;
    default:
      return data;
  }
  return data.filter(item => new Date(item.date) >= cutoffDate);
};

export const formatValue = (value, useCurrency, currencySymbol) => {
  if (value === null || value === undefined) return 'N/A';
  const formattedValue = formatLargeNumber(value);
  return useCurrency ? `${currencySymbol}${formattedValue}` : formattedValue;
};

export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};
