import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './FinancialsTable.css';

const numberMetrics = [
  'grossProfitRatio',
  'operatingIncomeRatio',
  'incomeBeforeTaxRatio',
  'netIncomeRatio',
  'ebitdaratio',
  'weightedAverageShsOut',
  'weightedAverageShsOutDil',
  'epsNumberAnalysts',
  'revenueNumberAnalysts',
  'ebitdaNumberAnalysts',
  'ebitNumberAnalysts',
  'netIncomeNumberAnalysts',
  'sgaExpenseNumberAnalysts',
];

const formatValue = (value, currencySymbol, isNumber = false) => {
  if (value === null || value === undefined) return '';
  
  var prefix = '';
  if (!isNumber) {
    prefix = currencySymbol;
  }
  
  if (typeof value === 'number') {
    if (Math.abs(value) >= 1e9) {
      return `${prefix}${(value / 1e9).toFixed(2)}B`;
    } else if (Math.abs(value) >= 1e6) {
      return `${prefix}${(value / 1e6).toFixed(2)}M`;
    } else {
      return `${prefix}${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }
  }
  
  return value;
};

const FinancialsTable = ({ data, metrics, currencySymbol, timeRange }) => {
  const tableRef = useRef(null);
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    if (tableRef.current) {
      const tableBody = tableRef.current.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.scrollLeft = tableBody.scrollWidth;
      }
    }

    // Set all rows to expanded by default
    const allExpanded = {};
    flattenMetrics(metrics).forEach(metric => {
      allExpanded[metric.value] = true;
    });
    setExpandedRows(allExpanded);
  }, [data, metrics]);

  const toggleRow = (key) => {
    setExpandedRows(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const columns = [
    {
      title: 'Metric',
      dataIndex: 'metric',
      key: 'metric',
      fixed: 'left',
      width: 300,
      render: (text, record) => (
        <span
          className={`metric-cell level-${record.level}`}
          style={{ paddingLeft: `${record.level * 20}px` }}
        >
          {record.children && record.children.length > 0 && (
            <span onClick={() => toggleRow(record.key)} style={{ cursor: 'pointer', marginRight: '5px' }}>
              {expandedRows[record.key] ? <CaretDownOutlined /> : <CaretRightOutlined />}
            </span>
          )}
          {(!record.children || record.children.length === 0) && <span style={{ marginLeft: '17px' }}></span>}
          {text}
        </span>
      ),
    },
    ...data.slice(-timeRange).map(item => ({
      title: `${item.calendarYear} ${item.period}`,
      dataIndex: item.date,
      key: item.date,
      render: (value, record) => formatValue(value, currencySymbol, numberMetrics.includes(record.key)),
    })),
  ];

  const flattenMetrics = (metrics, level = 0) => {
    return metrics.reduce((acc, metric) => {
      const flatMetric = { ...metric, level, key: metric.value };
      acc.push(flatMetric);
      if (metric.children && metric.children.length > 0) {
        acc.push(...flattenMetrics(metric.children, level + 1));
      }
      return acc;
    }, []);
  };

  const flatMetrics = flattenMetrics(metrics);

  const tableData = flatMetrics.map(metric => ({
    ...metric,
    metric: metric.label,
    ...data.slice(-timeRange).reduce((acc, item) => {
      acc[item.date] = item[metric.value];
      return acc;
    }, {}),
  }));

  const filteredData = tableData.filter(item => {
    if (item.level === 0) return true;
    let currentLevel = item.level;
    let currentKey = item.key;
    while (currentLevel > 0) {
      const parentMetric = flatMetrics.find(m => m.children && m.children.some(child => child.value === currentKey) && m.level === currentLevel - 1);
      if (parentMetric && !expandedRows[parentMetric.key]) return false;
      currentKey = parentMetric ? parentMetric.key : null;
      currentLevel--;
    }
    return true;
  });

  return (
    <div className="financials-table-container" ref={tableRef}>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        scroll={{ x: 'max-content', y: 'calc(100vh - 350px)' }}
        size="small"
        rowClassName={(record) => `row-level-${record.level}`}
        expandable={{ expandedRowKeys: [], expandIcon: () => null }}
      />
    </div>
  );
};

export default FinancialsTable;
