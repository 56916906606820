import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBFcRYNn_DZNXBG_3YMzZ8FXNGidJ7YGOc",
  authDomain: "noted-robot-430807-u8.firebaseapp.com",
  projectId: "noted-robot-430807-u8",
  storageBucket: "noted-robot-430807-u8.appspot.com",
  messagingSenderId: "130729742621",
  appId: "1:130729742621:web:f613c23e44207dec6b91f4",
  measurementId: "G-Y0E74P8509"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
