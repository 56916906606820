import { getMetrics } from './Metrics';

const createNode = (name, color, value) => ({ name, color, value });
const createLink = (source, target, value) => ({ source, target, value });

const getSankeyColors = (theme) => {
  return {
    revenue: theme === 'dark' ? '#4CAF50' : '#2E7D32',
    expenses: theme === 'dark' ? '#F44336' : '#C62828',
    profit: theme === 'dark' ? '#2196F3' : '#1565C0',
    assets: theme === 'dark' ? '#FFC107' : '#FFA000',
    liabilities: theme === 'dark' ? '#FF5722' : '#E64A19',
    equity: theme === 'dark' ? '#9C27B0' : '#7B1FA2',
  };
};

export const getSankeyMetrics = (tabKey, financialData, theme) => {
  const colors = getSankeyColors(theme);
  
  switch (tabKey) {
    case 'income_statement':
      return getIncomeSankeyMetrics(financialData, colors);
    case 'balance_sheet':
      return getBalanceSheetSankeyMetrics(financialData, colors);
    default:
      return { nodes: [], links: [] };
  }
};

const getIncomeSankeyMetrics = (financialData, colors) => {
  const nodes = [
    createNode('Revenue', colors.revenue, financialData.revenue),
    createNode('Cost of Revenue', colors.expenses, financialData.costOfRevenue),
    createNode('Gross Profit', colors.profit, financialData.grossProfit),
    createNode('Operating Expenses', colors.expenses, financialData.operatingExpenses),
    createNode('R&D Expenses', colors.expenses, financialData.researchAndDevelopmentExpenses),
    createNode('SG&A Expenses', colors.expenses, financialData.sellingGeneralAndAdministrativeExpenses),
    createNode('Other Operating Expenses', colors.expenses, financialData.otherExpenses),
    createNode('Operating Income', colors.profit, financialData.operatingIncome),
    createNode('Other Income/Expenses', colors.expenses, financialData.totalOtherIncomeExpensesNet),
    createNode('Income Before Tax', colors.profit, financialData.incomeBeforeTax),
    createNode('Income Tax Expense', colors.expenses, financialData.incomeTaxExpense),
    createNode('Net Income', colors.profit, financialData.netIncome),
  ];

  const links = [
    createLink(0, 1, financialData.costOfRevenue),
    createLink(0, 2, financialData.grossProfit),
    createLink(2, 3, financialData.operatingExpenses),
    createLink(3, 4, financialData.researchAndDevelopmentExpenses),
    createLink(3, 5, financialData.sellingGeneralAndAdministrativeExpenses),
    createLink(3, 6, financialData.otherExpenses),
    createLink(2, 7, financialData.operatingIncome),
    createLink(7, 8, financialData.totalOtherIncomeExpensesNet),
    createLink(7, 9, financialData.incomeBeforeTax),
    createLink(9, 10, financialData.incomeTaxExpense),
    createLink(9, 11, financialData.netIncome),
  ];

  return { nodes, links };
};

const getBalanceSheetSankeyMetrics = (financialData, colors) => {
  const nodes = [
    createNode('Total Assets', colors.assets, financialData.totalAssets),
    createNode('Current Assets', colors.assets, financialData.totalCurrentAssets),
    createNode('Non-Current Assets', colors.assets, financialData.totalNonCurrentAssets),
    createNode('Total Liabilities', colors.liabilities, financialData.totalLiabilities),
    createNode('Current Liabilities', colors.liabilities, financialData.totalCurrentLiabilities),
    createNode('Non-Current Liabilities', colors.liabilities, financialData.totalNonCurrentLiabilities),
    createNode('Stockholders Equity', colors.equity, financialData.totalStockholdersEquity),
  ];

  const links = [
    createLink(0, 1, financialData.totalCurrentAssets),
    createLink(0, 2, financialData.totalNonCurrentAssets),
    createLink(1, 4, financialData.totalCurrentLiabilities),
    createLink(2, 5, financialData.totalNonCurrentLiabilities),
    createLink(1, 6, financialData.totalStockholdersEquity / 2),
    createLink(2, 6, financialData.totalStockholdersEquity / 2),
    createLink(3, 4, financialData.totalCurrentLiabilities),
    createLink(3, 5, financialData.totalNonCurrentLiabilities),
    createLink(3, 6, financialData.totalStockholdersEquity),
  ];

  return { nodes, links };
};

export default getSankeyMetrics;
