import { Table } from 'antd';
import React from 'react';
import { formatLargeNumber } from '../../utils/formatters';

const EmployeeCountTable = ({ data }) => {
  const columns = [
    {
      title: 'Year',
      dataIndex: 'periodOfReport',
      key: 'periodOfReport',
      render: (date) => new Date(date).getFullYear(),
    },
    {
      title: 'Employee Count',
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      render: (count) => formatLargeNumber(count),
    },
  ];

  // Sort the data in reverse chronological order
  const sortedData = [...data].sort((a, b) => new Date(b.periodOfReport) - new Date(a.periodOfReport));

  return (
    <Table
      dataSource={sortedData}
      columns={columns}
      pagination={false}
      size="small"
      scroll={{ y: "calc(100vh - 260px)" }}
    />
  );
};

export default EmployeeCountTable;
