import { message, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FinancialContext } from '../../contexts/FinancialContext';
import { useTheme } from '../../contexts/ThemeContext';
import { getCurrencySymbol } from '../../utils/currencyMapping';
import ErrorBoundary from '../ErrorBoundary';
import DataChart from './DataChart';
import DataSelector from './DataSelector';
import './FinancialsPage.css';
import FinancialsTable from './FinancialsTable';
import { getMetrics } from './Metrics';
import SankeyChart from './SankeyChart';
import { getSankeyMetrics } from './SankeyMetrics';

const { TabPane } = Tabs;

const financialTabs = [
  { key: 'income_statement', label: 'Income Statement', defaultField: 'revenue' },
  { key: 'balance_sheet', label: 'Balance Sheet', defaultField: 'cashAndCashEquivalents' },
  { key: 'cash_flow', label: 'Cash Flow', defaultField: 'netCashProvidedByOperatingActivities' },
];

const FinancialsPage = ({ ticker, exchangeShortName }) => {
  const { financialData, loading, error, fetchFinancialData } = useContext(FinancialContext);
  const { theme } = useTheme();
  const [activeTab, setActiveTab] = useState('income_statement');
  const [timeframe, setTimeframe] = useState('annual');
  const [selectedDataTypes, setSelectedDataTypes] = useState({
    income_statement: ['revenue'],
    balance_sheet: ['cashAndCashEquivalents'],
    cash_flow: ['netCashProvidedByOperatingActivities'],
  });
  const [timeRange, setTimeRange] = useState('5');
  const [chartData, setChartData] = useState([]);
  const [availableMetrics, setAvailableMetrics] = useState(getMetrics('income_statement'));
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [isETF, setIsETF] = useState(false);
  const [viewType, setViewType] = useState('table');

  useEffect(() => {
    fetchFinancialData(ticker, activeTab, timeframe);
  }, [ticker, activeTab, timeframe, fetchFinancialData]);

  useEffect(() => {
    setCurrencySymbol(getCurrencySymbol(exchangeShortName));
  }, [exchangeShortName]);

  useEffect(() => {
    if (financialData[ticker]?.[activeTab]?.[timeframe]) {
      const currentData = financialData[ticker][activeTab][timeframe];
      
      if (!currentData || currentData.length === 0 || !currentData[0].hasOwnProperty('date')) {
        setIsETF(true);
        return;
      }
      
      setIsETF(false);
      setAvailableMetrics(getMetrics(activeTab));

      let processedData = currentData.map(item => ({
        ...item,
        date: new Date(item.date).toLocaleDateString(),
      })).sort((a, b) => new Date(a.date) - new Date(b.date));

      setChartData(processedData);
    }
  }, [financialData, ticker, activeTab, timeframe]);

  const handleTabChange = (key) => {
    setActiveTab(key);
    setAvailableMetrics(getMetrics(key));
    if (selectedDataTypes[key].length === 0) {
      setSelectedDataTypes(prev => ({
        ...prev,
        [key]: [financialTabs.find(tab => tab.key === key).defaultField]
      }));
    }
  };

  const handleDataTypeChange = (values) => {
    setSelectedDataTypes(prev => ({
      ...prev,
      [activeTab]: values
    }));
  };

  if (error) {
    message.error(error);
    return <div className="error">Error loading financial data. Please try again later.</div>;
  }

  const filteredChartData = viewType === 'chart' && timeRange !== 'all'
    ? chartData.slice(-parseInt(timeRange) * (timeframe === 'annual' ? 1 : 4))
    : chartData;

  const renderContent = () => {
    switch (viewType) {
      case 'chart':
        return (
          <DataChart 
            data={filteredChartData} 
            selectedDataTypes={selectedDataTypes[activeTab]}
            availableMetrics={availableMetrics}
            currencySymbol={currencySymbol}
          />
        );
      case 'table':
        return (
          <FinancialsTable
            data={chartData}
            metrics={availableMetrics}
            currencySymbol={currencySymbol}
            timeRange={parseInt(timeRange) * (timeframe === 'annual' ? 1 : 4)}
          />
        );
      case 'sankey':
        return (
          <SankeyChart currencySymbol={currencySymbol}
            data={getSankeyMetrics(activeTab, chartData[chartData.length - 1], theme.theme)}
            tabKey={activeTab}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ErrorBoundary>
      <div className="financials-page" style={{ color: theme.token.colorText, backgroundColor: theme.token.colorBgContainer, height: '100vh', overflow: 'hidden', padding: '20px' }}>
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {financialTabs.map(tab => (
            <TabPane tab={tab.label} key={tab.key}>
              <div style={{ marginBottom: '20px' }}>
                <DataSelector
                  timeframe={timeframe}
                  setTimeframe={setTimeframe}
                  selectedDataTypes={selectedDataTypes[activeTab]}
                  setSelectedDataTypes={handleDataTypeChange}
                  availableMetrics={availableMetrics}
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                  viewType={viewType}
                  setViewType={setViewType}
                  activeTab={activeTab}
                />
              </div>
              {renderContent()}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </ErrorBoundary>
  );
};

export default FinancialsPage;
