export const estimateTypes = [
  { key: 'eps', label: 'EPS', useCurrency: true },
  { key: 'revenue', label: 'Revenue', useCurrency: true },
  { key: 'ebitda', label: 'EBITDA', useCurrency: true },
  { key: 'ebit', label: 'EBIT', useCurrency: true },
  { key: 'netIncome', label: 'Net Income', useCurrency: true },
  { key: 'sgaExpense', label: 'SGA Expense', useCurrency: true },
];

export const timeRanges = [
  { key: '1y', label: '1Y' },
  { key: '2y', label: '2Y' },
  { key: '5y', label: '5Y' },
  { key: '10y', label: '10Y' },
  { key: 'all', label: 'All' },
];
