import { Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

const AnalystSummary = ({ currentConsensus }) => (
  <div className="analyst-summary">
    <Title level={4}>Current Consensus</Title>
    <Text strong>{currentConsensus.label}</Text>
    <br />
    <Text>Average Rating: {currentConsensus.averageRating.toFixed(2)}</Text>
  </div>
);

export default AnalystSummary;
