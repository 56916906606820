import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Spin, Table, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { getWatchlists, removeSymbolFromWatchlist } from '../../services/api';
import WebSocketManager from '../../utils/WebSocketManager';

const { Text } = Typography;

const WatchlistDetail = ({ watchlistId, refreshTrigger }) => {
  const [watchlist, setWatchlist] = useState(null);
  const [symbolData, setSymbolData] = useState({});
  const [loading, setLoading] = useState(true);
  const { theme } = useTheme();

  const fetchWatchlist = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getWatchlists();
      const fetchedWatchlists = response.data;
      const foundWatchlist = fetchedWatchlists.find(w => w.id === watchlistId);
      if (foundWatchlist) {
        setWatchlist(foundWatchlist);
        console.log('Fetched watchlist:', foundWatchlist);
      } else {
        throw new Error('Watchlist not found');
      }
    } catch (error) {
      console.error('Error fetching watchlist:', error);
      message.error('Failed to fetch watchlist');
    } finally {
      setLoading(false);
    }
  }, [watchlistId]);

  useEffect(() => {
    fetchWatchlist();
  }, [fetchWatchlist, refreshTrigger]);

  const updateSymbolData = useCallback((symbol, data) => {
    console.log('Received data for symbol:', symbol, data);
    setSymbolData(prevData => {
      const updatedData = {
        ...prevData,
        [symbol]: { ...prevData[symbol], ...data }
      };
      console.log('Updated symbol data:', updatedData);
      return updatedData;
    });
  }, []);

  useEffect(() => {
    if (watchlist && watchlist.symbols) {
      const symbols = watchlist.symbols.map(s => s.symbol);
      console.log('Subscribing to symbols:', symbols);
      WebSocketManager.subscribe(symbols, updateSymbolData);

      return () => {
        console.log('Unsubscribing from symbols:', symbols);
        WebSocketManager.unsubscribe(symbols, updateSymbolData);
      };
    }
  }, [watchlist, updateSymbolData]);

  const handleRemoveSymbol = async (symbol) => {
    try {
      await removeSymbolFromWatchlist(watchlistId, symbol);
      await fetchWatchlist();
      message.success('Symbol removed successfully');
      WebSocketManager.unsubscribe(symbol, updateSymbolData);
    } catch (error) {
      console.error('Error removing symbol:', error);
      message.error('Failed to remove symbol');
    }
  };

  const renderSymbolData = (record, dataKey) => {
    const data = symbolData[record.symbol];
    if (!data) {
      console.warn(`No data for symbol: ${record.symbol}`);
      return '-';
    }
    const value = data[dataKey];
    if (value === undefined || value === null) {
      console.warn(`Missing ${dataKey} for symbol: ${record.symbol}`);
      return '-';
    }
    return value;
  };

  const columns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      title: 'Name',
      key: 'name',
      render: (_, record) => (
        <div>
          <Text>{renderSymbolData(record, 'name')}</Text>
          <br />
          <Text type="secondary">{record.match_reason || ''}</Text>
        </div>
      ),
      sorter: (a, b) => (symbolData[a.symbol]?.name || '').localeCompare(symbolData[b.symbol]?.name || ''),
    },
    {
      title: 'Price',
      key: 'price',
      render: (_, record) => {
        const price = renderSymbolData(record, 'price');
        return typeof price === 'number' ? price.toFixed(2) : price;
      },
      sorter: (a, b) => (symbolData[a.symbol]?.price || 0) - (symbolData[b.symbol]?.price || 0),
    },
    {
      title: 'Change',
      key: 'change',
      render: (_, record) => {
        const price = symbolData[record.symbol]?.price;
        const previousClose = symbolData[record.symbol]?.previousClose;
        if (price === undefined || previousClose === undefined) {
          console.warn(`Missing price or previousClose for symbol: ${record.symbol}`);
          return '-';
        }
        const change = price - previousClose;
        return (
          <span style={{ color: change >= 0 ? 'green' : 'red' }}>
            {change.toFixed(2)}
          </span>
        );
      },
      sorter: (a, b) => {
        const changeA = (symbolData[a.symbol]?.price || 0) - (symbolData[a.symbol]?.previousClose || 0);
        const changeB = (symbolData[b.symbol]?.price || 0) - (symbolData[b.symbol]?.previousClose || 0);
        return changeA - changeB;
      },
    },
    {
      title: 'Change(%)',
      key: 'changePercent',
      render: (_, record) => {
        const price = symbolData[record.symbol]?.price;
        const previousClose = symbolData[record.symbol]?.previousClose;
        if (price === undefined || previousClose === undefined) {
          console.warn(`Missing price or previousClose for symbol: ${record.symbol}`);
          return '-';
        }
        const changePercent = ((price - previousClose) / previousClose) * 100;
        return (
          <span style={{ color: changePercent >= 0 ? 'green' : 'red' }}>
            {changePercent.toFixed(2)}%
          </span>
        );
      },
      sorter: (a, b) => {
        const changePercentA = ((symbolData[a.symbol]?.price || 0) - (symbolData[a.symbol]?.previousClose || 0)) / (symbolData[a.symbol]?.previousClose || 1) * 100;
        const changePercentB = ((symbolData[b.symbol]?.price || 0) - (symbolData[b.symbol]?.previousClose || 0)) / (symbolData[b.symbol]?.previousClose || 1) * 100;
        return changePercentA - changePercentB;
      },
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Button
          onClick={() => handleRemoveSymbol(record.symbol)}
          danger
          icon={<DeleteOutlined />}
          size="small"
        />
      ),
    },
  ];

  if (!watchlist) {
    return <Spin size="large" />;
  }

  return (
    <Table
      columns={columns}
      dataSource={watchlist.symbols}
      rowKey="symbol"
      pagination={false}
      loading={loading}
      scroll={{ y: 'calc(100vh - 400px)', x: true }}
    />
  );
};

export default WatchlistDetail;
