import { Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';
import { useTheme } from '../../contexts/ThemeContext';

const { Title } = Typography;

const PlotlySunburstChart = ({ sunburstData }) => {
  const { isDarkMode, theme } = useTheme();

  const processedData = useMemo(() => {
    if (!sunburstData) return null;

    const totalValue = sunburstData.values[0];
    const percentages = sunburstData.values.map(value => (value / totalValue) * 100);

    return {
      ...sunburstData,
      percentages,
    };
  }, [sunburstData]);

  if (!processedData) {
    return <div>Loading chart data...</div>;
  }

  const layout = {
    sunburstcolorway: isDarkMode
      ? ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#a4de6c', '#d0ed57', '#ffa726', '#66bb6a', '#26a69a', '#42a5f5']
      : ['#636efa', '#ef553b', '#00cc96', '#ab63fa', '#ffa15a', '#19d3f3', '#ff6692', '#b6e880', '#ff97ff', '#fecb52'],
    margin: { l: 0, r: 0, b: 0, t: 0 },
    sunburstclickmode: 'zoom',
    hovermode: 'closest',
    paper_bgcolor: theme.token.colorBgContainer,
    font: { color: theme.token.colorText },
    autosize: true,
  };

  const config = {
    displayModeBar: false,
    responsive: true,
  };

  return (
    <div style={{ padding: '20px', borderRadius: '8px', backgroundColor: theme.token.colorBgContainer }}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Plot
          data={[{
            type: 'sunburst',
            labels: processedData.labels,
            parents: processedData.parents,
            values: processedData.values,
            ids: processedData.ids,
            text: processedData.percentages.map(p => p.toFixed(2) + '%'),
            textinfo: 'label+text',
            hovertemplate: '<b>%{label}</b><br>Value: $%{value:,.2f}<br>Percentage: %{text}<extra></extra>',
            insidetextorientation: 'radial',
            sort: true,
            branchvalues: 'total',
            leaf: { opacity: 0.8 },
            marker: { line: { width: 1 } },
          }]}
          layout={layout}
          config={config}
          style={{ width: '100%', height: '600px' }}
          SunburstChart
        />
      </Space>
    </div>
  );
};

export default PlotlySunburstChart;
