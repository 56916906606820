import React, { createContext, useState, useContext } from 'react';

const CurrentWatchlistContext = createContext();

export const CurrentWatchlistProvider = ({ children }) => {
  const [currentWatchlist, setCurrentWatchlist] = useState(null);

  return (
    <CurrentWatchlistContext.Provider value={{ currentWatchlist, setCurrentWatchlist }}>
      {children}
    </CurrentWatchlistContext.Provider>
  );
};

export const useCurrentWatchlist = () => useContext(CurrentWatchlistContext);

export default CurrentWatchlistContext;
