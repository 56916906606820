import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { InfoCircleOutlined, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Avatar, Card, List, Tooltip, Typography, Skeleton, Radio } from 'antd';
import styled from 'styled-components';
import { getKeyPeople } from '../../services/api';
import KeyPeopleTable from './KeyPeopleTable';
import { formatLargeNumber } from '../../utils/formatters';

const { Text } = Typography;

const StyledCard = styled(Card)`
  transition: opacity 0.3s ease-in-out;
`;

const StatusBar = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const StatusIcon = styled.span`
  font-size: 24px;
  margin-right: 12px;
`;

const StatusText = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const KeyPeople = ({ ticker }) => {
  const [keyPeople, setKeyPeople] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responseInfo, setResponseInfo] = useState('');
  const [isLLMGenerating, setIsLLMGenerating] = useState(false);
  const [viewMode, setViewMode] = useState('card');
  const pollingIntervalRef = useRef(null);

  const fetchKeyPeople = useCallback(async () => {
    try {
      const keyPeopleResponse = await getKeyPeople(ticker);
      setKeyPeople(keyPeopleResponse.data.response);
      setResponseInfo(keyPeopleResponse.data.info);
      setIsLLMGenerating(keyPeopleResponse.data.isLLMGenerating);

      if (!keyPeopleResponse.data.isLLMGenerating) {
        clearInterval(pollingIntervalRef.current);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching key people data:', error);
      setError('Failed to fetch key people data. Please try again later.');
      clearInterval(pollingIntervalRef.current);
      setLoading(false);
    }
  }, [ticker]);

  useEffect(() => {
    const startPolling = () => {
      setLoading(true);
      setError(null);
      setIsLLMGenerating(true);

      fetchKeyPeople();
      pollingIntervalRef.current = setInterval(fetchKeyPeople, 2000);
    };

    if (ticker) {
      startPolling();
    } else {
      console.log('No ticker provided');
      setError('No ticker provided. Please select a company.');
      setLoading(false);
    }

    return () => {
      clearInterval(pollingIntervalRef.current);
    };
  }, [ticker, fetchKeyPeople]);

  const SkeletonLayout = useMemo(() => (
    <StyledCard>
      <List
        grid={{ gutter: 16, column: 2 }}
        dataSource={[...Array(4)].map((_, index) => ({ key: index }))}
        renderItem={(item) => (
          <List.Item key={item.key}>
            <Card>
              <Skeleton avatar active paragraph={{ rows: 4 }} />
            </Card>
          </List.Item>
        )}
      />
    </StyledCard>
  ), []);

  const renderStatusBar = () => {
    if (!isLLMGenerating) return null;

    return (
      <StatusBar bgColor="#e6f7ff" textColor="#1890ff">
        <StatusIcon><LoadingOutlined /></StatusIcon>
        <StatusText>{responseInfo || 'Analyzing key people...'}</StatusText>
      </StatusBar>
    );
  };

  const renderCardView = () => (
    <List
      grid={{ gutter: 16, column: 2 }}
      dataSource={keyPeople}
      renderItem={(person) => (
        <List.Item key={person.name}>
          <Card>
            <Card.Meta
              avatar={<Avatar size={64}>{person.name[0]}</Avatar>}
              title={person.name}
              description={
                <>
                  <Text strong>{person.title}</Text>
                  <br />
                  {person.pay && (
                    <Text>
                      Pay: {formatLargeNumber(person.pay, person.currencyPay)}
                    </Text>
                  )}
                  <br />
                  <Text>Born: {person.yearBorn || 'N/A'}</Text>
                  <br />
                  {person.importanceAI && (
                    <Text type="secondary">
                      {person.importanceAI}
                      <Tooltip title="AI generated">
                        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </Text>
                  )}
                </>
              }
            />
          </Card>
        </List.Item>
      )}
    />
  );

  if (error) {
    return (
      <StatusBar bgColor="#fff2f0" textColor="#ff4d4f">
        <StatusIcon><InfoCircleOutlined /></StatusIcon>
        <StatusText>{error}</StatusText>
      </StatusBar>
    );
  }

  return (
    <>
      <Radio.Group
        onChange={(e) => setViewMode(e.target.value)}
        value={viewMode}
        style={{ marginBottom: '16px' }}
      >
        <Radio.Button value="card">Card View</Radio.Button>
        <Radio.Button value="table">Table View</Radio.Button>
      </Radio.Group>

      {renderStatusBar()}
      
      {loading && keyPeople.length === 0 ? (
        SkeletonLayout
      ) : (
        <StyledCard>
          {viewMode === 'card' ? renderCardView() : <KeyPeopleTable keyPeople={keyPeople} />}
        </StyledCard>
      )}
    </>
  );
};

export default KeyPeople;
