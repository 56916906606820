import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import WebSocketManager from '../../utils/WebSocketManager';
import { getCurrencySymbol } from '../../utils/currencyMapping';
import './StockPrice.css';

const StockPrice = ({ symbol, changePercent = false, size = 'medium' }) => {
  const { isDarkMode } = useTheme();
  
  const [price, setPrice] = useState(null);
  const [prevClose, setPrevClose] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [percentChange, setPercentChange] = useState(null);

  const handleQuoteUpdate = useCallback((updatedSymbol, data) => {
    if (updatedSymbol !== symbol) return;

    if ('price' in data) {
      setPrice(data.price);
    }

    // Full quote update
    if ('previousClose' in data) {
      setPrevClose(data.previousClose);
      setCurrencySymbol(getCurrencySymbol(data.exchange));
      
      // Update percent change if we have both price and previousClose
      if (data.price && data.previousClose) {
        const change = ((data.price - data.previousClose) / data.previousClose) * 100;
        setPercentChange(change.toFixed(2));
      }
    }

    // Price update
    if ('price' in data && prevClose) {
      const change = ((data.price - prevClose) / prevClose) * 100;
      setPercentChange(change.toFixed(2));
    }
  }, [symbol, prevClose]);

  useEffect(() => {
    WebSocketManager.subscribe(symbol, handleQuoteUpdate);

    return () => {
      WebSocketManager.unsubscribe(symbol, handleQuoteUpdate);
    };
  }, [symbol, handleQuoteUpdate]);

  const getColorClass = () => {
    if (percentChange === null) return '';
    if (Math.abs(percentChange) < 0.01) return 'change-color-0';
    const absChange = Math.abs(parseFloat(percentChange));
    const colorIndex = Math.min(Math.ceil(absChange), 10);
    const colorClass = `change-color-${percentChange >= 0 ? '' : '-'}${colorIndex}`;
    return colorClass;
  };

  const renderValue = () => {
    if (changePercent) {
      return percentChange !== null ? `${percentChange > 0 ? '+' : ''}${percentChange}%` : '...';
    }
    return price !== null ? `${currencySymbol}${price.toFixed(2)}` : '...';
  };

  const colorClass = getColorClass();
  const value = renderValue();

  const sizeClass = `stock-price-${size}`;

  return (
    <div className={`stock-price ${sizeClass}`}>
      {size !== 'small' && (
        <div className="stock-price-title">{changePercent ? "Percent Change" : "Current Price"}</div>
      )}
      <div className={`stock-price-value ${isDarkMode ? "dark-theme" : ""} ${colorClass}`}>{value}</div>
    </div>
  );
};

StockPrice.propTypes = {
  symbol: PropTypes.string.isRequired,
  changePercent: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default StockPrice;
