import React, { createContext, useContext, useState, useEffect } from 'react';
import { getETFHoldings } from '../services/api';

const ETFHoldingsContext = createContext();

export const useETFHoldings = () => useContext(ETFHoldingsContext);

export const ETFHoldingsProvider = ({ children }) => {
  const [holdings, setHoldings] = useState([]);
  const [sunburstData, setSunburstData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedClassifications, setSelectedClassifications] = useState([]);

  const fetchHoldings = async (symbol) => {
    if (selectedDate && symbol) {
      try {
        console.log('Fetching holdings...', selectedClassifications);
        const response = await getETFHoldings(symbol, selectedDate, selectedClassifications);
        console.log('API response:', response);
        setHoldings(response.holdings);
        setSunburstData(response.sunburst_data);
      } catch (error) {
        console.error('Error fetching ETF holdings:', error);
      }
    } else {
      console.log('Not fetching: symbol or selectedDate is missing');
    }
  };

  return (
    <ETFHoldingsContext.Provider
      value={{
        holdings,
        setHoldings,
        sunburstData,
        setSunburstData,
        selectedDate,
        setSelectedDate,
        selectedClassifications,
        setSelectedClassifications,
        fetchHoldings,
      }}
    >
      {children}
    </ETFHoldingsContext.Provider>
  );
};
