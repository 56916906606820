import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, message, Space, Tag } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useStockScreen } from '../../contexts/StockScreenContext';
import { useTheme } from '../../contexts/ThemeContext';
import CriterionModal from './CriterionModal';

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 16px;
`;

const largeTagStyle = {
  fontSize: '14px',
  padding: '6px 12px',
  height: 'auto',
  lineHeight: '1.5',
  cursor: 'pointer',
};

const ScreenCriteriaPanel = ({ screenId, onCriteriaChange }) => {
  console.log('ScreenCriteriaPanel rendered', { screenId });

  const { theme } = useTheme();
  const { getScreenById, updateScreen, getAccumulatedResultCount, getFieldOptions } = useStockScreen();
  const [criteria, setCriteria] = useState([]);
  const [criteriaResultCounts, setCriteriaResultCounts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCriterionId, setEditingCriterionId] = useState(null);
  const [usedFields, setUsedFields] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [allFields, setAllFields] = useState([]);
  const criteriaRef = useRef(criteria);
  const resultCountUpdating = useRef(false);

  const fetchScreenData = useCallback(async () => {
    console.log('fetchScreenData called');
    setIsLoading(true);
    try {
      console.log('Fetching screen data for screenId:', screenId);
      const screen = await getScreenById(screenId);
      console.log('Received screen data:', screen);
      
      if (screen) {
        console.log('Setting criteria:', screen.criteria || []);
        setCriteria(screen.criteria || []);
        // Initialize result counts with existing values or null
        setCriteriaResultCounts(prevCounts => {
          const newCounts = new Array(screen.criteria.length).fill(null);
          return newCounts;
        });
        setUsedFields(new Set(screen.criteria.map(c => c.field)));
        onCriteriaChange(screen.criteria || []);
      } else {
        console.warn('No screen data received for screenId:', screenId);
      }

      console.log('Fetching field options');
      const numericFields = getFieldOptions('numeric');
      const booleanFields = getFieldOptions('boolean');
      const stringFields = getFieldOptions('string');
      
      console.log('Field options:', { numericFields, booleanFields, stringFields });
      setAllFields([...numericFields.fields, ...booleanFields.fields, ...stringFields.fields]);
    } catch (error) {
      console.error('Error in fetchScreenData:', error);
      console.error('Error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
      });
      message.error('Failed to fetch screen data');
    } finally {
      setIsLoading(false);
    }
  }, [screenId, getScreenById, getFieldOptions, onCriteriaChange]);

  useEffect(() => {
    console.log('fetchScreenData effect triggered');
    fetchScreenData();
  }, [fetchScreenData]);

  useEffect(() => {
    criteriaRef.current = criteria;
  }, [criteria]);

  const updateResultCount = useCallback(async () => {
    if (resultCountUpdating.current) return;
    resultCountUpdating.current = true;
    console.log('updateResultCount called');
    try {
      const updatedResultCounts = await Promise.all(
        criteriaRef.current.map(async (criterion, index) => {
          if (criteriaResultCounts[index] !== null) {
            return criteriaResultCounts[index];
          }
          const result = await getAccumulatedResultCount(screenId, index);
          console.log(`Result count for criterion ${index}:`, result);
          return result;
        })
      );
      console.log('Updated result counts:', updatedResultCounts);
      setCriteriaResultCounts(updatedResultCounts);
    } catch (error) {
      console.error('Error updating result counts:', error);
      message.error('Failed to update result counts');
    } finally {
      resultCountUpdating.current = false;
    }
  }, [screenId, getAccumulatedResultCount, criteria]);

  const debouncedUpdateResultCount = useCallback(
    debounce(() => {
      if (!resultCountUpdating.current) {
        updateResultCount();
      }
    }, 1000),
    [updateResultCount]
  );

  useEffect(() => {
    if (criteria.length > 0) {
      debouncedUpdateResultCount();
    }
    return () => debouncedUpdateResultCount.cancel();
  }, [criteria, debouncedUpdateResultCount]);

  const handleAddCriterion = () => {
    console.log('handleAddCriterion called');
    if (usedFields.size >= allFields.length) {
      message.error('All available fields have been used. Please edit or remove existing criteria.');
      return;
    }
    setEditingCriterionId(null);
    setIsModalVisible(true);
  };

  const handleEditCriterion = (criterionId) => {
    console.log('handleEditCriterion called', criterionId);
    setEditingCriterionId(criterionId);
    setIsModalVisible(true);
  };

  const handleRemoveCriterion = async (criterionId) => {
    console.log('handleRemoveCriterion called', criterionId);
    try {
      const updatedCriteria = criteriaRef.current.filter(c => c.id !== criterionId);
      await updateScreen(screenId, { criteria: updatedCriteria });
      setCriteria(updatedCriteria);
      setCriteriaResultCounts(prevCounts => {
        const index = criteriaRef.current.findIndex(criterion => criterion.id === criterionId);
        if (index === -1) return prevCounts; // If ID is not found, return the original array
      
        return prevCounts.slice(0, index).concat(Array(prevCounts.length - index - 1).fill(null));
      });
      setUsedFields(new Set(updatedCriteria.map(c => c.field)));
      onCriteriaChange(updatedCriteria);
      message.success('Criterion removed successfully');
    } catch (error) {
      console.error('Error removing criterion:', error);
      message.error('Failed to remove criterion');
    }
  };

  const handleSaveCriterion = async (criterionData) => {
    console.log('handleSaveCriterion called', criterionData);
    try {
      let updatedCriteria;
      if (editingCriterionId) {
        updatedCriteria = criteriaRef.current.map(c => 
          c.id === editingCriterionId ? { ...c, ...criterionData } : c
        );
        setCriteriaResultCounts(prevCounts => {
          const index = criteriaRef.current.findIndex(criterion => criterion.id === editingCriterionId);
          if (index === -1) return prevCounts; // If ID is not found, return the original array
        
          return prevCounts.map((count, i) => i < index ? count : null);
        });
      } else {
        const newCriterion = { ...criterionData, id: uuidv4() };
        updatedCriteria = [...criteriaRef.current, newCriterion];
        setCriteriaResultCounts(prevCounts => [...prevCounts, null]);
      }
      await updateScreen(screenId, { criteria: updatedCriteria });
      setCriteria(updatedCriteria);
      setUsedFields(new Set(updatedCriteria.map(c => c.field)));
      onCriteriaChange(updatedCriteria);
      setIsModalVisible(false);
      message.success(`Criterion ${editingCriterionId ? 'updated' : 'added'} successfully`);
    } catch (error) {
      console.error('Error saving criterion:', error);
      message.error('Failed to save criterion');
    }
  };

  const formatLargeNumber = (number) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(1) + 'T';
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + 'B';
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + 'K';
    }
    return number.toString();
  };

  const renderCriterionSummary = useCallback((criterion) => {
    switch (criterion.type) {
      case 'numeric':
        return `${criterion.field} ${criterion.min ? '>' + formatLargeNumber(criterion.min) : ''} ${criterion.min && criterion.max ? 'and' : ''} ${criterion.max ? '<' + formatLargeNumber(criterion.max) : ''}`;
      case 'boolean':
        return `${criterion.field} is ${criterion.value ? 'Yes' : 'No'}`;
      case 'string':
        return `${criterion.field} in [${criterion.values.join(', ')}]`;
      default:
        return '';
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log('Rendering criteria:', criteria);

  return (
    <div>
      <ChipContainer>
        {criteria.map((criterion, index) => (
          <Tag
            key={criterion.id}
            closable
            onClose={(e) => {
              e.preventDefault();
              handleRemoveCriterion(criterion.id);
            }}
            onClick={() => handleEditCriterion(criterion.id)}
            color={criteriaResultCounts[index] === null ? 'warning' : 'processing'}
            icon={criteriaResultCounts[index] === null ? <SyncOutlined spin /> : null}
            style={{
              ...largeTagStyle,
              // Add any additional custom styles here
            }}
          >
            {renderCriterionSummary(criterion)} ({criteriaResultCounts[index] === null ? '...' : (criteriaResultCounts[index] === 1000 ? '1000+' : criteriaResultCounts[index])})
          </Tag>
        ))}
      </ChipContainer>
      <Space>
        <Button icon={<PlusOutlined />} onClick={handleAddCriterion}>
          Add Criterion
        </Button>
      </Space>
      <CriterionModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSave={handleSaveCriterion}
        criterion={editingCriterionId ? criteriaRef.current.find(c => c.id === editingCriterionId) : null}
        usedFields={usedFields}
        allFields={allFields}
      />
    </div>
  );
};

export default ScreenCriteriaPanel;
