import { Card, Col, Radio, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getQuarterlyEstimatesAndActuals, getYearlyEstimatesAndActuals } from '../../services/api';


const estimateTypes = [
  { key: 'eps', label: 'EPS', useCurrency: false },
  { key: 'revenue', label: 'Revenue', useCurrency: true },
  { key: 'ebitda', label: 'EBITDA', useCurrency: true },
  { key: 'ebit', label: 'EBIT', useCurrency: true },
  { key: 'netIncome', label: 'Net Income', useCurrency: true },
  { key: 'sgaExpense', label: 'SGA Expense', useCurrency: true },
];

const RevenueEPSCharts = ({ symbol, currencySymbol }) => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [frequency, setFrequency] = useState('quarterly');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = frequency === 'yearly'
          ? await getYearlyEstimatesAndActuals(symbol)
          : await getQuarterlyEstimatesAndActuals(symbol);
        const filteredData = filterAndSortData(response.data);
        setChartData(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [symbol, frequency]);

  const filterAndSortData = (data) => {
    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
    const currentDate = new Date();
    const pastData = sortedData.filter(item => new Date(item.date) <= currentDate).slice(-6);
    const futureData = sortedData.filter(item => new Date(item.date) > currentDate).slice(0, 4);
    return [...pastData, ...futureData];
  };

  const formatLargeNumber = (num) => {
    if (num === null || num === undefined) return 'N/A';
    const absNum = Math.abs(num);
    const sign = num < 0 ? '-' : '';
    if (absNum >= 1e9) return sign + (absNum / 1e9).toFixed(1) + 'B';
    if (absNum >= 1e6) return sign + (absNum / 1e6).toFixed(1) + 'M';
    if (absNum >= 1e3) return sign + (absNum / 1e3).toFixed(1) + 'K';
    return sign + absNum.toFixed(2);
  };

  const createChartData = (data, dataKey) => ({
    labels: data.map(item => `${item.calendarYear} ${item.period}`),
    datasets: [
      {
        label: 'Actual',
        data: data.map(item => item[dataKey]?.actual),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Estimate',
        data: data.map(item => item[dataKey]?.estimate),
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return `${label}: ${currencySymbol}${formatLargeNumber(value)}`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => currencySymbol + formatLargeNumber(value)
        }
      },
    },
    animation: {
      duration: 0
    },
    transitions: {
      active: {
        animation: {
          duration: 0
        }
      }
    }
  };

  const ChartCard = ({ title, data, dataKey, useCurrency }) => (
    <Card title={title} style={{ marginBottom: '20px' }}>
      <div style={{ height: '300px' }}>
        <Bar options={{
          ...options,
          scales: {
            ...options.scales,
            y: {
              ...options.scales.y,
              ticks: {
                callback: (value) => useCurrency ? currencySymbol + formatLargeNumber(value) : formatLargeNumber(value)
              }
            }
          }
        }} data={createChartData(data, dataKey)} />
      </div>
    </Card>
  );

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <>
      <Row style={{ marginBottom: '20px' }}>
        <Col>
          <Radio.Group
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
          >
            <Radio.Button value="quarterly">Quarterly</Radio.Button>
            <Radio.Button value="yearly">Yearly</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {estimateTypes.map((type, index) => (
          <Col span={12} key={type.key}>
            <ChartCard 
              title={type.label} 
              data={chartData} 
              dataKey={type.key}
              useCurrency={type.useCurrency}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default RevenueEPSCharts;
