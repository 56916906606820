import { Divider, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useETFHoldings } from '../../contexts/ETFHoldingsContext';
import { getETFPortfolioDates } from '../../services/api';

const PortfolioDateSelector = ({ symbol }) => {
  const { setSelectedDate } = useETFHoldings();
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const response = await getETFPortfolioDates(symbol);
        setDates(response);
        if (response.length > 0) {
          setSelectedDate(response[0].date);
        }
      } catch (error) {
        console.error('Error fetching ETF portfolio dates:', error);
        // TODO: Handle error (e.g., show error message to user)
      }
    };

    fetchDates();
  }, [symbol, setSelectedDate]);

  return (

    <div>
      <b>As of:</b>
      <Divider type="vertical" />
      <Select
        style={{ width: 200 }}
        placeholder='Select portfolio date'
        onChange={(value) => setSelectedDate(value)}
      >
        {dates.map((date) => (
          <Select.Option key={date.date} value={date.date}>
            {date.date}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default PortfolioDateSelector;

