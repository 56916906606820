import React from 'react';
import { Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { formatLargeNumber } from '../../utils/formatters';

const KeyPeopleTable = ({ keyPeople }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Pay',
      dataIndex: 'pay',
      key: 'pay',
      render: (pay, record) => pay ? formatLargeNumber(pay, record.currencyPay) : 'N/A',
    },
    {
      title: 'Year Born',
      dataIndex: 'yearBorn',
      key: 'yearBorn',
      render: (yearBorn) => yearBorn || 'N/A',
    },
    {
      title: 'Importance (AI)',
      dataIndex: 'importanceAI',
      key: 'importanceAI',
      render: (importanceAI) => (
        importanceAI ? (
          <span>
            {importanceAI}
            <Tooltip title="AI generated">
              <InfoCircleOutlined style={{ marginLeft: '5px' }} />
            </Tooltip>
          </span>
        ) : 'N/A'
      ),
    },
  ];

  return (
    <Table
      dataSource={keyPeople}
      columns={columns}
      rowKey="name"
      pagination={false}
    />
  );
};

export default KeyPeopleTable;
