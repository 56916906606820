import React from 'react';
import Plot from 'react-plotly.js';
import { useTheme } from '../../contexts/ThemeContext';

const formatValue = (value, currencySymbol) => {
  if (value === undefined || value === null) {
    return 'N/A';
  }
  const absValue = Math.abs(value);
  let formattedValue;
  if (absValue >= 1e12) {
    formattedValue = (value / 1e12).toFixed(1) + 'T';
  } else if (absValue >= 1e9) {
    formattedValue = (value / 1e9).toFixed(1) + 'B';
  } else if (absValue >= 1e6) {
    formattedValue = (value / 1e6).toFixed(1) + 'M';
  } else if (absValue >= 1e3) {
    formattedValue = (value / 1e3).toFixed(1) + 'K';
  } else {
    formattedValue = value.toFixed(2);
  }
  return currencySymbol + formattedValue;
};

const SankeyChart = ({ data, tabKey, currencySymbol }) => {
  const { isDarkMode, theme } = useTheme();

  const nodeHoverTemplate = 
    "<b>%{label}</b><br>" +
    "Total: %{customdata}<br>" +
    "<extra></extra>";

  const linkHoverTemplate = 
    "<b>%{source.label} → %{target.label}</b><br>" +
    "Amount: %{customdata}<br>" +
    "<extra></extra>";

  const layout = {
    title: tabKey === 'income_statement' ? 'Income Statement Flow' : 'Balance Sheet Structure',
    font: { 
      size: 12,
      color: theme.token.colorText
    },
    width: 1000,
    height: 600,
    paper_bgcolor: theme.token.colorBgContainer,
    plot_bgcolor: theme.token.colorBgContainer,
    sankey: {
      node: {
        pad: 20,
        thickness: 20,
        line: {
          color: theme.token.colorText,
          width: 0.5
        },
        label: data.nodes.map(node => node.name || 'Unknown'),
        color: data.nodes.map(node => node.color || '#888888'),
        hovertemplate: nodeHoverTemplate,
        customdata: data.nodes.map(node => formatValue(node.value, currencySymbol))
      },
      link: {
        source: data.links.map(link => link.source),
        target: data.links.map(link => link.target),
        value: data.links.map(link => link.value || 0),
        color: data.links.map(() => `rgba(${isDarkMode ? '255,255,255' : '0,0,0'},0.2)`),
        hovertemplate: linkHoverTemplate,
        customdata: data.links.map(link => formatValue(link.value, currencySymbol))
      }
    }
  };

  return (
    <Plot
      data={[{
        type: "sankey",
        orientation: "h",
        node: layout.sankey.node,
        link: layout.sankey.link,
        arrangement: "snap",
        textfont: { color: theme.token.colorText }
      }]}
      layout={layout}
      config={{ responsive: true }}
    />
  );
};

export default React.memo(SankeyChart);
