import { Radio, Space } from 'antd';
import React from 'react';
import { timeRanges } from './constants';

const ControlPanel = ({ viewMode, setViewMode, frequencyMode, setFrequencyMode, timeRange, setTimeRange }) => {
  return (
    <Space style={{ width: '100%' }} size="middle">
      <Radio.Group value={viewMode} onChange={(e) => setViewMode(e.target.value)} >
        <Radio.Button value="chart">Chart</Radio.Button>
        <Radio.Button value="table">Table</Radio.Button>
      </Radio.Group>
      <Radio.Group value={frequencyMode} onChange={(e) => setFrequencyMode(e.target.value)}>
        <Radio.Button value="quarterly">Quarterly</Radio.Button>
        <Radio.Button value="yearly">Yearly</Radio.Button>
      </Radio.Group>
      <Radio.Group value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
        {timeRanges.map(range => (
          <Radio.Button key={range.key} value={range.key}>
            {range.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Space>
  );
};

export default ControlPanel;
