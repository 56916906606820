import axios from 'axios';
import { auth } from '../firebase';
import config from './config';

const api = axios.create({
  baseURL: config.API_BASE_URL,
});

let cachedToken = null;
let tokenExpirationTime = null;

const getToken = async () => {
  const currentTime = Date.now();
  if (cachedToken && tokenExpirationTime && currentTime < tokenExpirationTime) {
    return cachedToken;
  }

  const user = auth.currentUser;
  if (user) {
    cachedToken = await user.getIdToken(true);
    // Set token expiration to 55 minutes from now (Firebase tokens typically last 1 hour)
    tokenExpirationTime = currentTime + 55 * 60 * 1000;
    return cachedToken;
  }
  return null;
};

api.interceptors.request.use(async (config) => {
  console.log('Request', config);
  const token = await getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getFinancials = (ticker, endpoint, period) => {
  return api.get(`/financials/${ticker}/${endpoint}?period=${period}`);
};

export const getSupplyChain = (ticker) => {
  return api.get(`/supply-chain/${ticker}`);
};

export const getAnalystProfile = (analystName) => {
  return api.get(`/price-targets/analyst-profile/${encodeURIComponent(analystName)}`);
};

export const getPriceTargets = (ticker) => {
  return api.get(`/price-targets/${ticker}`);
};

export const getAnalysts = (ticker) => {
  return api.get(`/analysts/${ticker}`);
};

export const getNews = (params) => {
  return api.get('/news', { params });
};

export const getKeyPeople = (ticker) => {
  return api.get(`/company/${ticker}/key-people`);
};

export const getEmployeeCount = (ticker) => {
  return api.get(`/company/${ticker}/employee-count`);
};

export const getCompanyInfo = (ticker) => {
  return api.get(`/company/${ticker}`);
};

export const getUserProfile = () => {
  return api.get('/user/profile');
};

export const updateUserProfile = newProfile => {
  return api.put('/user/update', newProfile);
};

export const getHistoricalData = (ticker, range) => {
  return api.get(`/historical_data/${ticker}?range=${range.toLowerCase()}`);
};

export const getSearchResults = (query) => {
  return api.get(`/search/${query}`);
}; 

export const markNewsAsRead = (articleInfo) => {
  return api.post('/news/mark-read', articleInfo);
};

export const getMarkets = () => {
  return api.get('/markets');
};

// Updated Watchlist API methods
export const getWatchlists = () => api.get('/watchlists');
export const createWatchlist = (name, symbols = []) => api.post('/watchlists', { name, symbols });
export const updateWatchlist = (id, data) => api.put('/watchlists', { id, ...data });
export const deleteWatchlist = (id) => api.delete(`/watchlists/${id}`);
export const addSymbolToWatchlist = (watchlistId, symbol) => api.post(`/watchlists/${watchlistId}/symbols`, { symbol });
export const removeSymbolFromWatchlist = (watchlistId, symbol) => api.delete(`/watchlists/${watchlistId}/symbols/${symbol}`);
export const generateAIWatchlist = (id, llm_prompt) => api.put('/watchlists', { id, llm_prompt });

// Updated Stock Screener API calls
export const getStockScreens = async () => {
  const response = await api.get('/stock-screener/stock-screens');
  return response.data;
};

export const createStockScreen = async (name) => {
  const response = await api.post('/stock-screener/stock-screens', { name });
  return response.data;
};

export const updateStockScreen = async (screenId, updateData) => {
  const response = await api.put(`/stock-screener/stock-screens/${screenId}`, updateData);
  return response.data;
};

export const deleteStockScreen = async (screenId) => {
  await api.delete(`/stock-screener/stock-screens/${screenId}`);
};

export const getFieldOptions = async (field) => {
  const response = await api.get(`/stock-screener/options/${field}`);
  return response.data;
};

export const applyStockScreen = async (screenId) => {
  const response = await api.post(`/stock-screener/stock-screens/${screenId}/apply`);
  return response.data;
};

export const getAccumulatedResultCount = async (screenId, criteriaIndex) => {
  const response = await api.get(`/stock-screener/stock-screens/${screenId}/result_count/${criteriaIndex}`);
  return response.data;
};

// ETF Holdings API calls
export const getETFPortfolioDates = async (symbol) => {
  const response = await api.get(`/etf/${symbol}/portfolio-dates`);
  return response.data;
};

export const getETFHoldings = async (symbol, date, classifications) => {
  const params = { date };
  if (classifications && classifications.length > 0) {
    params.classifications = classifications.join(',');
  } else {
    params.classifications = '';
  }
  const response = await api.get(`/etf/${symbol}/holdings`, { params });
  return response.data;
};

export const getQuarterlyEstimatesAndActuals = async (symbol) => {
  const response = await api.get(`/analysts/quarterly_estimates_and_actuals/${symbol}`);
  return response.data;
};


export const getCompanyAISummary = async (symbol) => {
  try {
    const response = await api.get(`/company/${symbol}/ai-summary`);
    return response.data;
  } catch (error) {
    console.error('Error fetching company AI summary:', error);
    throw error;
  }
};

export const getYearlyEstimatesAndActuals = async (symbol) => {
  try {
    const response = await api.get(`/analysts/yearly_estimates_and_actuals/${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching yearly estimates and actuals:', error);
    throw error;
  }
};

// Earnings Transcript API calls
export const getAvailableTranscripts = async (symbol) => {
  try {
    const response = await api.get(`/earnings-transcript/available/${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching available transcripts:', error);
    throw error;
  }
};

export const getTranscriptContent = async (symbol, year, quarter) => {
  try {
    const response = await api.get(`/earnings-transcript/content/${symbol}`, {
      params: { year, quarter },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching transcript content:', error);
    throw error;
  }
};

export const getEconomicCalendar = async (params) => {
  try {
    const response = await api.get('/economic-calendar', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching economic calendar data:', error);
    throw error;
  }
};

export const getEarningsCalendar = async (params) => {
  try {
    const response = await api.get('/earnings-calendar', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching earnings calendar data:', error);
    throw error;
  }
};
