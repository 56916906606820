import { Col, Divider, Row } from 'antd';
import React, { useEffect, useRef } from 'react';
import { ETFHoldingsProvider, useETFHoldings } from '../../contexts/ETFHoldingsContext';
import ClassificationTags from './ClassificationTags';
import HoldingsDataTable from './HoldingsDataTable';
import PlotlySunburstChart from './PlotlySunburstChart';
import PortfolioDateSelector from './PortfolioDateSelector';

const ETFHoldingsContent = ({ symbol }) => {
  const {
    selectedDate,
    selectedClassifications,
    fetchHoldings,
    sunburstData
  } = useETFHoldings();

  const prevSymbolRef = useRef();
  const prevDateRef = useRef();
  const prevClassificationsRef = useRef();

  useEffect(() => {
    if (
      symbol &&
      selectedDate &&
      (symbol !== prevSymbolRef.current ||
        selectedDate !== prevDateRef.current ||
        selectedClassifications !== prevClassificationsRef.current)
    ) {
      console.log('Fetching holdings...');
      fetchHoldings(symbol);

      prevSymbolRef.current = symbol;
      prevDateRef.current = selectedDate;
      prevClassificationsRef.current = selectedClassifications;
    }
  }, [symbol, selectedDate, selectedClassifications, fetchHoldings]);

  return (
    <div className='etf-holdings'>
      <Row justify="space-between">
        <ClassificationTags />
        <PortfolioDateSelector symbol={symbol} />
      </Row>
      <Divider />
      <Row>
        <Col span={12}>{sunburstData && <PlotlySunburstChart sunburstData={sunburstData} />}</Col>
        <Col span={12}><HoldingsDataTable /></Col>
      </Row>
    </div>
  );
};

const ETFHoldings = ({ symbol }) => {
  return (
    <ETFHoldingsProvider>
      <ETFHoldingsContent symbol={symbol} />
    </ETFHoldingsProvider>
  );
};

export default ETFHoldings;
