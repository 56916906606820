import React from 'react';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const EstimateTypeTabs = ({ estimateType, setEstimateType, estimateTypes }) => {
  return (
    <Tabs activeKey={estimateType} onChange={setEstimateType}>
      {estimateTypes.map(et => (
        <TabPane tab={et.label} key={et.key} />
      ))}
    </Tabs>
  );
};

export default EstimateTypeTabs;
