import React, { createContext, useState, useContext } from 'react';

const WatchlistContext = createContext();

export const WatchlistProvider = ({ children }) => {
  const [watchlists, setWatchlists] = useState([]);

  return (
    <WatchlistContext.Provider value={{ watchlists, setWatchlists }}>
      {children}
    </WatchlistContext.Provider>
  );
};

export const useWatchlist = () => useContext(WatchlistContext);

export default WatchlistContext;
