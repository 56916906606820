const createMetric = (value, label, children = []) => ({ value, label, children });

export const getAnalystEstimatesMetrics = () => [
  createMetric('eps', 'EPS', [
    createMetric('epsEstimate', 'Estimate'),
    createMetric('epsActual', 'Actual'),
    createMetric('epsEstimateHigh', 'Estimate High'),
    createMetric('epsEstimateLow', 'Estimate Low'),
    createMetric('epsNumberAnalysts', 'Number of Analysts')
  ]),
  createMetric('revenue', 'Revenue', [
    createMetric('revenueEstimate', 'Estimate'),
    createMetric('revenueActual', 'Actual'),
    createMetric('revenueEstimateHigh', 'Estimate High'),
    createMetric('revenueEstimateLow', 'Estimate Low'),
    createMetric('revenueNumberAnalysts', 'Number of Analysts')
  ]),
  createMetric('ebitda', 'EBITDA', [
    createMetric('ebitdaEstimate', 'Estimate'),
    createMetric('ebitdaActual', 'Actual'),
    createMetric('ebitdaEstimateHigh', 'Estimate High'),
    createMetric('ebitdaEstimateLow', 'Estimate Low'),
    createMetric('ebitdaNumberAnalysts', 'Number of Analysts')
  ]),
  createMetric('ebit', 'EBIT', [
    createMetric('ebitEstimate', 'Estimate'),
    createMetric('ebitActual', 'Actual'),
    createMetric('ebitEstimateHigh', 'Estimate High'),
    createMetric('ebitEstimateLow', 'Estimate Low'),
    createMetric('ebitNumberAnalysts', 'Number of Analysts')
  ]),
  createMetric('netIncome', 'Net Income', [
    createMetric('netIncomeEstimate', 'Estimate'),
    createMetric('netIncomeActual', 'Actual'),
    createMetric('netIncomeEstimateHigh', 'Estimate High'),
    createMetric('netIncomeEstimateLow', 'Estimate Low'),
    createMetric('netIncomeNumberAnalysts', 'Number of Analysts')
  ]),
  createMetric('sgaExpense', 'SGA Expense', [
    createMetric('sgaExpenseEstimate', 'Estimate'),
    createMetric('sgaExpenseActual', 'Actual'),
    createMetric('sgaExpenseEstimateHigh', 'Estimate High'),
    createMetric('sgaExpenseEstimateLow', 'Estimate Low'),
    createMetric('sgaExpenseNumberAnalysts', 'Number of Analysts')
  ])
];
