import { Button, Card, Col, Row, Space, Spin, Switch, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getHistoricalData, getCompanyAISummary } from '../../services/api';
import { getCurrencySymbol } from '../../utils/currencyMapping';
import AdaptiveLogo from '../AdaptiveLogo/AdaptiveLogo';
import PriceChart from '../PriceChart/PriceChart';
import StockPrice from '../StockPrice/StockPrice';
import CompanyDetails from './CompanyDetails';
import ETFHoldings from './ETFHoldings';
import RevenueEPSCharts from './RevenueEPSCharts';
import AISummaryTab from './AISummaryTab';
import { useCompany } from '../../contexts/CompanyContext';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const timeRanges = ['1M', '3M', '6M', '1Y', '5Y', '10Y', 'ALL'];

const Overview = () => {
  const { companyInfo, selectedTicker, isETF } = useCompany();
  const [priceData, setPriceData] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState('1Y');
  const [showCandlesticks, setShowCandlesticks] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [aiSummary, setAiSummary] = useState(null);
  const [aiSummaryLoading, setAiSummaryLoading] = useState(false);

  useEffect(() => {
    if (companyInfo && companyInfo.profile) {
      setCurrencySymbol(getCurrencySymbol(companyInfo.profile.exchangeShortName));
    }
  }, [companyInfo]);

  useEffect(() => {
    if (selectedTicker && activeTab === '2') {
      fetchPriceData(selectedTicker, selectedTimeRange);
    }
  }, [selectedTicker, selectedTimeRange, activeTab]);

  useEffect(() => {
    if (selectedTicker && activeTab === '4') {
      fetchAISummary(selectedTicker);
    }
  }, [selectedTicker, activeTab]);

  const fetchPriceData = async (ticker, range) => {
    setLoading(true);
    try {
      const priceResponse = await getHistoricalData(ticker, range);
      setPriceData(priceResponse.data);
    } catch (error) {
      console.error('Error fetching price data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAISummary = async (ticker) => {
    setAiSummaryLoading(true);
    try {
      const summaryResponse = await getCompanyAISummary(ticker);
      setAiSummary(summaryResponse);
    } catch (error) {
      console.error('Error fetching AI summary:', error);
    } finally {
      setAiSummaryLoading(false);
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === '2') {
      fetchPriceData(selectedTicker, selectedTimeRange);
    } else if (key === '4') {
      fetchAISummary(selectedTicker);
    }
  };

  if (!companyInfo || !companyInfo.profile) {
    return <Text>Loading...</Text>;
  }

  const { profile } = companyInfo;

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Card>
        <Row gutter={[24, 24]} align="top">
        <Col span={8}>
            <AdaptiveLogo
              src={`https://financialmodelingprep.com/image-stock/${selectedTicker}.png`}
              alt={`${selectedTicker} stock chart`}
              height={40}
            />
            <Title level={2}>{profile.companyName}</Title>
          </Col>
          <Col span={4}>
            <StockPrice symbol={selectedTicker} currencySymbol={currencySymbol} size='large' />
          </Col>
          <Col span={4}>
            <StockPrice symbol={selectedTicker} changePercent currencySymbol={currencySymbol} size='large' />
          </Col>
          
          <Col span={24}>
            <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: 'more' }}>
              {profile.description}
            </Paragraph>
          </Col>
        </Row>
      </Card>

      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="Details" key="1">
          <CompanyDetails companyData={companyInfo} currencySymbol={currencySymbol} isETF={isETF} />
        </TabPane>
        <TabPane tab="Price" key="2">
          <Card bodyStyle={{ padding: 0, height: 'calc(100vh - 300px)', display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: '16px 24px' }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Switch
                    checked={showCandlesticks}
                    onChange={setShowCandlesticks}
                    checkedChildren="Candlesticks"
                    unCheckedChildren="Line"
                  />
                </Col>
                <Col>
                  <Space>
                    {timeRanges.map(range => (
                      <Button
                        key={range}
                        type={selectedTimeRange === range ? 'primary' : 'default'}
                        onClick={() => setSelectedTimeRange(range)}
                      >
                        {range}
                      </Button>
                    ))}
                  </Space>
                </Col>
              </Row>
            </div>
            <div style={{ flex: 1, minHeight: 0 }}>
              {loading ? (
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spin size="large" />
                </div>
              ) : (
                priceData.length > 0 && (
                  <PriceChart 
                    data={priceData} 
                    symbol={selectedTicker} 
                    timeRange={selectedTimeRange}
                    showCandlesticks={showCandlesticks}
                    currencySymbol={currencySymbol}
                  />
                )
              )}
            </div>
          </Card>
        </TabPane>
        {!isETF && (
          <TabPane tab="Revenue & EPS" key="3">
            <RevenueEPSCharts 
              symbol={selectedTicker}
              currencySymbol={currencySymbol}
            />
          </TabPane>
        )}
        {isETF && (
          <TabPane tab="ETF Holdings" key="3">
            <ETFHoldings symbol={selectedTicker} />
          </TabPane>
        )}
        <TabPane tab="AI Summary" key="4">
          <AISummaryTab 
            aiSummary={aiSummary}
            loading={aiSummaryLoading}
            fetchAISummary={() => fetchAISummary(selectedTicker)}
          />
        </TabPane>
      </Tabs>
    </Space>
  );
};

export default Overview;
