class WebSocketManager {
  constructor() {
    this.socket = null;
    this.subscribers = new Map();
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectInterval = 1000; // Start with 1 second
    this.heartbeatInterval = null;
    this.isConnected = false;
    this.subscriptionQueue = new Set();
    this.subscriptionTimer = null;
    this.connect();
    this.addNetworkListeners();
  }

  connect() {
    const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsHost = process.env.NODE_ENV === 'production' ? window.location.host : 'localhost:8000';
    this.socket = new WebSocket(`${wsProtocol}//${wsHost}/ws`);
    console.log(`${wsProtocol}//${wsHost}/ws`);
    
    this.socket.onopen = () => {
      console.log('WebSocket connected');
      this.isConnected = true;
      this.reconnectAttempts = 0;
      this.reconnectInterval = 1000;
      this.sendBatchedSubscriptions();
      this.startHeartbeat();
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket message:', data);
      
      if (data.event === 'heartbeat') {
        this.handleHeartbeat();
      } else if (data.s && data.lp) {
        // Handle FMP format
        this.handleQuoteUpdate(data.s.toUpperCase(), data.lp);
      } else if (data.event === 'quote' && data.data) {
        // Handle new quote format
        const quote = data.data;
        this.handleQuoteUpdate(quote.symbol, quote.price, quote);
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.isConnected = false;
    };

    this.socket.onclose = () => {
      console.log('WebSocket disconnected');
      this.isConnected = false;
      this.reconnect();
    };
  }

  reconnect() {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.error('Max reconnection attempts reached. Please refresh the page.');
      return;
    }

    this.reconnectAttempts++;
    const delay = Math.min(30000, this.reconnectInterval * Math.pow(2, this.reconnectAttempts - 1));
    console.log(`Attempting to reconnect in ${delay}ms`);

    setTimeout(() => {
      if (!this.isConnected) {
        this.connect();
      }
    }, delay);
  }

  startHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ event: 'heartbeat' }));
      }
    }, 30000); // Send heartbeat every 30 seconds
  }

  handleHeartbeat() {
    // Reset reconnect attempts on successful heartbeat
    this.reconnectAttempts = 0;
  }

  addNetworkListeners() {
    window.addEventListener('online', () => {
      console.log('Network is online. Attempting to reconnect...');
      this.reconnect();
    });

    window.addEventListener('offline', () => {
      console.log('Network is offline. WebSocket will attempt to reconnect when online.');
    });
  }

  handleQuoteUpdate(symbol, price, fullQuote = null) {
    const callbacks = this.subscribers.get(symbol) || [];
    callbacks.forEach(callback => callback(symbol, fullQuote || { price }));
  }

  queueSubscription(symbols) {
    // Ensure symbols is always an array
    const symbolArray = Array.isArray(symbols) ? symbols : [symbols];
    symbolArray.forEach(symbol => this.subscriptionQueue.add(symbol));
    this.debounceSendSubscriptions();
  }

  debounceSendSubscriptions() {
    if (this.subscriptionTimer) {
      clearTimeout(this.subscriptionTimer);
    }
    this.subscriptionTimer = setTimeout(() => this.sendBatchedSubscriptions(), 100); // 100ms debounce
  }

  sendBatchedSubscriptions() {
    if (this.socket.readyState === WebSocket.OPEN && this.subscriptionQueue.size > 0) {
      const symbols = Array.from(this.subscriptionQueue);
      this.socket.send(JSON.stringify({ event: 'subscribe', data: { ticker: symbols } }));
      console.log('Sent batch subscription for:', symbols);
      this.subscriptionQueue.clear();
    } else if (this.socket.readyState !== WebSocket.OPEN) {
      console.log('WebSocket not open, queuing subscriptions');
    }
  }

  sendUnsubscription(symbol) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ event: 'unsubscribe', data: { ticker: symbol } }));
    }
  }

  subscribe(symbols, callback) {
    const symbolList = Array.isArray(symbols) ? symbols : [symbols];
    
    symbolList.forEach(symbol => {
      if (!this.subscribers.has(symbol)) {
        this.subscribers.set(symbol, []);
      }
      this.subscribers.get(symbol).push(callback);
    });

    this.queueSubscription(symbolList);

    if (this.socket.readyState !== WebSocket.OPEN) {
      console.log('WebSocket not open, subscriptions will be sent when connected');
    }
  }

  unsubscribe(symbols, callback) {
    const symbolList = Array.isArray(symbols) ? symbols : [symbols];
    
    symbolList.forEach(symbol => {
      const callbacks = this.subscribers.get(symbol);
      if (callbacks) {
        const index = callbacks.indexOf(callback);
        if (index > -1) {
          callbacks.splice(index, 1);
        }
        if (callbacks.length === 0) {
          this.subscribers.delete(symbol);
          this.sendUnsubscription(symbol);
        }
      }
    });
  }
}

export default new WebSocketManager();
