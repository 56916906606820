import React, { useState } from 'react';
import { Tabs } from 'antd';
import KeyPeople from './KeyPeople';
import EmployeeCount from './EmployeeCount';

const { TabPane } = Tabs;

const People = ({ ticker }) => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <div>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Key People" key="1">
          <KeyPeople ticker={ticker} />
        </TabPane>
        <TabPane tab="Employee Count" key="2">
          <EmployeeCount ticker={ticker} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default People;
