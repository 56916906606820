import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Layout, message, Modal, Popconfirm, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useWatchlist } from '../../contexts/WatchlistContext';
import { createWatchlist, deleteWatchlist, getWatchlists, updateWatchlist } from '../../services/api';
import SymbolSearch from '../SymbolSearch/SymbolSearch';
import WatchlistAI from './WatchlistAI';
import WatchlistDetail from './WatchlistDetail';
import WatchlistNews from './WatchlistNews';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Title } = Typography;

const WatchlistPage = () => {
  const [activeWatchlistKey, setActiveWatchlistKey] = useState('');
  const [activeSubTabKey, setActiveSubTabKey] = useState('table');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [watchlistName, setWatchlistName] = useState('');
  const [editingWatchlistId, setEditingWatchlistId] = useState(null);
  const { watchlists, setWatchlists } = useWatchlist();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    const fetchWatchlists = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await getWatchlists();
        const fetchedWatchlists = response.data;
        if (Array.isArray(fetchedWatchlists)) {
          setWatchlists(fetchedWatchlists);
          if (fetchedWatchlists.length > 0) {
            setActiveWatchlistKey(fetchedWatchlists[0].id);
          }
        } else {
          throw new Error('Fetched watchlists is not an array');
        }
      } catch (error) {
        console.error('Error fetching watchlists:', error);
        setError('Failed to fetch watchlists');
        message.error('Failed to fetch watchlists');
      } finally {
        setIsLoading(false);
      }
    };
    fetchWatchlists();
  }, [setWatchlists]);

  const handleWatchlistTabChange = (key) => {
    if (key === 'add') {
      setModalTitle('Create New Watchlist');
      setWatchlistName('');
      setEditingWatchlistId(null);
      setIsModalVisible(true);
    } else {
      setActiveWatchlistKey(key);
    }
  };

  const handleSubTabChange = (key) => {
    setActiveSubTabKey(key);
  };

  const handleEditWatchlist = (watchlistId, watchlistName, event) => {
    event.stopPropagation();
    setModalTitle('Edit Watchlist');
    setWatchlistName(watchlistName);
    setEditingWatchlistId(watchlistId);
    setIsModalVisible(true);
  };

  const handleDeleteWatchlist = async (watchlistId) => {
    try {
      await deleteWatchlist(watchlistId);
      setWatchlists(prevWatchlists => prevWatchlists.filter(w => w.id !== watchlistId));
      message.success('Watchlist deleted successfully');
      if (activeWatchlistKey === watchlistId) {
        const remainingWatchlists = watchlists.filter(w => w.id !== watchlistId);
        setActiveWatchlistKey(remainingWatchlists[0]?.id || '');
      }
    } catch (error) {
      console.error('Error deleting watchlist:', error);
      message.error('Failed to delete watchlist');
    }
  };

  const handleModalOk = async () => {
    if (!watchlistName.trim()) {
      message.error('Please enter a watchlist name');
      return;
    }

    try {
      if (editingWatchlistId) {
        const response = await updateWatchlist(editingWatchlistId, { name: watchlistName });
        setWatchlists(prevWatchlists =>
          prevWatchlists.map(w => w.id === editingWatchlistId ? response.data : w)
        );
        message.success('Watchlist updated successfully');
      } else {
        const response = await createWatchlist(watchlistName);
        const newWatchlist = response.data;
        setWatchlists(prevWatchlists => [...prevWatchlists, newWatchlist]);
        setActiveWatchlistKey(newWatchlist.id);
        message.success('Watchlist created successfully');
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error creating/updating watchlist:', error);
      message.error('Failed to create/update watchlist');
    }
  };

  const handleSymbolAdded = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  if (isLoading) {
    return <div>Loading watchlists...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const activeWatchlist = watchlists.find(w => w.id === activeWatchlistKey);

  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ padding: '20px' }}>
        <Tabs
          activeKey={activeWatchlistKey}
          onChange={handleWatchlistTabChange}
          type="card"
          tabBarExtraContent={{
            right: (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => handleWatchlistTabChange('add')}
              >
                Add Watchlist
              </Button>
            ),
          }}
        >
          {Array.isArray(watchlists) && watchlists.map(watchlist => (
            <TabPane
              tab={
                <span>
                  {watchlist.name}
                  <Divider type="vertical" />
                  <EditOutlined
                    onClick={(e) => handleEditWatchlist(watchlist.id, watchlist.name, e)}
                  />
                  {watchlists.length > 1 && (
                    <Popconfirm
                      title="Are you sure you want to delete this watchlist?"
                      onConfirm={(e) => {
                        e.stopPropagation();
                        handleDeleteWatchlist(watchlist.id);
                      }}
                      okText="Yes"
                      cancelText="No"
                      onCancel={(e) => e.stopPropagation()}
                    >
                      <DeleteOutlined
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Popconfirm>
                  )}
                </span>
              }
              key={watchlist.id}
            >
              <div style={{ marginBottom: '20px' }}>
                <SymbolSearch watchlistId={watchlist.id} onSymbolAdded={handleSymbolAdded} />
              </div>
              <Tabs activeKey={activeSubTabKey} onChange={handleSubTabChange}>
                <TabPane tab="Table" key="table">
                  <WatchlistDetail watchlistId={watchlist.id} refreshTrigger={refreshTrigger} />
                </TabPane>
                <TabPane tab="News" key="news">
                  <WatchlistNews watchlist={watchlist} />
                </TabPane>
                <TabPane tab="Manage via AI" key="ai">
                  <WatchlistAI watchlistId={watchlist.id} />
                </TabPane>
              </Tabs>
            </TabPane>
          ))}
        </Tabs>
        <Modal
          title={modalTitle}
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={() => setIsModalVisible(false)}
        >
          <Input
            placeholder="Enter watchlist name"
            value={watchlistName}
            onChange={(e) => setWatchlistName(e.target.value)}
          />
        </Modal>
      </Content>
    </Layout>
  );
};

export default WatchlistPage;
