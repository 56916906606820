export const getColorForPercentChange = (percentChange) => {
  const absChange = Math.abs(percentChange);
  
  if (percentChange > 0) {
    if (absChange >= 5) return '#0f9960';      // Strong positive: Dark Green
    if (absChange >= 2) return '#15b371';      // Moderate positive: Medium Green
    return '#3dcc91';                          // Slight positive: Light Green
  } else if (percentChange < 0) {
    if (absChange >= 5) return '#db3737';      // Strong negative: Dark Red
    if (absChange >= 2) return '#f55656';      // Moderate negative: Medium Red
    return '#ff7373';                          // Slight negative: Light Red
  }
  
  return '#8a9ba8';  // Neutral: Gray
};

export const getStatusColor = (secondsToOpen, secondsToClose) => {
  const thirtyMinutes = 30 * 60; // 30 minutes in seconds
  if (secondsToClose > 0) {
    return secondsToClose <= thirtyMinutes ? '#d99e0b' : '#0f9960'; // Dark Yellow for closing soon, Green for open
  } else {
    return secondsToOpen <= thirtyMinutes ? '#d99e0b' : '#db3737'; // Dark Yellow for opening soon, Red for closed
  }
};
